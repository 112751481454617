import "./redemptionVoucher.scss";
import { React, useEffect, useState } from 'react';
import { ReactComponent as AddIcon } from '../../../assets/ic_add.svg';
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { getAvatar, getName, renameKey, sortTable, filterTable, isValidValue, dateToString } from '../../../Utils.jsx'
import checkRole from '../../../checkRole';
import { mainAxios } from "../../../axiosConfig";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Navbar from "../../../components/Navbar/Navbar";
import SearchBar from '../../../components/SearchBar/SearchBar';

const RedemptionVoucher = (params) => {
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        showDialog: false,
        isDelete: false,
        selectedRow: {},
        message: ""
    });
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });

    const [sorting, setSorting] = useState({
        column: "",
        assending: true
    });
    const [filterValue, setFilterValue] = useState({
        searchValue: "",
        filterValue: []
    });
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        getRedemptionVoucherListAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        { label: "Category", accessor: "category", isSort: true, width: '15%', type: "string", isFilter: true },
        { label: "Title", accessor: "title", isSort: true, width: '15%', type: "string", isFilter: true },
        { label: "Points", accessor: "redemption_points", isSort: true, width: '15%', type: "string", isFilter: true },
        { label: "Quantity Left", accessor: "quantity_left", isSort: true, width: '15%', type: "string", isFilter: true },
        { label: "Status", accessor: "redemption_status", isSort: true, width: '22%', type: "string", isFilter: true },
        { label: "Action", accessor: "action", isSort: false }
    ]

    // 1062
    const getRedemptionVoucherListAPI = async () => {
        try {
            const res = await mainAxios.get('redemption_vouchers', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var redemptionVoucherList = res.response.data.redemption_vouchers;
                redemptionVoucherList.forEach(obj => renameKey(obj, "redemption_voucher_id", "id"))
                for (let i = 0; i < redemptionVoucherList.length; i++) {
                    if (!redemptionVoucherList[i].is_limited_quantity) {
                        redemptionVoucherList[i].quantity_left = "-";
                    }
                    redemptionVoucherList[i].redemption_status =
                        "Start: " + (!isValidValue(redemptionVoucherList[i].start_at) ? "-" : dateToString(redemptionVoucherList[i].start_at, "D MMM YY, h:mma")) + "\n" +
                        "End: " + (!isValidValue(redemptionVoucherList[i].end_at) ? "-" : dateToString(redemptionVoucherList[i].end_at, "D MMM YY, h:mma")) + "\n" +
                        "Expire: " + (!isValidValue(redemptionVoucherList[i].expire_at) ? "-" : dateToString(redemptionVoucherList[i].expire_at, "D MMM YY, h:mma"));
                }
                setResponseData({
                    loading: false,
                    data: redemptionVoucherList
                });

                filter(filterValue, redemptionVoucherList);
            } else {
                setResponseData({
                    loading: false,
                    data: {}
                });
                filter(filterValue, []);
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: {}
            });
            filter(filterValue, []);
        }
    }

    function onClickRow(id) {
        redemptionVouchers(id, 'view');
    }

    function redemptionVouchers(id, action) {
        for (var data of responseData.data) {
            if (data.id === id) {
                navigate("/redemption/voucher/details?id=" + id);
                break;
            }
        }
    }

    function onClickDelete(id) {
        for (var data of responseData.data) {
            if (data.id === id) {
                setDialog({
                    showDialog: true,
                    isDelete: true,
                    selectedRow: data
                })
            }
        }
    }

    // 4063
    const deleteRedemptionVoucher = async (redemptionVoucherID) => {
        try {
            const res = await mainAxios.delete('redemption_voucher/' + redemptionVoucherID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                getRedemptionVoucherListAPI()
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    function confirmDelete(id) {
        setDialog({ showDialog: false });
        deleteRedemptionVoucher(id);
    }

    function createDialog() {
        return dialog.showDialog ? dialog.isDelete ?
            (
                <ConfirmDialog
                    id={dialog.selectedRow.id}
                    title={'Delete Voucher'}
                    subtitle={'Are you sure you want to delete ‘' + dialog.selectedRow.title + '‘ ?'}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Callback={(id) => setDialog({ showDialog: false })}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => confirmDelete(id)}
                />
            ) :
            (
                <ConfirmDialog
                    iconTitle={''}
                    title={''}
                    subtitle={dialog.message}
                    subTitleStyle={{ fontSize: 16 }}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Style={{ visibility: "hidden" }}
                    button2Icon={''}
                    button2Title={'OK'}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => setDialog({ showDialog: false })}
                />
            )
            : null
    }

    function onSidebarClick() {
        setResponseData({
            loading: true,
            data: {}
        });
        getRedemptionVoucherListAPI();
    }

    function onClickColumn(column, arr) {
        var data = sortTable(column, columns, arr ?? filterList);
        column.data = data;
        setSorting(column);
    }

    function filter(filter, arr) {
        var list = filterTable(filter, arr ?? responseData.data);
        setFilterList(list);
        onClickColumn(sorting, list)
    }

    function handleSearchChange(value) {
        setFilterValue(value)
        filter(value);
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="redemption_vouchers" onSidebarClick={onSidebarClick} navChild={{ redemption: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="redemption-voucher">
                        <div className="header">
                            <p>REDEMPTION VOUCHERS</p>
                            <SearchBar
                                handleSearchChange={(value) => handleSearchChange(value)}
                                value={filterValue}
                                filterList={columns}
                            />
                            <button onClick={() => navigate("/redemption/voucher-add")}>
                                <span><AddIcon /></span>
                                <span>Add New</span>
                            </button>
                        </div>
                        <div className="table-container">
                            <CustomTable
                                columns={columns}
                                tableData={sorting.data ?? filterList}
                                isEdit={false}
                                numPerPage={10}
                                onClickRow={(id) => onClickRow(id)}
                                onClickDelete={(id) => onClickDelete(id)}
                                onClickViewMore={(id) => onClickRow(id)}
                                sorting={sorting}
                                onClickColumn={(column) => onClickColumn(column)}
                            />
                        </div>
                        {createDialog()}
                    </div>
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(RedemptionVoucher);