import React, { useEffect, useState } from 'react'
import './redemptionVoucherDetails.scss'
import { useNavigate, useSearchParams } from 'react-router-dom';
import checkRole from '../../../checkRole';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { ReactComponent as PlaceHolderIcon } from '../../../assets/img_company_placeholder.svg';
import { ReactComponent as AddIcon } from '../../../assets/ic_add.svg';
import { ReactComponent as MinusIcon } from '../../../assets/ic_minus.svg'
import { mainAxios } from '../../../axiosConfig';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { dateToString, getAvatar, getName, isValidValue } from '../../../Utils';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import CustomInputDialog from '../../../components/CustomInputDialog/CustomInputDialog';

const RedemptionVoucherDetails = (params) => {
    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();
    const queryID = queryParameters.get("id");
    const [responseData, setResponseData] = useState({
        loading: true,
        data: {}
    });
    const [inputDialog, setInputDialog] = useState({
        showDialog: false,
        title: "",
        action: "add"
    });

    useEffect(() => {
        getRedemptionVoucherDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const links = document.querySelectorAll('#html-link a');
        links.forEach(link => {
            link.setAttribute('target', '_blank');
        });

    }, [responseData]);


    // 1063
    const getRedemptionVoucherDetails = async () => {
        try {
            const res = await mainAxios.get('redemption_voucher/' + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: false,
                    data: res.response.data.redemption_voucher
                });
            } else {
                setResponseData({
                    loading: false,
                    data: {}
                });
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: {}
            });
        }
    }

    // 3064
    const updateRedemptionPointsAPI = async (quantity) => {
        setInputDialog({ showDialog: false });
        try {
            const res = await mainAxios.put('redemption_voucher_quantity/' + queryID, {
                quantity: quantity
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                getRedemptionVoucherDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    const DetailsLayout = (props) => {
        return (
            <div id={props.isHTML ? 'html-link' : ''} className='details-layout'>
                <p className={`${props.isAddMinus ? 'details-layout-add-edit-label' : ''}`}>{props.title}</p>
                {!props.isHTML ? props.isAddMinus ?
                    <div className='details-layout-add-edit'>
                        <p>{props.content}</p>
                        <AddIcon className='details-layout-add-icon' onClick={() => setInputDialog({ showDialog: true, title: 'Increase Quantity', action: 'add' })} />
                        <MinusIcon className='details-layout-minus-icon' onClick={() => setInputDialog({ showDialog: true, title: 'Reduce Quantity', action: "reduce" })} />
                    </div> : <p>{props.content}</p> : <div className='details-html'>{props.content}</div>}
            </div>
        )
    }

    function onSidebarClick() { }

    function createInputDialog() {
        return inputDialog.showDialog ?
            (
                <CustomInputDialog
                    type={"points"}
                    title={inputDialog.title}
                    action={inputDialog.action}
                    backgroundButtonCallback={() => setInputDialog({ showDialog: false })}
                    button1Callback={() => setInputDialog({ showDialog: false })}
                    button2Style={{ backgroundColor: 'rgba(249, 165, 40, 1)', color: 'white' }}
                    button2Callback={(points) => updateRedemptionPointsAPI(points)}
                />
            ) : null
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="redemption_vouchers" onSidebarClick={onSidebarClick} navChild={{ redemption: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="redemption-voucher-details">
                        <div className="header">
                            <div>
                                <p>Redemption voucher details</p>
                                <p>Redemption Voucher &gt; Redemption voucher details </p>
                            </div>
                        </div>
                        <div className='content'>
                            <div className='details'>
                                <DetailsLayout title="Category:" content={!isValidValue(responseData.data.category) ? "-" : responseData.data.category} />
                                <DetailsLayout title="Title:" content={!isValidValue(responseData.data.title) ? "-" : responseData.data.title} />
                                <DetailsLayout title="Content:" isHTML={isValidValue(responseData.data.content_html)} content={isValidValue(responseData.data.content_html) ? parse(DOMPurify.sanitize(responseData.data.content_html)) : "-"} />
                                <DetailsLayout title="Limited Quantity:" content={!isValidValue(responseData.data.is_limited_quantity) ? "-" : responseData.data.is_limited_quantity ? "Yes" : "No"} />
                                {isValidValue(responseData.data.is_limited_quantity) && responseData.data.is_limited_quantity ?
                                    <DetailsLayout title="Quantity Left:" isAddMinus={true} content={!isValidValue(responseData.data.quantity_left) ? "0" : responseData.data.quantity_left} /> : undefined
                                }
                                <DetailsLayout title="Points:" content={!isValidValue(responseData.data.redemption_points) ? "-" : responseData.data.redemption_points} />
                                <DetailsLayout title="Start at:" content={!isValidValue(responseData.data.start_at) ? "-" : dateToString(responseData.data.start_at, "D MMM YY, h:mma")} />
                                <DetailsLayout title="End at:" content={!isValidValue(responseData.data.end_at) ? "-" : dateToString(responseData.data.end_at, "D MMM YY, h:mma")} />
                                <DetailsLayout title="Expire at:" content={!isValidValue(responseData.data.expire_at) ? "-" : dateToString(responseData.data.expire_at, "D MMM YY, h:mma")} />
                            </div>
                            <div className='details-image'>
                                <div className='logo'>
                                    <p>Image:</p>
                                    <div className='upload'>
                                        {!isValidValue(responseData.data.image_url) ? <PlaceHolderIcon /> : <img className='upload-logo' src={responseData.data.image_url} alt="" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {createInputDialog()}
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(RedemptionVoucherDetails);