import React, { useEffect, useState } from 'react'
import './bookingDetails.scss'
import checkRole from '../../checkRole'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { mainAxios } from '../../axiosConfig';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { getAvatar, getName, sortTable, filterTable, renameKey, dateToString, timeToString, isValidValue, setupName, getDateTimeWithGMT } from '../../Utils';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import CustomTable from '../../components/CustomTable/CustomTable';
import SearchBar from '../../components/SearchBar/SearchBar';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import CustomTableDialog from '../../components/CustomTableDialog/CustomTableDialog';
import { ReactComponent as RefreshIcon } from '../../assets/ic_refresh.svg'

const BookingDetails = (params) => {
    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();
    const queryID = queryParameters.get("id");
    const [responseData, setResponseData] = useState({
        loading: true,
        data: {}
    });
    const [sorting, setSorting] = useState({
        column: "",
        assending: true
    });
    const [filterValue, setFilterValue] = useState({
        searchValue: "",
        filterValue: []
    });
    const [filterList, setFilterList] = useState([]);

    const [dialog, setDialog] = useState({
        showDialog: false,
        selectedRow: {},
        isRefund: true
    });

    const [tableDialog, setTableDialog] = useState({
        session_id: "",
        showDialog: false,
        isRedButton: false
    });

    const [selectedId, setSelectedId] = useState("");
    const [invoice, setInvoice] = useState("");

    const weekObject = { 0: "Mon", 1: "Tue", 2: "Wed", 3: "Thu", 4: "Fri", 5: "Sat", 6: "Sun" };

    const columns = [
        { label: "#", accessor: "code", isSort: true, width: '20%', type: "string", isFilter: true, isAbleClick: true },
        { label: "Schedule Date", accessor: "schedule_date", isSort: true, width: '26%', type: "string", isFilter: true },
        { label: "Session Status", accessor: "status", isSort: true, width: '27%', type: "string", isFilter: true },
        { label: "Agent", accessor: "agent_details", isSort: true, width: '27%', type: "string", isFilter: true, isCustomButton: true, buttonText: "Assign", redButtonText: "Re-assign", isSkipDefault: true, justifyContent: 'center' }
    ]

    const agentColumns = [
        { label: "Name", accessor: "name", isSort: true, width: '35%', type: "string", isFilter: true },
        { label: "Email", accessor: "login_id", isSort: true, width: '35%', type: "string", isFilter: true },
        { label: "Mobile No.", accessor: "mobile_no", isSort: true, width: '30%', type: "string", isFilter: true }
    ]

    useEffect(() => {
        getBookingDetails();
        getBookingInvoiceURLAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const DetailsLayout = (props) => {
        return (
            <div className='details-layout'>
                <div className='details-layout-item'>
                    <p>{props.title}</p>
                </div>
                <div className='details-layout-item'>
                    {(props.screen === undefined) ? props.isRefreshIcon ?
                        (
                            <div className='details-layout-refresh'>
                                <p>{props.content}</p>
                                <RefreshIcon className='details-layout-refresh-icon' onClick={() => refreshBookingPayAt()} />
                            </div>
                        ) : <p>{props.content}</p> :
                        <p>
                            <Link to={getLinkTo(props.screen)} rel="noreferrer" target='_blank'>{props.content}</Link>
                        </p>
                    }
                </div>
            </div>
        )
    }

    function getLinkTo(id) {
        let data = responseData.data ?? {};
        let customer = data.customer ?? {};

        switch (id) {
            case 0: return { pathname: "/customers/details", search: "?id=" + customer.customer_id };
            default: break;
        }
    }

    // 1031
    const getBookingDetails = async () => {
        try {
            const res = await mainAxios.get('booking/' + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var data = res.response.data.booking;
                var isPay = data.pay_at != null;
                var isCancel = data.cancel_at != null;
                var sessionList = data.sessions;
                sessionList.forEach(obj => renameKey(obj, "session_id", "id"))
                for (let i = 0; i < sessionList.length; i++) {
                    var code = sessionList[i].session_code;
                    if (sessionList[i].agent) {
                        sessionList[i].agent_name = setupName(sessionList[i].agent.first_name, sessionList[i].agent.last_name);
                        var phoneNumber = "";
                        if (isValidValue(sessionList[i].agent.country_code)) {
                            phoneNumber += sessionList[i].agent.country_code + " ";
                        }
                        if (isValidValue(sessionList[i].agent.phone_number)) {
                            phoneNumber += sessionList[i].agent.phone_number;
                        }
                        sessionList[i].agent_details = (sessionList[i].agent.login_id ?? "-") + "\n" + (setupName(sessionList[i].agent.first_name, sessionList[i].agent.last_name) ?? "-") + "\n" + (phoneNumber === "" ? "-" : phoneNumber);
                    }
                    sessionList[i].schedule_date = getDateTimeWithGMT(sessionList[i].start_at, data.address_timezone_offset, "D MMM YY");
                    sessionList[i].code = code;
                    sessionList[i].status = "Assign: " +
                        (!isValidValue(sessionList[i].assign_at) ? "-" : getDateTimeWithGMT(sessionList[i].assign_at, data.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Accept: " + (!isValidValue(sessionList[i].accept_at) ? "-" : getDateTimeWithGMT(sessionList[i].accept_at, data.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Decline: " + (!isValidValue(sessionList[i].decline_at) ? "-" : getDateTimeWithGMT(sessionList[i].decline_at, data.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Complete: " + (!isValidValue(sessionList[i].complete_at) ? "-" : getDateTimeWithGMT(sessionList[i].complete_at, data.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Review: " + (!isValidValue(sessionList[i].review_at) ? "-" : getDateTimeWithGMT(sessionList[i].review_at, data.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Pay: " + (!isValidValue(sessionList[i].pay_at) ? "-" : getDateTimeWithGMT(sessionList[i].pay_at, data.address_timezone_offset, "D MMM YY, h:mma"));
                    sessionList[i].isDisableCustom = !isPay || isCancel || sessionList[i].complete_at != null;
                    sessionList[i].isRedButton = sessionList[i].decline_at != null;
                }
                data.sessionsList = sessionList;
                setResponseData({
                    loading: false,
                    data: data
                });
                filter(filterValue, sessionList);
            } else {
                setResponseData({
                    loading: false,
                    ...responseData
                });
                filter(filterValue, []);
            }
        } catch (error) {
            setResponseData({
                loading: false,
                ...responseData
            });
            filter(filterValue, []);
        }
    }

    function refreshBookingPayAt() {
        setResponseData({
            ...responseData,
            loading: true,
        });
        getBookingPayAtAPI();
    }

    // 1032
    const getBookingPayAtAPI = async () => {
        try {
            const res = await mainAxios.get('booking_pay_at/' + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: true,
                    data: {}
                });
                getBookingDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 1033
    const getBookingInvoiceURLAPI = async () => {
        try {
            const res = await mainAxios.get('booking_invoice_url/' + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var invoiceURL = res.response.data.booking_invoice_url;
                if (invoiceURL) {
                    setInvoice(invoiceURL);
                }
            }
        } catch (error) { }
    }

    // 3032
    const cancelBookingAPI = async () => {
        try {
            const res = await mainAxios.put('booking_cancel/' + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: true,
                    data: {}
                });
                getBookingDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 3033
    const refundBookingAPI = async () => {
        try {
            const res = await mainAxios.put('booking_refund/' + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: true,
                    data: {}
                });
                getBookingDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 3042
    const assignAgentAPI = async (id) => {
        try {
            const res = await mainAxios.put('session_assign/' + id, {
                agent_id: selectedId
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: true,
                    data: {}
                });
                getBookingDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
        setSelectedId("");
    }

    function onClickColumn(column, arr) {
        var data = sortTable(column, columns, arr ?? filterList);
        column.data = data;
        setSorting(column);
    }

    function filter(filter, arr) {
        var list = filterTable(filter, arr ?? responseData.data.sessionsList);
        setFilterList(list);
        onClickColumn(sorting, list)
    }

    function handleSearchChange(value) {
        setFilterValue(value)
        filter(value);
    }

    function onClickRow(id) {
        sessions(id, 'view');
    }

    function sessions(id, action) {
        for (var data of responseData.data.sessionsList) {
            if (data.id === id) {
                navigate("/session/details?id=" + id);
                break;
            }
        }
    }

    function onCustomButton(id, isRedButton) {
        setTableDialog({ showDialog: true, session_id: id, isRedButton: isRedButton });
    }

    function createDialog() {
        return dialog.showDialog ? dialog.isRefund ?
            <ConfirmDialog
                id={dialog.selectedRow.id}
                title={'Refund Booking'}
                subtitle={'Are you sure you want to refund ‘' + dialog.selectedRow.booking_code + '‘ booking?'}
                backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                iconTitle={''}
                button1Callback={(id) => setDialog({ showDialog: false })}
                button2Icon={''}
                button2Title={'OK'}
                button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                button2Callback={(id) => confirmRefund(id)}
            /> :
            <ConfirmDialog
                id={dialog.selectedRow.id}
                title={'Cancel Booking'}
                subtitle={'Are you sure you want to cancel ‘' + dialog.selectedRow.booking_code + '‘ booking?'}
                backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                iconTitle={''}
                button1Callback={(id) => setDialog({ showDialog: false })}
                button2Icon={''}
                button2Title={'OK'}
                button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                button2Callback={(id) => confirmCancel(id)}
            /> : null
    }

    function createTableDialog() {
        return tableDialog.showDialog ?
            (
                <CustomTableDialog
                    columns={agentColumns}
                    numPerPage={3}
                    onClickTableRow={(id) => onClickTableRow(id)}
                    backgroundButtonCallback={(id) => handleCancelCallback()}
                    button1Callback={(id) => handleCancelCallback()}
                    button2Title={tableDialog.isRedButton ? 'Re-assign' : 'Assign'}
                    button2Style={selectedId === "" ? { backgroundColor: '#dbdbdb', color: '#656565' } : tableDialog.isRedButton ? { backgroundColor: '#FF0000', color: 'white' } : { backgroundColor: '#FC7921', color: 'white' }}
                    isDisableButton2={selectedId === ""}
                    button2Callback={(id) => handleButton2Callback()}
                />
            ) : null
    }

    function handleCancelCallback() {
        setSelectedId("");
        setTableDialog({ showDialog: false, session_id: "", isRedButton: false })
    }

    function handleButton2Callback() {
        setResponseData({
            ...responseData,
            loading: true,
        });
        assignAgentAPI(tableDialog.session_id)
        setTableDialog({ showDialog: false, session_id: "", isRedButton: false })
    }

    function onClickTableRow(id) {
        setSelectedId(id);
    }

    function confirmRefund() {
        setDialog({ showDialog: false })
        setResponseData({
            ...responseData,
            loading: true,
        });
        refundBookingAPI();
    }

    function confirmCancel() {
        setDialog({ showDialog: false })
        setResponseData({
            ...responseData,
            loading: true,
        });
        cancelBookingAPI();
    }

    function onSidebarClick() { }

    function getWeekdays(weekdays) {
        var weekdaysString = "";
        for (let i = 0; i < weekdays.length; i++) {
            if (weekdaysString === "") {
                weekdaysString += weekObject[weekdays.charAt(i)];
            } else {
                weekdaysString += "/" + weekObject[weekdays.charAt(i)];
            }
        }
        return weekdaysString;
    }

    function refundBooking() {
        setDialog({
            showDialog: true,
            selectedRow: responseData.data,
            isRefund: true
        })
    }

    function cancelBooking() {
        setDialog({
            showDialog: true,
            selectedRow: responseData.data,
            isRefund: false
        })
    }

    function getPackageValue() {
        var bookingPackage = "";
        var hasPackageLabel = false;

        if (isValidValue(responseData.data.package_label)) {
            bookingPackage = responseData.data.package_label;
            hasPackageLabel = true;
        }

        if (isValidValue(responseData.data.package_value)) {
            if (hasPackageLabel) {
                bookingPackage += ` $${responseData.data.package_value}`
            } else {
                bookingPackage = `$${responseData.data.package_value}`
            }
        }

        return bookingPackage === "" ? "-" : bookingPackage;
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="bookings" onSidebarClick={onSidebarClick} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="booking-details">
                        <div className="header">
                            <div>
                                <p>Booking details</p>
                                <p>Bookings &gt; Booking details </p>
                            </div>
                            {
                                invoice !== "" ? <button className='invoice-button' onClick={() => window.open(invoice, '_blank')}>
                                    Invoice
                                </button> : undefined
                            }
                            {
                                responseData.data.cancel_at == null ? <button className={`${invoice === "" ? 'booking-button' : ''}`} onClick={() => cancelBooking()}>
                                    Cancel
                                </button> : responseData.data.cancel_at != null && responseData.data.refund_at == null && responseData.data.pay_at != null ? <button className={`${invoice === "" ? 'booking-button' : ''}`} onClick={() => refundBooking()}>
                                    Refund
                                </button> : undefined
                            }
                        </div>
                        <div className='content'>
                            <div className='details-container'>
                                <div className='details'>
                                    <p className='details-title'>Booking Information</p>
                                    <div className='details-form'>
                                        <DetailsLayout title="Booking Code:" content={!isValidValue(responseData.data.booking_code) ? "-" : responseData.data.booking_code} />
                                        <DetailsLayout title="Service:" content={!isValidValue(responseData.data.title) ? "-" : responseData.data.title} />
                                        <DetailsLayout title="Customer:" screen={0} content={responseData.data.customer ? !isValidValue(setupName(responseData.data.customer.first_name, responseData.data.customer.last_name)) ? "-" : setupName(responseData.data.customer.first_name, responseData.data.customer.last_name) : "-"} />
                                        <DetailsLayout title="Rate Per Hour:" content={!isValidValue(responseData.data.rate_per_hour) ? "-" : "$ " + responseData.data.rate_per_hour.toFixed(2)} />
                                        <DetailsLayout title="Designation:" content={!isValidValue(responseData.data.designation) ? "-" : responseData.data.designation} />
                                        <DetailsLayout title="Package:" content={getPackageValue()} />
                                        <DetailsLayout title="Urgent:" content={!isValidValue(responseData.data.is_urgent) ? "-" : responseData.data.is_urgent ? "Yes" : "No"} />
                                        <DetailsLayout title="Editable:" content={!isValidValue(responseData.data.is_editable) ? "-" : responseData.data.is_editable ? "Yes" : "No"} />
                                        <DetailsLayout title="Amount:" content={!isValidValue(responseData.data.amount) ? "-" : "$ " + responseData.data.amount.toFixed(2)} />
                                        <DetailsLayout title="Pay ID:" content={!isValidValue(responseData.data.pay_reference) ? "-" : responseData.data.pay_reference.split("_secret")[0]} />
                                        <DetailsLayout title="Pay Amount:" content={!isValidValue(responseData.data.pay_amount) ? "-" : "$ " + responseData.data.pay_amount.toFixed(2)} />
                                        <DetailsLayout title="Pay Credits:" content={!isValidValue(responseData.data.pay_credits) ? "-" : responseData.data.pay_credits + "c"} />
                                        <DetailsLayout title="Book at:" content={!isValidValue(responseData.data.book_at) ? "-" : getDateTimeWithGMT(responseData.data.book_at, responseData.data.address_timezone_offset, "D MMM YY, h:mma")} />
                                        <DetailsLayout title="Pay at:" isRefreshIcon={!isValidValue(responseData.data.pay_at)} content={!isValidValue(responseData.data.pay_at) ? "-" : getDateTimeWithGMT(responseData.data.pay_at, responseData.data.address_timezone_offset, "D MMM YY, h:mma")} />
                                        <DetailsLayout title="Cancel at:" content={!isValidValue(responseData.data.cancel_at) ? "-" : getDateTimeWithGMT(responseData.data.cancel_at, responseData.data.address_timezone_offset, "D MMM YY, h:mma")} />
                                        <DetailsLayout title="Refund at:" content={!isValidValue(responseData.data.refund_at) ? "-" : getDateTimeWithGMT(responseData.data.refund_at, responseData.data.address_timezone_offset, "D MMM YY, h:mma")} />
                                    </div>
                                    {isValidValue(responseData.data.breakdown) && responseData.data.breakdown.length > 0 ?
                                        <div className='details-breakdown'>
                                            <p className='details-title'>Breakdown</p>
                                            <div className='details-form'>
                                                {responseData.data.breakdown.map((item) => {
                                                    return (
                                                        <DetailsLayout title={item.key + ":"} content={item.value} />
                                                    )
                                                })}
                                            </div>
                                        </div> : undefined
                                    }
                                </div>
                            </div>
                            <div className='details-supporting'>
                                <div className='details-supporting-container'>
                                    <label className='details-supporting-label'>Schedule</label>
                                    <div className='details-supporting-form'>
                                        <DetailsLayout title="Recurring:" content={!isValidValue(responseData.data.is_recurring) ? "-" : responseData.data.is_recurring ? "Yes" : "No"} />
                                        <DetailsLayout title="Weekdays:" content={!isValidValue(responseData.data.schedule_weekdays) ? "-" : getWeekdays(responseData.data.schedule_weekdays)} />
                                        <DetailsLayout title="Start Date:" content={!isValidValue(responseData.data.schedule_start_date) ? "-" : dateToString(responseData.data.schedule_start_date, "D MMM YY")} />
                                        <DetailsLayout title="End Date:" content={!isValidValue(responseData.data.schedule_end_date) ? "-" : dateToString(responseData.data.schedule_end_date, "D MMM YY")} />
                                        <DetailsLayout title="Time:" content={!isValidValue(responseData.data.schedule_time) ? "-" : timeToString(responseData.data.schedule_time, "h:mma")} />
                                        <DetailsLayout title="Duration:" content={!isValidValue(responseData.data.schedule_duration) ? "-" : responseData.data.schedule_duration + (responseData.data.schedule_duration > 1 ? " hours" : " hour")} />
                                    </div>
                                    <label className='details-supporting-label2'>Address</label>
                                    <div className='details-supporting-form'>
                                        <DetailsLayout title="Label:" content={!isValidValue(responseData.data.address_label) ? "-" : responseData.data.address_label} />
                                        <DetailsLayout title="Timezone Offset:" content={!isValidValue(responseData.data.address_timezone_offset) ? "-" : responseData.data.address_timezone_offset} />
                                        <DetailsLayout title="Address:" content={!isValidValue(responseData.data.address) ? "-" : responseData.data.address} />
                                        <DetailsLayout title="Building Name:" content={!isValidValue(responseData.data.address_building) ? "-" : responseData.data.address_building} />
                                        <DetailsLayout title="Unit:" content={!isValidValue(responseData.data.address_unit) ? "-" : responseData.data.address_unit} />
                                        <DetailsLayout title="Note:" content={!isValidValue(responseData.data.address_note) ? "-" : responseData.data.address_note} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='session'>
                            <div className="header">
                                <p className='details-title'>Sessions</p>
                                <SearchBar
                                    handleSearchChange={(value) => handleSearchChange(value)}
                                    value={filterValue}
                                    filterList={columns}
                                />
                            </div>
                            <div className="table-container">
                                <CustomTable
                                    columns={columns}
                                    tableData={sorting.data ?? filterList}
                                    isDelete={false}
                                    isEdit={false}
                                    isCustomButton={true}
                                    isDisableOnClickRow={true}
                                    buttonText="Assign"
                                    numPerPage={5}
                                    onClickRow={(id) => onClickRow(id)}
                                    onClickViewMore={(id) => onClickRow(id)}
                                    onCustomButton={(id, isRedButton) => onCustomButton(id, isRedButton)}
                                    sorting={sorting}
                                    onClickColumn={(column) => onClickColumn(column)}
                                />
                            </div>
                        </div>
                        {createDialog()}
                        {createTableDialog()}
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(BookingDetails)