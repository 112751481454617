import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import './promotionItem.scss'
import { ReactComponent as MenuIcon } from '../../../assets/ic_menu2.svg'
import { ReactComponent as AddIcon } from '../../../assets/ic_add.svg';
import { ReactComponent as CloseIcon } from '../../../assets/ic_close_outline.svg'
import { blockInvalidChar, getGMTString } from "../../../Utils";
import { CustomSelect } from "../../../components/SelectOption/CustomSelect";

export const PromotionItem = ({ index, id, serviceList, minSessions, serviceId, discount, discountAmount, startAt, endAt, setMinSessions, setService, setDiscount, setDiscountAmount, setStartAt, setEndAt, addPromotion, removePromotion }) => {
    const { attributes, listeners, setNodeRef, transform } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform)
    };

    const service = serviceList.find(item => item.value === serviceId);

    return (
        <div className="promotion-item" style={style}>
            <div className="promotion-item-content">
                <MenuIcon className="promotion-menu" ref={setNodeRef}{...attributes}{...listeners} />
                <label>Book</label>
                <div className='details-div-select-layout'>
                    <CustomSelect list={serviceList} isMulti={false} value={service ?? {}} onChangeValue={(e) => setService(id, e)} />
                </div>
                <label className="promotion-bold">OR</label>
                <input className='promotion-input' type='number' onKeyDown={blockInvalidChar} onChange={(e) => setMinSessions(id, e.target.value)} value={minSessions ?? ""} min={0} />
                <label>+ sessions for a </label>
                <input className='promotion-input' type='number' onKeyDown={blockInvalidChar} onChange={(e) => setDiscount(id, e.target.value)} value={discount ?? ""} min={0} />
                <label>%&nbsp;</label>
                <label className="promotion-bold">OR</label>
                <label>&nbsp;$</label>
                <input className='promotion-input-amount' type='number' onKeyDown={blockInvalidChar} onChange={(e) => setDiscountAmount(id, e.target.value)} value={discountAmount ?? ""} min={0} />
                <label>discount</label>
                <CloseIcon className='promotion-remove-icon' onClick={() => removePromotion(id)} />
                {
                    index === 0 ? <AddIcon className='promotion-add-icon' onClick={() => addPromotion()} /> : undefined
                }
            </div>
            <div className="promotion-item-content">
                <MenuIcon className="promotion-menu-hidden" />
                <label>Promotion period: from</label>
                <input className='promotion-input-date-time' type='datetime-local' onChange={(e) => setStartAt(id, e.target.value)} value={startAt ?? ""} />
                <label>until</label>
                <input className='promotion-input-date-time' type='datetime-local' onChange={(e) => setEndAt(id, e.target.value)} value={endAt ?? ""} />
                <label>({getGMTString()})</label>
            </div>
        </div>
    )
}