import React from 'react';
import './navbar.scss';
import { ReactComponent as MenuIcon } from '../../assets/ic_menu.svg';
import { ReactComponent as DefaultProfileIcon } from '../../assets/ic_default_profile.svg';

const Navbar = (props) => {
    return (
        <div className='navbar' >
            <MenuIcon className='navbar-menu-icon' onClick={() => props.setNavClose(!props.navClose)} />
            <div className='navbar-profile'>
                <p className='name'>{props.name}</p>
                <p className='role'>Admin</p>
            </div>
            {/* <div className='navbar-profile-icon'>
                {props.avatar != null && props.avatar !== "" ? <img className='nav-avatar' src={props.avatar} alt="avatar" /> : <DefaultProfileIcon />}
            </div> */}
        </div>
    )
}

export default Navbar