import "./redemptionHistory.scss";
import { React, useEffect, useState } from 'react';
import CustomTable from "../../../components/CustomTable/CustomTable.jsx";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog.jsx";
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner.jsx';
import { getAvatar, getName, sortTable, filterTable, dateToString, isValidValue, setupName } from '../../../Utils.jsx'
import checkRole from '../../../checkRole.js';
import { mainAxios } from "../../../axiosConfig.js";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";
import Navbar from "../../../components/Navbar/Navbar.jsx";
import SearchBar from '../../../components/SearchBar/SearchBar.jsx';

const RedemptionHistory = (params) => {
    const [dialog, setDialog] = useState({
        showDialog: false,
        isDelete: false,
        selectedRow: {},
        message: ""
    });
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });

    const [sorting, setSorting] = useState({
        column: "",
        assending: true
    });
    const [filterValue, setFilterValue] = useState({
        searchValue: "",
        filterValue: []
    });
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        getRedemptionAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        { label: "Category", accessor: "category", isSort: true, width: '20%', type: "string", isFilter: true },
        { label: "Title", accessor: "title", isSort: true, width: '20%', type: "string", isFilter: true },
        { label: "Points", accessor: "redemption_points", isSort: true, width: '15%', type: "string", isFilter: true },
        { label: "Status", accessor: "redemption_status", isSort: true, width: '23%', type: "string", isFilter: true },
        { label: "Agent", accessor: "agent_details", isSort: true, width: '22%', type: "string", isFilter: true }
    ]

    // 1060
    const getRedemptionAPI = async () => {
        try {
            const res = await mainAxios.get('redemptions', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var redemptionsList = res.response.data.redemptions;
                for (let i = 0; i < redemptionsList.length; i++) {
                    redemptionsList[i].id = i + 1;
                    let redemptionVoucher = redemptionsList[i].redemption_voucher;
                    if (redemptionVoucher) {
                        redemptionsList[i].title = redemptionVoucher.title;
                        redemptionsList[i].category = redemptionVoucher.category;
                        redemptionsList[i].redemption_points = redemptionVoucher.redemption_points;
                    }
                    redemptionsList[i].redemption_status = "Redeem: " + (!isValidValue(redemptionsList[i].redeem_at) ? "-" : dateToString(redemptionsList[i].redeem_at, "D MMM YY, h:mma"));
                    if (redemptionsList[i].agent) {
                        var phoneNumber = "";
                        if (isValidValue(redemptionsList[i].agent.country_code)) {
                            phoneNumber += redemptionsList[i].agent.country_code + " ";
                        }
                        if (isValidValue(redemptionsList[i].agent.phone_number)) {
                            phoneNumber += redemptionsList[i].agent.phone_number;
                        }
                        redemptionsList[i].agent_details = (redemptionsList[i].agent.login_id ?? "-") + "\n" + (setupName(redemptionsList[i].agent.first_name, redemptionsList[i].agent.last_name) ?? "-") + "\n" + (phoneNumber === "" ? "-" : phoneNumber);
                    }
                }
                setResponseData({
                    loading: false,
                    data: redemptionsList
                });

                filter(filterValue, redemptionsList);
            } else {
                setResponseData({
                    loading: false,
                    data: {}
                });
                filter(filterValue, []);
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: {}
            });
            filter(filterValue, []);
        }
    }

    function onClickRow(id) {
        redemptionHistory(id, 'view');
    }

    function redemptionHistory(id, action) {
        for (var data of responseData.data) {
            if (data.id === id) {
                //navigate("/service/details?id=" + id);
                break;
            }
        }
    }

    function createDialog() {
        return dialog.showDialog ?
            (
                <ConfirmDialog
                    iconTitle={''}
                    title={''}
                    subtitle={dialog.message}
                    subTitleStyle={{ fontSize: 16 }}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Style={{ visibility: "hidden" }}
                    button2Icon={''}
                    button2Title={'OK'}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => setDialog({ showDialog: false })}
                />
            ) : null
    }

    function onSidebarClick() {
        setResponseData({
            loading: true,
            data: {}
        });
        getRedemptionAPI();
    }

    function onClickColumn(column, arr) {
        var data = sortTable(column, columns, arr ?? filterList);
        column.data = data;
        setSorting(column);
    }

    function filter(filter, arr) {
        var list = filterTable(filter, arr ?? responseData.data);
        setFilterList(list);
        onClickColumn(sorting, list)
    }

    function handleSearchChange(value) {
        setFilterValue(value)
        filter(value);
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="redemption_history" onSidebarClick={onSidebarClick} navChild={{ redemption: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="redemption-history">
                        <div className="header">
                            <p>REDEMPTION HISTORY</p>
                            <SearchBar
                                handleSearchChange={(value) => handleSearchChange(value)}
                                value={filterValue}
                                filterList={columns}
                            />
                        </div>
                        <div className="table-container">
                            <CustomTable
                                columns={columns}
                                tableData={sorting.data ?? filterList}
                                isDisableOnClickRow={true}
                                numPerPage={10}
                                onClickRow={(id) => onClickRow(id)}
                                onClickViewMore={(id) => onClickRow(id)}
                                sorting={sorting}
                                onClickColumn={(column) => onClickColumn(column)}
                            />
                        </div>
                        {createDialog()}
                    </div>
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(RedemptionHistory);