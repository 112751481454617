import React, { useEffect, useState } from 'react'
import "./userDetails.scss";
import checkRole from '../../checkRole';
import { Link, useSearchParams } from 'react-router-dom';
import { mainAxios } from '../../axiosConfig';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { ReactComponent as MoreIcon } from '../../assets/ic_more.svg';
import { ReactComponent as DisableIcon } from '../../assets/ic_disable.svg';
import { ReactComponent as PlaceHolderIcon } from '../../assets/img_company_placeholder.svg';
import { ReactComponent as EnableIcon } from '../../assets/ic_enable.svg';
import { ReactComponent as WarningIcon } from '../../assets/ic_warning.svg';
import { ReactComponent as WarningWhiteIcon } from '../../assets/ic_warning_white.svg';
import { ReactComponent as ShieldIcon } from '../../assets/ic_shield.svg';
import { ReactComponent as ShieldWhiteIcon } from '../../assets/ic_shield_white.svg';
import { ReactComponent as AddIcon } from '../../assets/ic_add.svg';
import { ReactComponent as MinusIcon } from '../../assets/ic_minus.svg'
import { dateToString, getAvatar, getName, isValidValue, setupName } from '../../Utils';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import CustomInputDialog from '../../components/CustomInputDialog/CustomInputDialog';

const UserDetails = (params) => {
    const [queryParameters] = useSearchParams();
    const queryID = queryParameters.get("id");
    const [isShowMore, setMore] = useState(false);
    const [responseData, setResponseData] = useState({
        loading: false,
        data: {}
    });
    const [dialog, setDialog] = useState({
        showDialog: false,
        isDisable: false,
        isVerify: false,
        message: ""
    });
    const [inputDialog, setInputDialog] = useState({
        showDialog: false,
        title: "",
        action: "add"
    });
    const [isDisable, setDisable] = useState(false);
    let type = params.type;
    let isAdmin = type === 'admins';
    let isCustomer = type === 'customers';
    let isAgent = type === 'agents';

    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 1012, 1017, 101C
    const getUserDetails = async () => {
        try {
            const res = await mainAxios.get((isAdmin ? 'admin/' : isCustomer ? 'customer/' : 'agent/') + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: false,
                    data: isAdmin ? res.response.data.admin : isCustomer ? res.response.data.customer : res.response.data.agent
                });
                // const currentDate = new Date();
                let deactivateAt = isAdmin ? res.response.data.admin.deactivate_at : isCustomer ? res.response.data.customer.deactivate_at : res.response.data.agent.deactivate_at;
                // if (deactivateAt != null) {
                //     const deactivateAtDate = new Date(deactivateAt);
                //     setDisable(deactivateAtDate <= currentDate);
                // } else {
                //     setDisable(false);
                // }
                setDisable(deactivateAt != null);
            } else {
                setResponseData({
                    loading: false,
                    data: {}
                });
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: {}
            });
        }
    }

    const DetailsLayout = (props) => {
        return (
            <div className='details-layout'>
                <div className='details-layout-item'>
                    <p className={`${props.isAddMinus ? 'details-layout-add-edit-label' : ''}`}>{props.title}</p>
                </div>
                <div className='details-layout-item'>
                    {
                        props.isImage ? <div className='upload'>
                            {!isValidValue(responseData.data.avatar_url) ? <PlaceHolderIcon /> : <img className='upload-logo' src={responseData.data.avatar_url} alt="avatar" />}
                        </div>
                            : props.isFiles ?
                                <div className='links-container'>
                                    {!isValidValue(responseData.data.certifications) || responseData.data.certifications.length === 0 ?
                                        <div className='upload'>
                                            <PlaceHolderIcon />
                                            <div className='upload-image' onClick={() => document.querySelector(".input-logo").click()}>
                                                <label>Upload File</label>
                                                <input className='input-logo' type='file' accept='image/png, image/jpeg, image/jpg, application/pdf' name="attachment" hidden onChange={({ target: { files } }) => {
                                                    if (files && files[0]) {
                                                        uploadCertificateAPI(files[0]);
                                                    }
                                                }} />
                                            </div>
                                            <p>Maximum file size is 5MB. Only jpeg, jpg, png, & pdf is allowed.</p>
                                        </div> : responseData.data.certifications.map((item, key) => {
                                            return (
                                                <div key={key} className='upload upload-files'>
                                                    <Link to={item} rel="noreferrer" target='_blank' className='links'>{getFileName(item)}</Link>
                                                    <button className='remove-image' onClick={() => {
                                                        deleteCertificateAPI(getFileName(item));
                                                    }}>Remove</button>
                                                    <p>Maximum file size is 5MB. Only jpeg, jpg, png, & pdf is allowed.</p>
                                                </div>
                                            )
                                        })
                                    }
                                    {isValidValue(responseData.data.certifications) && responseData.data.certifications.length !== 0 && responseData.data.certifications.length < 2 ?
                                        <div className='upload new-files'>
                                            <PlaceHolderIcon />
                                            <div className='upload-image' onClick={() => document.querySelector(".input-logo").click()}>
                                                <label>Upload File</label>
                                                <input className='input-logo' type='file' accept='image/png, image/jpeg, image/jpg, application/pdf' name="attachment" hidden onChange={({ target: { files } }) => {
                                                    if (files && files[0]) {
                                                        uploadCertificateAPI(files[0]);
                                                    }
                                                }} />
                                            </div>
                                            <p>Maximum file size is 5MB. Only jpeg, jpg, png, & pdf is allowed.</p>
                                        </div> : undefined
                                    }
                                </div> : props.isAddMinus ?
                                    <div className='details-layout-add-edit'>
                                        <p>{props.content}</p>
                                        <AddIcon className='details-layout-add-icon' onClick={() => setInputDialog({ showDialog: true, title: 'Increase Grains', action: 'add' })} />
                                        <MinusIcon className='details-layout-minus-icon' onClick={() => setInputDialog({ showDialog: true, title: 'Reduce Grains', action: "reduce" })} />
                                    </div> : props.isVerify ? responseData.data.certification_verified ?
                                        <p>{props.content + "\u00A0\u00A0✓"}</p> :
                                        <div className='details-layout-link'>
                                            <p>{props.content}</p>
                                            <p onClick={verifyCertification}>Verify Now</p>
                                        </div> : <p>{props.content}</p>
                    }
                </div>
            </div>
        )
    }

    const verifyCertification = () => {
        setDialog({
            showDialog: true,
            isVerify: true,
            message: "Are you sure you want to verify this user's certification?"
        })
    }

    function getFileName(url) {
        const parsedUrl = new URL(url);
        const pathname = parsedUrl.pathname;
        const segments = pathname.split('/');
        return segments.pop();
    }

    // 201D
    const uploadCertificateAPI = async (files) => {
        setResponseData({
            ...responseData,
            loading: true
        });
        try {
            const formData = new FormData();
            formData.append("file", files);
            const res = await mainAxios.post('agent_certification/' + queryID, formData, {
                headers: { "Content-Type": 'multipart/form-data' }
            });
            if (res.isSuccess) {
                getUserDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false
            });
        }
    }

    //401D
    const deleteCertificateAPI = async (filename) => {
        setResponseData({
            ...responseData,
            loading: true
        });
        try {
            const res = await mainAxios.delete('agent_certification/' + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' },
                params: { "filename": filename }
            });
            if (res.isSuccess) {
                getUserDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false
            });
        }
    }

    // 3017, 301C
    const reactivateUserAPI = async (id) => {
        try {
            const res = await mainAxios.put(isCustomer ? 'customer_reactivate/' + id : 'agent_reactivate/' + id, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                getUserDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 301D
    const verifyCertificationAPI = async () => {
        setDialog({ showDialog: false })
        setResponseData({
            ...responseData,
            loading: true,
        });
        try {
            const res = await mainAxios.put('agent_certification_verified/' + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                getUserDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 301E
    const updateRedemptionPointsAPI = async (points) => {
        setInputDialog({ showDialog: false });
        try {
            const res = await mainAxios.put('agent_redemption_points/' + queryID, {
                redemption_points: points
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                getUserDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 4016, 401B
    const deactivateUserAPI = async (id) => {
        try {
            const res = await mainAxios.delete(isCustomer ? 'customer_deactivate/' + id : 'agent_deactivate/' + id, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                getUserDetails();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    function confirmStatus(id) {
        setDialog({ showDialog: false });
        if (isDisable) {
            reactivateUserAPI(id);
        } else {
            deactivateUserAPI(id);
        }
    }

    function createDialog() {
        return dialog.showDialog ? dialog.isDisable ?
            (
                <ConfirmDialog
                    id={queryID}
                    title={isDisable ? 'Enable User' : 'Disable User'}
                    subtitle={isDisable ? 'Enabling the account will allow the user to sign in or use the services of RiceBowl Xpress SG, continue?' : 'Disabling the account will not allow the user to sign in or use the services of RiceBowl Xpress SG, continue?'}
                    iconTitle={isDisable ? <ShieldIcon /> : <WarningIcon />}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Callback={(id) => setDialog({ showDialog: false })}
                    button2Icon={isDisable ? <ShieldWhiteIcon /> : <WarningWhiteIcon />}
                    button2Title={isDisable ? 'Enable' : 'Disable'}
                    button2Style={{ backgroundColor: isDisable ? 'rgba(0, 137, 38, 1)' : 'rgba(249, 165, 40, 1)', color: 'white' }}
                    button2Callback={(id) => confirmStatus(id, isDisable)}
                />
            ) : dialog.isVerify ?
                (
                    <ConfirmDialog
                        iconTitle={''}
                        title={''}
                        subtitle={dialog.message}
                        subTitleStyle={{ fontSize: 16 }}
                        backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                        button1Callback={(id) => setDialog({ showDialog: false })}
                        button2Icon={''}
                        button2Title={'OK'}
                        button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                        button2Callback={(id) => verifyCertificationAPI()}
                    />
                ) : (
                    <ConfirmDialog
                        iconTitle={''}
                        title={''}
                        subtitle={dialog.message}
                        subTitleStyle={{ fontSize: 16 }}
                        backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                        button1Style={{ visibility: "hidden" }}
                        button2Icon={''}
                        button2Title={'OK'}
                        button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                        button2Callback={(id) => setDialog({ showDialog: false })}
                    />
                )
            : null
    }

    function createInputDialog() {
        return inputDialog.showDialog ?
            (
                <CustomInputDialog
                    type={"points"}
                    title={inputDialog.title}
                    action={inputDialog.action}
                    backgroundButtonCallback={() => setInputDialog({ showDialog: false })}
                    button1Callback={() => setInputDialog({ showDialog: false })}
                    button2Style={{ backgroundColor: isDisable ? 'rgba(0, 137, 38, 1)' : 'rgba(249, 165, 40, 1)', color: 'white' }}
                    button2Callback={(points) => updateRedemptionPointsAPI(points)}
                />
            ) : null
    }

    const onMore = () => {
        setMore(!isShowMore);
    }

    const onStatus = () => {
        setDialog({
            showDialog: true,
            isDisable: true
        })
    }

    function getLastLogin() {
        const currentDate = new Date();
        var lastLogin = "";
        let lastLoginAt = responseData.data.last_login_at;
        if (isValidValue(lastLoginAt)) {
            const lastLoginAtDate = new Date(lastLoginAt);
            const timeDifference = Math.abs(lastLoginAtDate - currentDate);
            const seconds = Math.floor(timeDifference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const years = Math.round((timeDifference / 1000 / (60 * 60 * 24)) / 365.25);

            lastLogin = seconds > 59 ? minutes > 59 ? hours > 23 ? days > 364 ? years + (years > 1 ? " year ago" : " years ago") : days + (days > 1 ? " days ago" : " days ago") : hours + (hours > 1 ? " hours ago" : " hour ago") : minutes + (minutes > 1 ? " minutes ago" : " minute ago") : seconds + (seconds > 1 ? " seconds ago" : " second ago")
        }
        return lastLogin === "" ? "-" : lastLogin;
    }

    function onSidebarClick() { }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page={type} onSidebarClick={onSidebarClick} navChild={{ accounts: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="user-details">
                        <div className="header">
                            <div>
                                {isAdmin ? <p>Admin details</p> : isCustomer ? <p>Customer details</p> : <p>Agent details</p>}
                                {isAdmin ? <p>Admins &gt; Admin details</p> : isCustomer ? <p>Customers &gt; Customer details</p> : <p>Agents &gt; Agent details</p>}
                            </div>
                        </div>
                        <div className='content'>
                            <div className={`details-container ${isDisable ? 'details-disable' : ''}`}>
                                <div className='details'>
                                    <p className='details-title'>Account Information</p>
                                    {
                                        isAdmin ? <div className='details-form'>
                                            <DetailsLayout title="Name:" content={!isValidValue(responseData.data.username) ? "-" : responseData.data.username} />
                                            <DetailsLayout title="Email Address:" content={!isValidValue(responseData.data.login_id) ? "-" : responseData.data.login_id} />
                                        </div> : isCustomer ? <div className='details-form'>
                                            <DetailsLayout title="Name:" content={!isValidValue(setupName(responseData.data.first_name, responseData.data.last_name)) ? "-" : setupName(responseData.data.first_name, responseData.data.last_name)} />
                                            <DetailsLayout title="Company Code:" content={!isValidValue(responseData.data.company_code) ? "-" : responseData.data.company_code} />
                                            <DetailsLayout title="Email Address:" content={!isValidValue(responseData.data.login_id) ? "-" : responseData.data.login_id} />
                                            <DetailsLayout title="Mobile No:" content={!isValidValue(responseData.data.phone_number) ? "-" : !isValidValue(responseData.data.country_code) ? responseData.data.phone_number : responseData.data.country_code + " " + responseData.data.phone_number} />
                                            <DetailsLayout title="Date of Birth:" content={!isValidValue(responseData.data.birth_date) ? "-" : dateToString(new Date(responseData.data.birth_date), "D MMM YY")} />
                                            <DetailsLayout title="Your Photo:" isImage={true} />
                                        </div> : <div className='details-form'>
                                            <DetailsLayout title="Name:" isVerify={true} content={!isValidValue(setupName(responseData.data.first_name, responseData.data.last_name)) ? "-" : setupName(responseData.data.first_name, responseData.data.last_name)} />
                                            <DetailsLayout title="Email Address:" content={!isValidValue(responseData.data.login_id) ? "-" : responseData.data.login_id} />
                                            <DetailsLayout title="Mobile No:" content={!isValidValue(responseData.data.phone_number) ? "-" : !isValidValue(responseData.data.country_code) ? responseData.data.phone_number : responseData.data.country_code + " " + responseData.data.phone_number} />
                                            <DetailsLayout title="Date of Birth:" content={!isValidValue(responseData.data.birth_date) ? "-" : dateToString(new Date(responseData.data.birth_date), "D MMM YY")} />
                                            <DetailsLayout title="Your Photo:" isImage={true} />
                                            <DetailsLayout title="Certifications:" isFiles={true} />
                                        </div>
                                    }
                                </div>
                                <div className='more'>
                                    <div className='moreButtonContainer'>
                                        <label className='last-active'>Last active {getLastLogin()}</label>
                                        {
                                            isAdmin ? undefined : <button type='button' onClick={onMore} className='more-button'>
                                                <MoreIcon />
                                            </button>
                                        }
                                    </div>
                                    {
                                        isShowMore ?
                                            <div className={'moreAction'}>
                                                {isDisable ? <button className='option-enable' type='button' onClick={onStatus}>
                                                    <EnableIcon className='more-icon' />
                                                    Enable
                                                </button> : <button className='option-disable' type='button' onClick={onStatus}>
                                                    <DisableIcon className='more-icon' />
                                                    Disable
                                                </button>}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            {
                                isAdmin ? undefined : isCustomer ? <div className={`details-supporting ${isDisable ? 'details-disable' : ''}`} hidden={responseData.data.customer_addresses == null || responseData.data.customer_addresses.length <= 0}>
                                    {responseData.data.customer_addresses != null && responseData.data.customer_addresses.length > 0 ?
                                        responseData.data.customer_addresses.map((item, key) => {
                                            return (
                                                <div key={key} className='details-supporting-container'>
                                                    <label className='details-supporting-label'>Address {key + 1}</label>
                                                    <div className='details-supporting-form'>
                                                        <DetailsLayout title="Label:" content={!isValidValue(item.address_label) ? "-" : item.address_label} />
                                                        <DetailsLayout title="Timezone Offset:" content={!isValidValue(item.address_timezone_offset) ? "-" : item.address_timezone_offset} />
                                                        <DetailsLayout title="Address:" content={!isValidValue(item.address) ? "-" : item.address} />
                                                        <DetailsLayout title="Building Name:" content={!isValidValue(item.address_building) ? "-" : item.address_building} />
                                                        <DetailsLayout title="Unit:" content={!isValidValue(item.address_unit) ? "-" : item.address_unit} />
                                                        <DetailsLayout title="Note:" content={!isValidValue(item.address_note) ? "-" : item.address_note} />
                                                    </div>
                                                </div>
                                            )
                                        }) : undefined}
                                </div> : <div className={`details-supporting ${isDisable ? 'details-disable' : ''}`}>
                                    <div className='details-supporting-container'>
                                        <label className='details-supporting-label'>Redemption</label>
                                        <div className='details-supporting-form'>
                                            <DetailsLayout title="Codes:" content={!isValidValue(responseData.data.redemption_codes) ? "-" : responseData.data.redemption_codes.toUpperCase().split(',').join("\n")} />
                                            <DetailsLayout title="Grains:" isAddMinus={true} content={!isValidValue(responseData.data.redemption_points_left) ? "0" : responseData.data.redemption_points_left} />
                                        </div>
                                    </div>
                                    {responseData.data.emergency_contacts != null && responseData.data.emergency_contacts !== "" && JSON.parse(responseData.data.emergency_contacts).data != null ?
                                        JSON.parse(responseData.data.emergency_contacts).data.map((item, key) => {
                                            return (
                                                <div key={key} className='details-supporting-container'>
                                                    <label className='details-supporting-label'>Emergency Contact {key + 1}</label>
                                                    <div className='details-supporting-form'>
                                                        <DetailsLayout title="Name:" content={!isValidValue(item.name) ? "-" : item.name} />
                                                        <DetailsLayout title="Mobile No.:" content={!isValidValue(item.phone_number) ? "-" : isValidValue(item.country_code) ? item.country_code + " " + item.phone_number : item.phone_number} />
                                                    </div>
                                                </div>
                                            )
                                        }) : undefined}
                                </div>
                            }
                        </div>
                        {createDialog()}
                        {createInputDialog()}
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(UserDetails)