import { React, useEffect, useState } from 'react';
import './notificationTemplate.scss';
import { ReactComponent as AddIcon } from '../../../assets/ic_add.svg';
import { useNavigate } from "react-router-dom";
import CustomTable from '../../../components/CustomTable/CustomTable';
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import checkRole from '../../../checkRole';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { getAvatar, getName, getNotificationTypeName, sortTable, filterTable, getRoleTypeName, removeHTMLTags } from '../../../Utils';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import { ReactComponent as SendIcon } from '../../../assets/ic_sent.svg';
import { mainAxios } from '../../../axiosConfig';
import SearchBar from '../../../components/SearchBar/SearchBar';

const NotificationTemplate = (params) => {
    const navigate = useNavigate();

    useEffect(() => {
        getNotificationsTemplatesListAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [dialog, setDialog] = useState({
        showDialog: false,
        isDelete: false,
        isSent: false,
        selectedRow: {},
        message: ""
    });
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });
    const [sorting, setSorting] = useState({
        column: "",
        assending: true
    });
    const [filterValue, setFilterValue] = useState({
        searchValue: "",
        filterValue: []
    });
    const [filterList, setFilterList] = useState([]);

    const columns = [
        { label: "Type", accessor: "type_name", isSort: true, width: '20%', type: "string", isFilter: true },
        { label: "Role", accessor: "role_name", isSort: true, width: '20%', type: "string", isFilter: true },
        { label: "Title", accessor: "title", isSort: true, width: '40%', type: "string", isFilter: true },
        { label: "Push Notifications", accessor: "push_notification", isSort: true, type: "string", isFilter: true, width: '10%', styleColumn: { textAlign: 'center' } },
        { label: "Action", accessor: "action", isSort: false, width: '10%' }
    ]

    // 1052
    const getNotificationsTemplatesListAPI = async () => {
        try {
            const res = await mainAxios.get('notification_templates', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var arr = []
                var notifications = res.response.data.notification_templates;
                notifications.forEach(obj => {
                    var notificationData = JSON.parse(obj.data);
                    obj.type_name = getNotificationTypeName(obj.type);
                    obj.role_name = getRoleTypeName(obj.role);
                    if (obj.type === "e") {
                        obj.title = notificationData.title;
                        obj.title_string = notificationData.title;
                        obj.content_title = notificationData.content_title;
                        obj.content_description_html = notificationData.content_description_html;
                    } else {
                        obj.title = removeHTMLTags(notificationData.title_html);
                        obj.title_html = notificationData.title_html;
                        obj.message = removeHTMLTags(notificationData.message_html);
                        obj.message_html = notificationData.message_html;
                        obj.image_url = notificationData.image_url;
                        obj.icon_name = notificationData.icon_name;
                    }
                    obj.push_notification = obj.to_push ? "Yes" : "No";
                    obj.id = obj.notification_template_id;

                    arr.push(obj);
                })
                setResponseData({
                    loading: false,
                    data: arr
                });

                filter(filterValue, arr);
            } else {
                setResponseData({
                    loading: false,
                    data: []
                });

                filter(filterValue, []);
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: []
            });

            filter(filterValue, []);
        }
    }

    function onClickRow(id) {
        responseData.data.forEach(obj => {
            if (obj.id === id) {
                navigate("/notifications/template/" + id, { state: { id: id, data: obj } });
            }
        })
    }

    function onClickEdit(id) {
        responseData.data.forEach(obj => {
            if (obj.id === id) {
                navigate("/notifications/" + id + "/template-edit", { state: { id: id, data: obj } });
            }
        })
    }

    function onClickSent(id) {
        responseData.data.forEach(obj => {
            if (obj.id === id) {
                setDialog({
                    showDialog: true,
                    isDelete: false,
                    isSent: true,
                    selectedRow: obj
                })
            }
        })
    }

    function onClickDelete(id) {
        responseData.data.forEach(obj => {
            if (obj.id === id) {
                setDialog({
                    showDialog: true,
                    isDelete: true,
                    isSent: false,
                    selectedRow: obj
                })
            }
        })
    }

    // 4053
    async function confirmDelete(id) {
        setResponseData({
            loading: false,
            data: responseData.data,
        });
        setDialog({ showDialog: false, isDelete: false, isSent: false })

        try {
            const res = await mainAxios.delete('notification_template/' + id, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            getNotificationsTemplatesListAPI();
        } catch (error) {
            getNotificationsTemplatesListAPI();
        }
    }

    // 2054
    async function confirmSent(id) {
        setResponseData({
            loading: true,
            data: responseData.data,
        });
        setDialog({ showDialog: false, isDelete: false, isSent: false })

        try {
            const res = await mainAxios.post('send_notification/' + id, {}, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            setResponseData({
                loading: false,
                data: responseData.data,
            });
            if (res.isSuccess) {
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: responseData.data,
            });
        }
    }

    function createDialog() {
        return dialog.showDialog ? (
            dialog.isDelete ?
                (
                    <ConfirmDialog
                        id={(dialog.selectedRow ?? {}).id ?? 0}
                        title={'Delete Notification'}
                        subtitle={'Are you sure you want to delete this notification?'}
                        backgroundButtonCallback={(id) => setDialog({ showDialog: false, isDelete: false, isSent: false })}
                        button1Callback={(id) => setDialog({ showDialog: false, isDelete: false, isSent: false })}
                        button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                        button2Callback={(id) => confirmDelete(id)}
                    />
                )
                :
                dialog.isSent ?
                    (
                        <ConfirmDialog
                            id={(dialog.selectedRow ?? {}).id ?? 0}
                            title={'Sent Notification'}
                            subtitle={'Are you sure you want to sent this notification?'}
                            iconTitle={<SendIcon />}
                            backgroundButtonCallback={(id) => setDialog({ showDialog: false, isDelete: false, isSent: false })}
                            button1Callback={(id) => setDialog({ showDialog: false, isDelete: false, isSent: false })}
                            button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                            button2Callback={(id) => confirmSent(id)}
                            button2Icon={<SendIcon />}
                            button2Title={"Send"}
                        />
                    )
                    :
                    (
                        <ConfirmDialog
                            iconTitle={''}
                            title={''}
                            subtitle={dialog.message}
                            subTitleStyle={{ fontSize: 16 }}
                            backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                            button1Style={{ visibility: "hidden" }}
                            button2Icon={''}
                            button2Title={'OK'}
                            button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                            button2Callback={(id) => setDialog({ showDialog: false })}
                        />
                    )
        )
            : null
    }

    function onClickNew(id) {
        navigate("/notifications/template-add");
    }

    function onSidebarClick() {
        setResponseData({
            loading: true,
            data: []
        });
        getNotificationsTemplatesListAPI();
    }

    function onClickColumn(column, arr = undefined) {
        var data = sortTable(column, columns, arr ?? filterList);
        column.data = data;
        setSorting(column);
    }

    function filter(filter, arr) {
        var list = filterTable(filter, arr ?? responseData.data);
        setFilterList(list);
        onClickColumn(sorting, list)
    }

    function handleSearchChange(value) {
        setFilterValue(value)
        filter(value);
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="notification_template" onSidebarClick={onSidebarClick} navChild={{ manage_notifications: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className="notification_template">
                    <div className="header">
                        <p>NOTIFICATION TEMPLATES</p>
                        <SearchBar
                            handleSearchChange={(value) => handleSearchChange(value)}
                            value={filterValue}
                            filterList={columns}
                        />
                        <button onClick={() => onClickNew()}>
                            <span><AddIcon /></span>
                            <span>Add New</span>
                        </button>
                    </div>
                    <div className="table-container">
                        <CustomTable
                            columns={columns}
                            tableData={sorting.data ?? filterList}
                            numPerPage={10}
                            onClickRow={(id) => onClickRow(id)}
                            onClickEdit={(id) => onClickEdit(id)}
                            onClickDelete={(id) => onClickDelete(id)}
                            onClickSent={(id) => onClickSent(id)}
                            isSent={true}
                            sorting={sorting}
                            onClickColumn={(column) => onClickColumn(column)}
                        />
                    </div>
                    {createDialog()}
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(NotificationTemplate);