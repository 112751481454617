import { React, useEffect, useState } from 'react';
import "./dashboard.scss";
import { ReactComponent as PendingApplicationIcon } from '../../assets/ic_pending_applications.svg';
import { ReactComponent as EngagementUsersIcon } from '../../assets/ic_engagement_users.svg';
import { ReactComponent as UrgentJobsIcon } from '../../assets/ic_urgent_jobs.svg';
import { Link } from 'react-router-dom';
import { mainAxios } from '../../axiosConfig';
import { getCurrentSingaporeDate, getAvatar, getName } from '../../Utils.jsx'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import checkRole from '../../checkRole';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';

const Dashboard = (params) => {

  const [responseData, setResponseData] = useState({
    loading: true,
    data: {}
  });
  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState("");

  useEffect(() => {
    getDashboardAPI();
    updateDeviceToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 1001
  const getDashboardAPI = async () => {
    try {
      const res = await mainAxios.get('dashboard', {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        getUserAPI(res.response.data);
      } else {
        getUserAPI({});
      }
    } catch (error) {
      getUserAPI({});
    }
  }

  // 1011
  const getUserAPI = async (dashbaordData) => {
    try {
      const res = await mainAxios.get('admin', {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        setResponseData({
          loading: false,
          data: dashbaordData
        });
        const adminObject = {
          name: res.response.data.admin.username,
          avatar: res.response.data.admin.avatar_url,
          admin_id: res.response.data.admin.admin_id
        }
        window.localStorage.setItem("admin", JSON.stringify(adminObject));
        setName(res.response.data.admin.username);
        setAvatar(res.response.data.admin.avatar_url)
      } else {
        setResponseData({
          loading: false,
          data: dashbaordData
        });
      }
    } catch (error) {
      setResponseData({
        loading: false,
        data: dashbaordData
      });
    }
  }

  // 3003
  const updateDeviceToken = async () => {
    // var device_token = "";
    // var isChanged = false;
    // let object = JSON.parse(window.localStorage.getItem("device_token"));
    // if (object?.token) {
    //   device_token = object?.token;
    // }
    // if (object?.isChanged) {
    //   isChanged = object?.isChanged;
    // }

    // if (isChanged) {
    //   try {
    //     const res = await mainAxios.put('device', {
    //       // device_token: device_token
    //     }, {
    //       headers: { "Content-Type": 'application/x-www-form-urlencoded' }
    //     });
    //     if (res.isSuccess) {
    //       const deviceTokenObject = {
    //         token: device_token,
    //         isChanged: false,
    //       }
    //       window.localStorage.setItem("device_token", JSON.stringify(deviceTokenObject));
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  }

  const calculateEngagement = (activeUser, totalUser) => {
    return ((activeUser / totalUser) * 100).toFixed(2) + "%";
  }

  const CardLayout1 = (props) => {
    return (
      <div className='grid-item'>
        <div className='content'>
          <p>{props.title}</p>
          <p>{props.content}</p>
          <p>{props.desc}</p>
        </div>
        <div className='icon' style={{ backgroundColor: props.color }}>
          <props.icon />
        </div>
      </div>
    )
  }

  function getLinkTo(id, queryId) {
    switch (id) {
      case 0: return { pathname: "/companies/details", search: "?id=" + queryId };
      case 1: return { pathname: "/job/details", search: "?id=" + queryId };
      case 2: return { pathname: "details", search: "?id=" + queryId };
      default: break;
    }
  }
  const CardLayout2 = (props) => {
    return (
      <div className='grid-item-2'>
        <div className='content'>
          <div className='header'>
            <p>{props.title}</p>
            <Link className='link' to={props.link}>{props.linkName}</Link>
          </div>
          <table>
            <thead>
              {props.type === "applicants" ? (
                <tr className='tr-1'>
                  <th>Applicant Name</th>
                  <th>Contact</th>
                  <th>Job Applied</th>
                </tr>
              ) : (
                <tr className='tr-2'>
                  <th>Job Title</th>
                  <th>Company</th>
                  <th>Location</th>
                </tr>
              )}
            </thead>
            {Object.keys(responseData.data).length > 0 ? <tbody>
              {props.type === "applicants" ? responseData.data.new_applicants.map((item, key) => {
                return (
                  <tr key={key}>
                    <td><Link to={getLinkTo(2, item.member.member_id)} rel="noreferrer" target='_blank'>{item.member.username}</Link></td>
                    <td>{item.member.phone_number}</td>
                    <td><Link to={getLinkTo(1, item.job.job_id)} rel="noreferrer" target='_blank'>{item.job.title}</Link></td>
                  </tr>
                )
              }) : responseData.data.recent_jobs.map((item, key) => {
                return (
                  <tr key={key}>
                    <td><Link to={getLinkTo(1, item.job_id)} rel="noreferrer" target='_blank'>{item.title}</Link></td>
                    <td><Link to={getLinkTo(0, item.company.company_id)} rel="noreferrer" target='_blank'>{item.company.name}</Link></td>
                    <td>{item.is_company_location ? item.company.location : item.location}</td>
                  </tr>
                )
              })}
            </tbody> : ""}
          </table>
          {Object.keys(responseData.data).length > 0 ? props.type === "applicants" ? responseData.data.new_applicants.length > 0 ? "" : <div className='no-data'>No data found</div> : responseData.data.recent_jobs.length > 0 ? "" : <div className='no-data'>No data found</div> : <div className='no-data'>No data found</div>}
        </div>
      </div>
    )
  }

  function onSidebarClick() {
    setResponseData({
      loading: true,
      data: {}
    });
    getDashboardAPI();
    updateDeviceToken();
  }

  return (
    <div className='app-main'>
      <div className={`${params.navClose ? 'navClose' : ""}`}>
        <Sidebar page="dashboard" onSidebarClick={onSidebarClick} />
      </div>
      <div className='app-main-content'>
        <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
        <div className='app-content'>
          <div className='grid-container'>
            <CardLayout1 title="Pending Jobs" content={Object.keys(responseData.data).length > 0 ? responseData.data.total_pending_applications : "-"} desc={"As of " + getCurrentSingaporeDate("D MMM YYYY")} icon={PendingApplicationIcon} color="rgba(255, 178, 104, 0.15)" />
            <CardLayout1 title="Engagement of Users" content={Object.keys(responseData.data).length > 0 ? calculateEngagement(responseData.data.total_active_users, responseData.data.total_users) : "-"} desc={`${responseData.data.total_active_users} Active / ${responseData.data.total_users} Users`} icon={EngagementUsersIcon} color="rgba(99, 226, 241, 0.15)" />
            <CardLayout1 title="Urgent Jobs" content={Object.keys(responseData.data).length > 0 ? responseData.data.total_urgent : "-"} desc={"As of " + getCurrentSingaporeDate("D MMM YYYY")} icon={UrgentJobsIcon} color="rgba(40, 198, 111, 0.15)" />
          </div>
          <div className='grid-container-2'>
            <CardLayout2 title="New Applicants" linkName="See All Applicants" link="/applications" type="applicants" />
            <CardLayout2 title="Recent Jobs Posted" linkName="See All Jobs" link="/job" type="job" />
          </div>
        </div>
        {responseData.loading ? <LoadingSpinner /> : undefined}
      </div>
    </div>
  )
}

//Check Role => check Login
export default checkRole(Dashboard);