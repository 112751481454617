import { React, useEffect, useState } from 'react';
import './notificationHistoryDetails.scss';
import checkRole from '../../../checkRole';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { getAvatar, getName, isValidValue } from '../../../Utils';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { ReactComponent as TrashIcon } from '../../../assets/ic_trash.svg';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import { ReactComponent as MoreIcon } from '../../../assets/ic_more.svg';
import { ReactComponent as SendIcon } from '../../../assets/ic_sent.svg';
import { ReactComponent as CheckboxSelectedIcon } from '../../../assets/ic_checkbox_selected.svg';
import { ReactComponent as CheckboxUnselectedIcon } from '../../../assets/ic_checkbox_unselect.svg';
import { mainAxios } from '../../../axiosConfig';
import { ReactComponent as PlaceHolderIcon } from '../../../assets/img_company_placeholder.svg';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const NotificationHistoryDetails = (params) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isShowMore, setMore] = useState(false);
    const [responseData, setResponseData] = useState({
        loading: false,
        data: {}
    });
    const [dialog, setDialog] = useState({
        showDialog: false,
        isDelete: false,
        isSent: false,
    });
    const [statistics, setStatistics] = useState({
        totalUser: 0,
        notification: 0,
        pushNotification: 0
    })
    const [message, setMessage] = useState("");
    const [contentDescription, setContentDescription] = useState("");
    const isTemplate = params.type === "template"
    const emailType = "e";

    useEffect(() => {
        setResponseData({
            loading: false,
            data: location.state.data
        });
        if (isValidValue(location.state.data.message_html)) {
            setMessage(parse(DOMPurify.sanitize(location.state.data.message_html)));
        }
        if (isValidValue(location.state.data.content_description_html)) {
            setContentDescription(parse(DOMPurify.sanitize(location.state.data.content_description_html)));
        }
        filterNotification_API();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const links = document.querySelectorAll('#html-link a');
        links.forEach(link => {
            link.setAttribute('target', '_blank');
        });

    }, [message, statistics]);

    function getTotalSent(batches) {
        var count = 0;
        batches.forEach(element => {
            var arr = element.receiver_ids.split(",");
            count = count + arr.length;
        })
        return count;
    }

    // 2055
    async function filterNotification_API() {
        try {
            const res = await mainAxios.post('filter_notification', {
                role: location.state.data.role,
                filter: location.state.data.filter ?? {}
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setStatistics({
                    totalUser: res.response.data.total,
                    notification: res.response.data.filtered_total,
                    pushNotification: res.response.data.filtered_push_total
                })
            }
        } catch (error) {
        }
    }

    // 4053, 4051
    async function confirmDelete(id) {
        setResponseData({
            loading: false,
            data: responseData.data,
        });
        setDialog({ showDialog: false, isDelete: false, isSent: false, })

        try {
            const res = await mainAxios.delete((isTemplate ? 'notification_template/' : 'notification/') + id, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            onBack();
        } catch (error) {
            onBack();
        }
    }

    function onBack() {
        if (isTemplate) {
            navigate("/notifications/template");
        } else {
            navigate("/notifications/history");
        }
    }

    // 2054
    async function confirmSent(id) {
        setResponseData({
            loading: true,
            data: responseData.data,
        });
        setDialog({ showDialog: false, isDelete: false, isSent: false })

        try {
            const res = await mainAxios.post('send_notification/' + id, {}, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            setResponseData({
                loading: false,
                data: responseData.data,
            });

            if (res.isSuccess) {
                navigate("/notifications/history");
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: responseData.data,
            });
        }
    }

    function onDelete() {
        setDialog({ showDialog: true, isDelete: true, isSent: false });
    }

    function onClickEdit() {
        if (isTemplate) {
            navigate("/notifications/" + location.state.id + "/template-edit", { state: location.state })
        }
    }

    function onSend() {
        setDialog({ showDialog: true, isDelete: false, isSent: true });
    }

    function createDialog() {
        return dialog.showDialog ? (
            dialog.isDelete ?
                (
                    <ConfirmDialog
                        id={location.state.id}
                        title={'Delete Notification'}
                        subtitle={'Are you sure you want to delete this notification?'}
                        backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                        button1Callback={(id) => setDialog({ showDialog: false })}
                        button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                        button2Callback={(id) => confirmDelete(id)}
                    />
                )
                :
                dialog.isSent ?
                    (
                        <ConfirmDialog
                            id={location.state.id}
                            title={'Sent Notification'}
                            iconTitle={<SendIcon />}
                            subtitle={'Are you sure you want to sent this notification?'}
                            backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                            button1Callback={(id) => setDialog({ showDialog: false })}
                            button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                            button2Callback={(id) => confirmSent(id)}
                            button2Icon={<SendIcon />}
                            button2Title={"Send"}
                        />
                    )
                    :
                    (
                        <ConfirmDialog
                            iconTitle={''}
                            title={''}
                            subtitle={dialog.message}
                            subTitleStyle={{ fontSize: 16 }}
                            backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                            button1Style={{ visibility: "hidden" }}
                            button2Icon={''}
                            button2Title={'OK'}
                            button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                            button2Callback={(id) => setDialog({ showDialog: false })}
                        />
                    )
        )
            : null
    }

    const onMore = () => {
        setMore(!isShowMore);
    }

    function onSidebarClick() { }

    function drawReceiveIdsView(batches) {
        var idsViews = [];

        batches.forEach(element => {
            let ids = element.receiver_ids.split(",");
            ids.forEach(id => {
                if (idsViews.length > 0) {
                    idsViews.push(<pre key={id * 100}> ,  </pre>)
                }
                idsViews.push(
                    <div key={id}>
                        <Link to={getLinkTo(id)} rel="noreferrer" target='_blank'>{id}</Link>
                    </div>
                )
            })
        });

        return idsViews;
    }

    function getLinkTo(id) {
        if (responseData.data.role === "c") {
            return { pathname: "/customers/details", search: "?id=" + id }
        } else if (responseData.data.role === "a") {
            return { pathname: "/agents/details", search: "?id=" + id }
        }
    }

    const DetailsLayout = (props) => {
        let content = props.content;
        return (
            <div className='details-layout'>
                <div className='details-layout-item'>
                    <p>{props.title}</p>
                </div>
                <div id={props.isHTML ? 'html-link' : ''} className='details-layout-item'>
                    {
                        props.isUsers ?
                            (
                                <div className='receivedIds'>
                                    {drawReceiveIdsView(content)}
                                </div>
                            )
                            :
                            (
                                (props.isChecked !== undefined) ?
                                    (<p className='details-checkbox'>
                                        {(props.isChecked) ? <CheckboxSelectedIcon /> : <CheckboxUnselectedIcon />}
                                        {content}
                                    </p>)
                                    :
                                    (
                                        (props.isImage ?
                                            <div className='upload'>
                                                {!isValidValue(content) ? <PlaceHolderIcon /> : <img className={`${props.isIcon ? 'upload-icon' : 'upload-logo'}`} src={content} alt="Photo" />}
                                            </div>
                                            : props.isHTML ? props.isPreview ?
                                                <div className='details-preview'>
                                                    <b>{isValidValue(data.content_title) ? data.content_title : '$content_title'}</b>
                                                    <br /> <br />
                                                    Hi $first_name $last_name,
                                                    <br /> <br />
                                                    {isValidValue(contentDescription) ? contentDescription : '$content_description_html'}
                                                    {isValidValue(contentDescription) ? undefined : <br />}
                                                    <br />
                                                    Regards, <br />
                                                    The RiceGrains
                                                </div>
                                                : content : <p>{content}</p>
                                        )
                                    )
                            )
                    }
                </div>
            </div>
        )
    }

    function loadAge(from_age, to_age) {
        if (from_age === undefined && to_age === undefined) {
            return "All";
        } else if (to_age === undefined) {
            return `From ${from_age}`;
        } else if (from_age === undefined) {
            return `To ${to_age}`;
        }

        return `${from_age} to ${to_age}`;
    }

    function countTotalView(batches) {
        var count = 0;
        batches.forEach(element => {
            var arr = element.is_reads.split(",");
            arr.forEach(obj => {
                if (obj === 'true') {
                    count += 1;
                }
            })
        })
        return count;
    }

    var data = responseData.data ?? {};
    var filter = JSON.parse(data.filter ?? "{ }") ?? {};
    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page={isTemplate ? "notification_template" : "notification_history"} onSidebarClick={onSidebarClick} navChild={{ manage_notifications: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="notification_history_details">
                        <div className="header">
                            <div>
                                <p>Notifications</p>
                                {isTemplate ? <p>Notification Templates &gt; Notification detail</p> : <p>Notification History &gt; Notification detail</p>}
                            </div>
                            {isTemplate ?
                                <button className='detail-button' type='button'
                                    onClick={() => { onClickEdit() }}>
                                    Edit
                                </button>
                                : null
                            }
                        </div>
                        <div className='content'>
                            <div className={`details-container ${data.remove_at != null ? 'details-disable' : ''}`}>
                                <div className='details'>
                                    <p className='details-title'>Notifications Detail</p>
                                    <div className='details-form'>
                                        <DetailsLayout title="Notification Type:" content={data.type_name} />
                                        <DetailsLayout title="Role:" content={data.role_name} />
                                        {
                                            data.type === emailType ?
                                                <DetailsLayout title="Title:" content={data.title} /> :
                                                <DetailsLayout title="Title:" isHTML={true} content={isValidValue(data.title_html) ? parse(DOMPurify.sanitize(data.title_html)) : "-"} />
                                        }
                                        {
                                            data.type === emailType ?
                                                <DetailsLayout title="Content Title:" content={data.content_title} /> :
                                                <DetailsLayout title="Message:" isHTML={true} content={message !== "" ? message : "-"} />
                                        }
                                        {
                                            data.type === emailType ?
                                                <DetailsLayout title="Content Description:" isHTML={true} content={contentDescription !== "" ? contentDescription : "-"} /> : undefined
                                        }
                                        {
                                            data.type === emailType ?
                                                <DetailsLayout title="Content Preview:" isHTML={true} isPreview={true} /> : undefined
                                        }
                                        {
                                            data.type === emailType ? undefined :
                                                <DetailsLayout title="Icon:" isImage={true} isIcon={true} content={isValidValue(data.icon_name) ? require('../../../assets/' + data.icon_name + '.png') : ""} />
                                        }
                                        {
                                            data.type === emailType ? undefined :
                                                <DetailsLayout title="Photo:" isImage={true} content={data.image_url} />
                                        }
                                        <DetailsLayout title="Send Push Notifications" content={data.to_push ? "Yes" : "No"} />
                                        {
                                            isTemplate ? null :
                                                <DetailsLayout title="Total views:" content={countTotalView(data.batches ?? [])} />
                                        }
                                        {
                                            isTemplate ? null :
                                                <DetailsLayout title="Receiver Ids:" isUsers={true} content={data.batches ?? []} />
                                        }
                                    </div>
                                    {
                                        <div>
                                            <p className='details-title'>Notifications Filter {isTemplate ? `(${statistics.notification} / ${statistics.totalUser} users)` : `(${getTotalSent(data.batches ?? [])} users)`}</p>
                                            <div className='details-form'>
                                                <DetailsLayout title="Age Range:" content={loadAge(filter.from_age ?? undefined, filter.to_age ?? undefined)} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                {
                                    data.remove_at != null ? '' :
                                        <div className='more'>
                                            <div className='moreButtonContainer'>
                                                <button type='button' onClick={onMore} className='more-button'>
                                                    <MoreIcon />
                                                </button>
                                            </div>
                                            {
                                                isShowMore ?
                                                    <div className={'moreAction'}>
                                                        <button type='button' onClick={onDelete}>
                                                            <TrashIcon className='more-icon' />
                                                            Delete
                                                        </button>
                                                        {
                                                            isTemplate ?
                                                                <button type='button' onClick={onSend}>
                                                                    <SendIcon className='more-icon' />
                                                                    Send
                                                                </button>
                                                                : null
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                        {createDialog()}
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div >
    )
}

export default checkRole(NotificationHistoryDetails);