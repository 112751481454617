import { Navigate } from "react-router-dom";

export default function checkRole(WrapperComponent) {
    let localStorage = window.localStorage;
    return (props) => {
        let object = JSON.parse(localStorage.getItem("auth"));
        if (object?.isLogin === "1") {
            return <WrapperComponent {...props} />;
        } else {
            return <Navigate to="/" />;
        }
    }
}