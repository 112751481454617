import React, { useEffect, useState } from 'react'
import './faqs.scss'
import { useNavigate } from 'react-router-dom';
import checkRole from '../../../checkRole';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { mainAxios } from '../../../axiosConfig';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { getAvatar, getName } from '../../../Utils';

const FAQs = (params) => {
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });

    useEffect(() => {
        getSetup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 1002
    const getSetup = async () => {
        try {
            const res = await mainAxios.get('setup', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var array = [];
                if (res.response.data.faqs) {
                    array = JSON.parse(res.response.data.faqs);
                }
                setResponseData({
                    loading: false,
                    data: array
                });
            } else {
                setResponseData({
                    loading: false,
                    data: {}
                });
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: {}
            });
        }
    }

    function onSidebarClick() {
        setResponseData({
            loading: true,
            data: []
        });
        getSetup();
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="faqs" onSidebarClick={onSidebarClick} navChild={{ setup: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="faqs">
                        <div className="header">
                            <div>
                                <p>FAQs</p>
                            </div>
                            <button onClick={() => navigate("/setup/faqs/faqs-edit")}>
                                Edit
                            </button>
                        </div>
                        <div className='content'>
                            <div className='details'>
                                {responseData.data.map((data, key) => {
                                    return (
                                        <div key={key} className='details-layout'>
                                            <div className='details-layout-content'>
                                                <p>•</p>
                                                <p>{data.title}</p>
                                            </div>
                                            <div className='details-layout-content'>
                                                <p>•</p>
                                                <p>{data.content}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(FAQs);