import React from 'react'
import { ReactComponent as SortingUpIcon } from '../../assets/ic_sorting_up.svg';
import { ReactComponent as SortingDownIcon } from '../../assets/ic_sorting_down.svg';
import { ReactComponent as SortIcon } from '../../assets/ic_sort.svg';

const CustomTableHead = ({ columns, onClickColumn, sorting }) => {
    var sorting = sorting ?? {};
    var columnSelected = sorting.column ?? "";
    var isAssending = sorting.assending ?? false;

    function sort(accessor) {
        if (accessor === sorting.column) {
            isAssending = !isAssending;
        } else {
            isAssending = true;
        }
        onClickColumn({
            column: accessor,
            assending: isAssending
        })
    }

    return (
        <thead>
            <tr>
                {columns.map(({ label, accessor, isSort, width, justifyContent }) => {
                    return (
                        <th key={accessor}
                            style={{
                                width: width ?? '',
                            }}
                        >
                            <div
                                className='custom-table-head'
                                style={{
                                    justifyContent: accessor === "action" ? 'center' : (justifyContent ?? '')
                                }}
                            >
                                {label}
                                {isSort ? (columnSelected === accessor ? (isAssending ? <SortingUpIcon /> : <SortingDownIcon />) : <SortIcon />) : ""}
                                {isSort ? <button style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} onClick={() => sort(accessor)} /> : ""}
                            </div>
                        </th>
                    )
                })}
            </tr>
        </thead >
    )
}

export default CustomTableHead