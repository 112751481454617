import DOMPurify from "dompurify";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import moment from "moment-timezone";

export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const getCurrentSingaporeDate = (format) => {
    const now = new Date();
    let currentTime = moment(now).tz('Asia/Singapore').format(format);
    return currentTime;
}

export const dateToString = (date, format) => {
    let newDate = moment(date).format(format);
    return newDate;
}

export const timeToString = (time, format) => {
    let newTime = moment(time, "HH:mm:ss");
    return newTime.format(format).toLowerCase();
}

export const getTimeZoneOffset = () => {
    const offset = new Date().getTimezoneOffset();
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    const sign = offset <= 0 ? '+' : '-';
    return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const getGMTString = () => {
    const timezone_offset = getTimeZoneOffset();
    const [sign, time] = [timezone_offset.charAt(0), timezone_offset.substr(1)];
    const [hours, minutes] = time.split(":").map(Number);
    return `GMT${sign}${hours + (minutes / 60)}`;
}

export const getDateTimeWithGMT = (datetime, to_timezone_offset, format) => {
    if (isValidValue(to_timezone_offset)) {
        const [sign, time] = [to_timezone_offset.charAt(0), to_timezone_offset.substr(1)];
        const [hours, minutes] = time.split(":").map(Number);
        const from_timezone_offset = getTimeZoneOffset();
        const originalTime = moment(datetime).utcOffset(from_timezone_offset);
        const convertedTime = originalTime.clone().utcOffset(to_timezone_offset);
        return convertedTime.format(format) + ` (GMT${sign}${hours + (minutes / 60)})`;
    } else {
        return "-";
    }
}

export function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
}

export function removeHTMLTags(str) {
    if (str !== undefined) {
        return str.replace(/<[^>]+>/g, ' ').replace(/\s+/g, ' ').trim();
    } else {
        return "";
    }
}

export function replaceEmptyPTagWithBrTa(htmlString) {
    return htmlString.replace(/<p><\/p>/gi, "<br/>");
}

export function replaceBrTagWithEmptyPTag(htmlString) {
    return htmlString.replace(/<br\s*\/?>/gi, "<p><br></p>");
}

export function createEditorStateFromHtml(htmlString) {
    const sanitizedHtml = DOMPurify.sanitize(replaceBrTagWithEmptyPTag(htmlString));
    const contentState = stateFromHTML(sanitizedHtml);
    return EditorState.createWithContent(contentState);
}

export function setupName(first_name, last_name) {
    return isValidValue(first_name) ? isValidValue(last_name) ? first_name + " " + last_name : first_name : isValidValue(last_name) ? last_name : null;
}

export function isValidValue(value) {
    return !(value === undefined || value === null || value === "");
}

export function validateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,}@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true;
    }
    return false;
}

export function getName() {
    var name = "";
    let object = JSON.parse(window.localStorage.getItem("admin"));
    if (object?.name) {
        name = object?.name;
    }
    return name;
}

export function getAvatar() {
    var avatar = "";
    let object = JSON.parse(window.localStorage.getItem("admin"));
    if (object?.avatar) {
        avatar = object?.avatar;
    }
    return avatar;
}

export function getAdminId() {
    var admin_id = "";
    let object = JSON.parse(window.localStorage.getItem("admin"));
    if (object?.admin_id) {
        admin_id = object?.admin_id;
    }
    return admin_id;
}

export function filterTable(filterData, array) {
    var list = [];
    var searchValue = filterData.searchValue ?? "";
    var filterValue = filterData.filterValue ?? {};

    if (searchValue !== "" && Object.keys(filterValue).length > 0) {
        array.forEach(obj => {
            var item = JSON.parse(JSON.stringify(obj));
            var hasChecked = false;

            [filterValue].forEach(opt => {
                if ((obj[opt.accessor] ?? "").toString().toLowerCase().includes(searchValue.toLowerCase())) {
                    hasChecked = true;
                }
            })

            if (hasChecked) {
                list.push(item);
            }
        })
    } else {
        list = JSON.parse(JSON.stringify(array));
    }

    return list;
}

export function sortTable(column, columns, array) {
    var list = JSON.parse(JSON.stringify(array));
    var isAssending = column.assending;

    columns.forEach(obj => {
        if (column.column === obj.accessor) {
            var type = obj.type;
            var sortingKey = obj.sortingKey ?? column.column;

            if (type === "string") {
                list.sort((a, b) => {
                    if (a[sortingKey] !== undefined && b[sortingKey] !== undefined) {
                        let ca = a[sortingKey].toLowerCase();
                        let cb = b[sortingKey].toLowerCase();

                        if (ca < cb) {
                            return isAssending ? -1 : 1;
                        }
                        if (ca > cb) {
                            return isAssending ? 1 : -1;
                        }
                    }
                    return 0;
                });
            } else if (type === "number") {
                if (isAssending) {
                    list.sort((a, b) => a[sortingKey] - b[sortingKey]);
                } else {
                    list.sort((a, b) => b[sortingKey] - a[sortingKey]);
                }
            } else if (type === "date") {
                if (isAssending) {
                    list.sort((a, b) => new Date(a[sortingKey]) - new Date(b[sortingKey]));
                } else {
                    list.sort((a, b) => new Date(b[sortingKey]) - new Date(a[sortingKey]));
                }
            }
        }
    })

    return list;
}

export function errorsMapping(error) {
    var message = error;
    if (error.includes("NonEmptyField")) {
        message = error.split("=").length > 1 ? `${error.split("=")[1]} cannot be empty.` : message = error;
    } else if (error.includes("SmsWait")) {
        message = error.split("=").length > 1 ? `Please wait ${error.split("=")[1]}s before requesting another SMS.` : message = error;
    } else if (error.includes("MaxFiles")) {
        message = error.split("=").length > 1 ? `Maximum of ${error.split("=")[1]} files only.` : message = error;
    } else {
        switch (error) {
            case "NoReceivers": message = "Cannot send notification due to no target receivers."; break;
            case "InvalidRequest": message = "Unable to proceed with your request."; break;
            case "InvalidFileFormat": message = "File format is not supported."; break;
            case "InvalidFileSize": message = "File size is too large."; break;
            case "InvalidCode": message = "Verification code is wrong."; break;
            case "InvalidPassword": message = "Password is wrong."; break;
            case "InvalidIdOrPassword": message = "Email or password is wrong."; break;
            case "IdExisted": message = "This email is already in use. Please try another email address."; break;
            case "PhoneExisted": message = "This mobile number is already in use. Please try another number."; break;
            case "DifferentAddressTimezone": message = "Cannot set the address to a different timezone."; break;
            case "SamePassword": message = "Cannot use the same password."; break;
            case "SmsFailed": message = "Failed to send SMS."; break;
            default: message = error; break;
        }
    }
    return message;
}

export const getNotificationTypeName = (type) => {
    let list = NotificationTypeOption();
    var name = "";
    list.forEach(element => {
        if (element.value === type) {
            name = element.label;
        }
    });

    return name;
}

export const getFullNotificationTypeName = (type) => {
    let list = FullNotificationTypeOption();
    var name = "";
    list.forEach(element => {
        if (element.value === type) {
            name = element.label;
        }
    });

    return name;
}

export const getRoleTypeName = (type) => {
    let list = RoleTypeOption();
    var name = "";
    list.forEach(element => {
        if (element.value === type) {
            name = element.label;
        }
    });

    return name;
}

export const getRegionTypeName = (type) => {
    let list = RegionDropDownOption();
    var name = "";

    list.forEach(element => {
        if (element.value === type) {
            name = element.label;
        }
    });

    return name;
}

export const NotificationTypeOption = () => {
    return [
        {
            label: "Promotion",
            value: 'p'
        },
        {
            label: "Email",
            value: 'e'
        }
    ]
}

export const FullNotificationTypeOption = () => {
    return [
        {
            label: "Promotion",
            value: 'p'
        },
        {
            label: "Email",
            value: 'e'
        },
        {
            label: "Activities",
            value: 'a'
        }
    ]
}

export const IconOption = () => {
    return [
        {
            label: "notification_icon_tag",
            value: 'notification_icon_tag',
            icon: "notification_icon_tag"
        }
    ]
}

export const RoleTypeOption = () => {
    return [
        {
            label: "Customer",
            value: 'c'
        },
        {
            label: "Agent",
            value: 'a'
        }
    ]
}

export const AgentBannerTypeOption = () => {
    return [
        {
            label: "No Certs",
            value: 'nc'
        }
    ]
}

export const RegionDropDownOption = () => {
    return [
        {
            label: "All Locations",
            value: 'a'
        },
        {
            label: "Central",
            value: 'c'
        },
        {
            label: "East",
            value: 'e'
        },
        {
            label: "North",
            value: 'n'
        },
        {
            label: "North-East",
            value: 'ne'
        },
        {
            label: "West",
            value: 'w'
        },
    ]
}

export const ApplicationStatusDropDownOption = () => {
    return [
        {
            label: "Pending",
            value: 'Pending',
            color: 'rgba(95, 182, 216, 1)'
        },
        {
            label: "Reviewed",
            value: 'Reviewed',
            color: 'rgba(255, 138, 0, 1)'
        },
        {
            label: "Under Offer",
            value: 'Under Offer',
            color: 'rgba(249, 216, 0, 1)'
        },
        {
            label: "Unsuccessful Application",
            value: 'Unsuccessful Application',
            color: 'rgba(255, 42, 4, 1)'
        },
        {
            label: "Hired",
            value: 'Hired',
            color: 'rgba(0, 137, 38, 1)'
        },
        {
            label: "Offer Rejected",
            value: 'Offer Rejected',
            color: 'rgba(123, 129, 138, 1)'
        },
    ]
}

export const CountryCodeDropDownOption = () => {
    return [
        {
            "name": "Afghanistan",
            "value": "+93",
            "flag": "🇦🇫",
            "code": "AF"
        },
        {
            "name": "Aland Islands",
            "value": "+358",
            "flag": "🇦🇽",
            "code": "AX"
        },
        {
            "name": "Albania",
            "value": "+355",
            "flag": "🇦🇱",
            "code": "AL"
        },
        {
            "name": "Algeria",
            "value": "+213",
            "flag": "🇩🇿",
            "code": "DZ"
        },
        {
            "name": "AmericanSamoa",
            "value": "+1684",
            "flag": "🇦🇸",
            "code": "AS"
        },
        {
            "name": "Andorra",
            "value": "+376",
            "flag": "🇦🇩",
            "code": "AD"
        },
        {
            "name": "Angola",
            "value": "+244",
            "flag": "🇦🇴",
            "code": "AO"
        },
        {
            "name": "Anguilla",
            "value": "+1264",
            "flag": "🇦🇮",
            "code": "AI"
        },
        {
            "name": "Antarctica",
            "value": "+672",
            "flag": "🇦🇶",
            "code": "AQ"
        },
        {
            "name": "Antigua and Barbuda",
            "value": "+1268",
            "flag": "🇦🇬",
            "code": "AG"
        },
        {
            "name": "Argentina",
            "value": "+54",
            "flag": "🇦🇷",
            "code": "AR"
        },
        {
            "name": "Armenia",
            "value": "+374",
            "flag": "🇦🇲",
            "code": "AM"
        },
        {
            "name": "Aruba",
            "value": "+297",
            "flag": "🇦🇼",
            "code": "AW"
        },
        {
            "name": "Australia",
            "value": "+61",
            "flag": "🇦🇺",
            "code": "AU"
        },
        {
            "name": "Austria",
            "value": "+43",
            "flag": "🇦🇹",
            "code": "AT"
        },
        {
            "name": "Azerbaijan",
            "value": "+994",
            "flag": "🇦🇿",
            "code": "AZ"
        },
        {
            "name": "Bahamas",
            "value": "+1242",
            "flag": "🇧🇸",
            "code": "BS"
        },
        {
            "name": "Bahrain",
            "value": "+973",
            "flag": "🇧🇭",
            "code": "BH"
        },
        {
            "name": "Bangladesh",
            "value": "+880",
            "flag": "🇧🇩",
            "code": "BD"
        },
        {
            "name": "Barbados",
            "value": "+1246",
            "flag": "🇧🇧",
            "code": "BB"
        },
        {
            "name": "Belarus",
            "value": "+375",
            "flag": "🇧🇾",
            "code": "BY"
        },
        {
            "name": "Belgium",
            "value": "+32",
            "flag": "🇧🇪",
            "code": "BE"
        },
        {
            "name": "Belize",
            "value": "+501",
            "flag": "🇧🇿",
            "code": "BZ"
        },
        {
            "name": "Benin",
            "value": "+229",
            "flag": "🇧🇯",
            "code": "BJ"
        },
        {
            "name": "Bermuda",
            "value": "+1441",
            "flag": "🇧🇲",
            "code": "BM"
        },
        {
            "name": "Bhutan",
            "value": "+975",
            "flag": "🇧🇹",
            "code": "BT"
        },
        {
            "name": "Bolivia, Plurinational State of",
            "value": "+591",
            "flag": "🇧🇴",
            "code": "BO"
        },
        {
            "name": "Bosnia and Herzegovina",
            "value": "+387",
            "flag": "🇧🇦",
            "code": "BA"
        },
        {
            "name": "Botswana",
            "value": "+267",
            "flag": "🇧🇼",
            "code": "BW"
        },
        {
            "name": "Brazil",
            "value": "+55",
            "flag": "🇧🇷",
            "code": "BR"
        },
        {
            "name": "British Indian Ocean Territory",
            "value": "+246",
            "flag": "🇮🇴",
            "code": "IO"
        },
        {
            "name": "Brunei Darussalam",
            "value": "+673",
            "flag": "🇧🇳",
            "code": "BN"
        },
        {
            "name": "Bulgaria",
            "value": "+359",
            "flag": "🇧🇬",
            "code": "BG"
        },
        {
            "name": "Burkina Faso",
            "value": "+226",
            "flag": "🇧🇫",
            "code": "BF"
        },
        {
            "name": "Burundi",
            "value": "+257",
            "flag": "🇧🇮",
            "code": "BI"
        },
        {
            "name": "Cambodia",
            "value": "+855",
            "flag": "🇰🇭",
            "code": "KH"
        },
        {
            "name": "Cameroon",
            "value": "+237",
            "flag": "🇨🇲",
            "code": "CM"
        },
        {
            "name": "Canada",
            "value": "+1",
            "flag": "🇨🇦",
            "code": "CA"
        },
        {
            "name": "Cape Verde",
            "value": "+238",
            "flag": "🇨🇻",
            "code": "CV"
        },
        {
            "name": "Cayman Islands",
            "value": "+345",
            "flag": "🇰🇾",
            "code": "KY"
        },
        {
            "name": "Central African Republic",
            "value": "+236",
            "flag": "🇨🇫",
            "code": "CF"
        },
        {
            "name": "Chad",
            "value": "+235",
            "flag": "🇹🇩",
            "code": "TD"
        },
        {
            "name": "Chile",
            "value": "+56",
            "flag": "🇨🇱",
            "code": "CL"
        },
        {
            "name": "China",
            "value": "+86",
            "flag": "🇨🇳",
            "code": "CN"
        },
        {
            "name": "Christmas Island",
            "value": "+61",
            "flag": "🇨🇽",
            "code": "CX"
        },
        {
            "name": "Cocos (Keeling) Islands",
            "value": "+61",
            "flag": "🇨🇨",
            "code": "CC"
        },
        {
            "name": "Colombia",
            "value": "+57",
            "flag": "🇨🇴",
            "code": "CO"
        },
        {
            "name": "Comoros",
            "value": "+269",
            "flag": "🇰🇲",
            "code": "KM"
        },
        {
            "name": "Congo",
            "value": "+242",
            "flag": "🇨🇬",
            "code": "CG"
        },
        {
            "name": "Congo, The Democratic Republic of the Congo",
            "value": "+243",
            "flag": "🇨🇩",
            "code": "CD"
        },
        {
            "name": "Cook Islands",
            "value": "+682",
            "flag": "🇨🇰",
            "code": "CK"
        },
        {
            "name": "Costa Rica",
            "value": "+506",
            "flag": "🇨🇷",
            "code": "CR"
        },
        {
            "name": "Cote d'Ivoire",
            "value": "+225",
            "flag": "🇨🇮",
            "code": "CI"
        },
        {
            "name": "Croatia",
            "value": "+385",
            "flag": "🇭🇷",
            "code": "HR"
        },
        {
            "name": "Cuba",
            "value": "+53",
            "flag": "🇨🇺",
            "code": "CU"
        },
        {
            "name": "Cyprus",
            "value": "+357",
            "flag": "🇨🇾",
            "code": "CY"
        },
        {
            "name": "Czech Republic",
            "value": "+420",
            "flag": "🇨🇿",
            "code": "CZ"
        },
        {
            "name": "Denmark",
            "value": "+45",
            "flag": "🇩🇰",
            "code": "DK"
        },
        {
            "name": "Djibouti",
            "value": "+253",
            "flag": "🇩🇯",
            "code": "DJ"
        },
        {
            "name": "Dominica",
            "value": "+1767",
            "flag": "🇩🇲",
            "code": "DM"
        },
        {
            "name": "Dominican Republic",
            "value": "+1849",
            "flag": "🇩🇴",
            "code": "DO"
        },
        {
            "name": "Ecuador",
            "value": "+593",
            "flag": "🇪🇨",
            "code": "EC"
        },
        {
            "name": "Egypt",
            "value": "+20",
            "flag": "🇪🇬",
            "code": "EG"
        },
        {
            "name": "El Salvador",
            "value": "+503",
            "flag": "🇸🇻",
            "code": "SV"
        },
        {
            "name": "Equatorial Guinea",
            "value": "+240",
            "flag": "🇬🇶",
            "code": "GQ"
        },
        {
            "name": "Eritrea",
            "value": "+291",
            "flag": "🇪🇷",
            "code": "ER"
        },
        {
            "name": "Estonia",
            "value": "+372",
            "flag": "🇪🇪",
            "code": "EE"
        },
        {
            "name": "Ethiopia",
            "value": "+251",
            "flag": "🇪🇹",
            "code": "ET"
        },
        {
            "name": "Falkland Islands (Malvinas)",
            "value": "+500",
            "flag": "🇫🇰",
            "code": "FK"
        },
        {
            "name": "Faroe Islands",
            "value": "+298",
            "flag": "🇫🇴",
            "code": "FO"
        },
        {
            "name": "Fiji",
            "value": "+679",
            "flag": "🇫🇯",
            "code": "FJ"
        },
        {
            "name": "Finland",
            "value": "+358",
            "flag": "🇫🇮",
            "code": "FI"
        },
        {
            "name": "France",
            "value": "+33",
            "flag": "🇫🇷",
            "code": "FR"
        },
        {
            "name": "French Guiana",
            "value": "+594",
            "flag": "🇬🇫",
            "code": "GF"
        },
        {
            "name": "French Polynesia",
            "value": "+689",
            "flag": "🇵🇫",
            "code": "PF"
        },
        {
            "name": "Gabon",
            "value": "+241",
            "flag": "🇬🇦",
            "code": "GA"
        },
        {
            "name": "Gambia",
            "value": "+220",
            "flag": "🇬🇲",
            "code": "GM"
        },
        {
            "name": "Georgia",
            "value": "+995",
            "flag": "🇬🇪",
            "code": "GE"
        },
        {
            "name": "Germany",
            "value": "+49",
            "flag": "🇩🇪",
            "code": "DE"
        },
        {
            "name": "Ghana",
            "value": "+233",
            "flag": "🇬🇭",
            "code": "GH"
        },
        {
            "name": "Gibraltar",
            "value": "+350",
            "flag": "🇬🇮",
            "code": "GI"
        },
        {
            "name": "Greece",
            "value": "+30",
            "flag": "🇬🇷",
            "code": "GR"
        },
        {
            "name": "Greenland",
            "value": "+299",
            "flag": "🇬🇱",
            "code": "GL"
        },
        {
            "name": "Grenada",
            "value": "+1473",
            "flag": "🇬🇩",
            "code": "GD"
        },
        {
            "name": "Guadeloupe",
            "value": "+590",
            "flag": "🇬🇵",
            "code": "GP"
        },
        {
            "name": "Guam",
            "value": "+1671",
            "flag": "🇬🇺",
            "code": "GU"
        },
        {
            "name": "Guatemala",
            "value": "+502",
            "flag": "🇬🇹",
            "code": "GT"
        },
        {
            "name": "Guernsey",
            "value": "+44",
            "flag": "🇬🇬",
            "code": "GG"
        },
        {
            "name": "Guinea",
            "value": "+224",
            "flag": "🇬🇳",
            "code": "GN"
        },
        {
            "name": "Guinea-Bissau",
            "value": "+245",
            "flag": "🇬🇼",
            "code": "GW"
        },
        {
            "name": "Guyana",
            "value": "+595",
            "flag": "🇬🇾",
            "code": "GY"
        },
        {
            "name": "Haiti",
            "value": "+509",
            "flag": "🇭🇹",
            "code": "HT"
        },
        {
            "name": "Holy See (Vatican City State)",
            "value": "+379",
            "flag": "🇻🇦",
            "code": "VA"
        },
        {
            "name": "Honduras",
            "value": "+504",
            "flag": "🇭🇳",
            "code": "HN"
        },
        {
            "name": "Hong Kong",
            "value": "+852",
            "flag": "🇭🇰",
            "code": "HK"
        },
        {
            "name": "Hungary",
            "value": "+36",
            "flag": "🇭🇺",
            "code": "HU"
        },
        {
            "name": "Iceland",
            "value": "+354",
            "flag": "🇮🇸",
            "code": "IS"
        },
        {
            "name": "India",
            "value": "+91",
            "flag": "🇮🇳",
            "code": "IN"
        },
        {
            "name": "Indonesia",
            "value": "+62",
            "flag": "🇮🇩",
            "code": "ID"
        },
        {
            "name": "Iran, Islamic Republic of Persian Gulf",
            "value": "+98",
            "flag": "🇮🇷",
            "code": "IR"
        },
        {
            "name": "Iraq",
            "value": "+964",
            "flag": "🇮🇷",
            "code": "IQ"
        },
        {
            "name": "Ireland",
            "value": "+353",
            "flag": "🇮🇪",
            "code": "IE"
        },
        {
            "name": "Isle of Man",
            "value": "+44",
            "flag": "🇮🇲",
            "code": "IM"
        },
        {
            "name": "Israel",
            "value": "+972",
            "flag": "🇮🇱",
            "code": "IL"
        },
        {
            "name": "Italy",
            "value": "+39",
            "flag": "🇮🇹",
            "code": "IT"
        },
        {
            "name": "Jamaica",
            "value": "+1876",
            "flag": "🇯🇲",
            "code": "JM"
        },
        {
            "name": "Japan",
            "value": "+81",
            "flag": "🇯🇵",
            "code": "JP"
        },
        {
            "name": "Jersey",
            "value": "+44",
            "flag": "🇯🇪",
            "code": "JE"
        },
        {
            "name": "Jordan",
            "value": "+962",
            "flag": "🇯🇴",
            "code": "JO"
        },
        {
            "name": "Kazakhstan",
            "value": "+77",
            "flag": "🇰🇿",
            "code": "KZ"
        },
        {
            "name": "Kenya",
            "value": "+254",
            "flag": "🇰🇪",
            "code": "KE"
        },
        {
            "name": "Kiribati",
            "value": "+686",
            "flag": "🇰🇮",
            "code": "KI"
        },
        {
            "name": "Korea, Democratic People's Republic of Korea",
            "value": "+850",
            "flag": "🇰🇵",
            "code": "KP"
        },
        {
            "name": "Korea, Republic of South Korea",
            "value": "+82",
            "flag": "🇰🇷",
            "code": "KR"
        },
        {
            "name": "Kuwait",
            "value": "+965",
            "flag": "🇰🇼",
            "code": "KW"
        },
        {
            "name": "Kyrgyzstan",
            "value": "+996",
            "flag": "🇰🇬",
            "code": "KG"
        },
        {
            "name": "Laos",
            "value": "+856",
            "flag": "🇱🇦",
            "code": "LA"
        },
        {
            "name": "Latvia",
            "value": "+371",
            "flag": "🇱🇻",
            "code": "LV"
        },
        {
            "name": "Lebanon",
            "value": "+961",
            "flag": "🇱🇧",
            "code": "LB"
        },
        {
            "name": "Lesotho",
            "value": "+266",
            "flag": "🇱🇸",
            "code": "LS"
        },
        {
            "name": "Liberia",
            "value": "+231",
            "flag": "🇱🇷",
            "code": "LR"
        },
        {
            "name": "Libyan Arab Jamahiriya",
            "value": "+218",
            "flag": "🇱🇾",
            "code": "LY"
        },
        {
            "name": "Liechtenstein",
            "value": "+423",
            "flag": "🇱🇮",
            "code": "LI"
        },
        {
            "name": "Lithuania",
            "value": "+370",
            "flag": "🇱🇹",
            "code": "LT"
        },
        {
            "name": "Luxembourg",
            "value": "+352",
            "flag": "🇱🇺",
            "code": "LU"
        },
        {
            "name": "Macao",
            "value": "+853",
            "flag": "🇲🇴",
            "code": "MO"
        },
        {
            "name": "Macedonia",
            "value": "+389",
            "flag": "🇲🇰",
            "code": "MK"
        },
        {
            "name": "Madagascar",
            "value": "+261",
            "flag": "🇲🇬",
            "code": "MG"
        },
        {
            "name": "Malawi",
            "value": "+265",
            "flag": "🇲🇼",
            "code": "MW"
        },
        {
            "name": "Malaysia",
            "value": "+60",
            "flag": "🇲🇾",
            "code": "MY"
        },
        {
            "name": "Maldives",
            "value": "+960",
            "flag": "🇲🇻",
            "code": "MV"
        },
        {
            "name": "Mali",
            "value": "+223",
            "flag": "🇲🇱",
            "code": "ML"
        },
        {
            "name": "Malta",
            "value": "+356",
            "flag": "🇲🇹",
            "code": "MT"
        },
        {
            "name": "Marshall Islands",
            "value": "+692",
            "flag": "🇲🇭",
            "code": "MH"
        },
        {
            "name": "Martinique",
            "value": "+596",
            "flag": "🇲🇶",
            "code": "MQ"
        },
        {
            "name": "Mauritania",
            "value": "+222",
            "flag": "🇲🇷",
            "code": "MR"
        },
        {
            "name": "Mauritius",
            "value": "+230",
            "flag": "🇲🇺",
            "code": "MU"
        },
        {
            "name": "Mayotte",
            "value": "+262",
            "flag": "🇾🇹",
            "code": "YT"
        },
        {
            "name": "Mexico",
            "value": "+52",
            "flag": "🇲🇽",
            "code": "MX"
        },
        {
            "name": "Micronesia, Federated States of Micronesia",
            "value": "+691",
            "flag": "🇫🇲",
            "code": "FM"
        },
        {
            "name": "Moldova",
            "value": "+373",
            "flag": "🇲🇩",
            "code": "MD"
        },
        {
            "name": "Monaco",
            "value": "+377",
            "flag": "🇲🇨",
            "code": "MC"
        },
        {
            "name": "Mongolia",
            "value": "+976",
            "flag": "🇲🇳",
            "code": "MN"
        },
        {
            "name": "Montenegro",
            "value": "+382",
            "flag": "🇲🇪",
            "code": "ME"
        },
        {
            "name": "Montserrat",
            "value": "+1664",
            "flag": "🇲🇸",
            "code": "MS"
        },
        {
            "name": "Morocco",
            "value": "+212",
            "flag": "🇲🇦",
            "code": "MA"
        },
        {
            "name": "Mozambique",
            "value": "+258",
            "flag": "🇲🇿",
            "code": "MZ"
        },
        {
            "name": "Myanmar",
            "value": "+95",
            "flag": "🇲🇲",
            "code": "MM"
        },
        {
            "name": "Namibia",
            "flag": "🇳🇦",
            "value": "+264",
            "code": "NA"
        },
        {
            "name": "Nauru",
            "value": "+674",
            "flag": "🇳🇷",
            "code": "NR"
        },
        {
            "name": "Nepal",
            "value": "+977",
            "flag": "🇳🇵",
            "code": "NP"
        },
        {
            "name": "Netherlands",
            "value": "+31",
            "flag": "🇳🇱",
            "code": "NL"
        },
        {
            "name": "Netherlands Antilles",
            "value": "+599",
            "flag": "🇧🇶",
            "code": "AN"
        },
        {
            "name": "New Caledonia",
            "value": "+687",
            "flag": "🇳🇨",
            "code": "NC"
        },
        {
            "name": "New Zealand",
            "value": "+64",
            "flag": "🇳🇿",
            "code": "NZ"
        },
        {
            "name": "Nicaragua",
            "value": "+505",
            "flag": "🇳🇮",
            "code": "NI"
        },
        {
            "name": "Niger",
            "value": "+227",
            "flag": "🇳🇪",
            "code": "NE"
        },
        {
            "name": "Nigeria",
            "value": "+234",
            "flag": "🇳🇬",
            "code": "NG"
        },
        {
            "name": "Niue",
            "value": "+683",
            "flag": "🇳🇺",
            "code": "NU"
        },
        {
            "name": "Norfolk Island",
            "value": "+672",
            "flag": "🇳🇫",
            "code": "NF"
        },
        {
            "name": "Northern Mariana Islands",
            "value": "+1670",
            "flag": "🇲🇵",
            "code": "MP"
        },
        {
            "name": "Norway",
            "value": "+47",
            "flag": "🇳🇴",
            "code": "NO"
        },
        {
            "name": "Oman",
            "value": "+968",
            "flag": "🇴🇲",
            "code": "OM"
        },
        {
            "name": "Pakistan",
            "value": "+92",
            "flag": "🇵🇰",
            "code": "PK"
        },
        {
            "name": "Palau",
            "value": "+680",
            "flag": "🇵🇼",
            "code": "PW"
        },
        {
            "name": "Palestinian Territory, Occupied",
            "value": "+970",
            "flag": "🇵🇸",
            "code": "PS"
        },
        {
            "name": "Panama",
            "value": "+507",
            "flag": "🇵🇦",
            "code": "PA"
        },
        {
            "name": "Papua New Guinea",
            "value": "+675",
            "flag": "🇵🇬",
            "code": "PG"
        },
        {
            "name": "Paraguay",
            "value": "+595",
            "flag": "🇵🇾",
            "code": "PY"
        },
        {
            "name": "Peru",
            "value": "+51",
            "flag": "🇵🇪",
            "code": "PE"
        },
        {
            "name": "Philippines",
            "value": "+63",
            "flag": "🇵🇭",
            "code": "PH"
        },
        {
            "name": "Pitcairn",
            "value": "+872",
            "flag": "🇵🇳",
            "code": "PN"
        },
        {
            "name": "Poland",
            "value": "+48",
            "flag": "🇵🇱",
            "code": "PL"
        },
        {
            "name": "Portugal",
            "value": "+351",
            "flag": "🇵🇹",
            "code": "PT"
        },
        {
            "name": "Puerto Rico",
            "value": "+1939",
            "flag": "🇵🇷",
            "code": "PR"
        },
        {
            "name": "Qatar",
            "value": "+974",
            "flag": "🇶🇦",
            "code": "QA"
        },
        {
            "name": "Romania",
            "value": "+40",
            "flag": "🇷🇴",
            "code": "RO"
        },
        {
            "name": "Russia",
            "value": "+7",
            "flag": "🇷🇺",
            "code": "RU"
        },
        {
            "name": "Rwanda",
            "value": "+250",
            "flag": "🇷🇼",
            "code": "RW"
        },
        {
            "name": "Reunion",
            "value": "+262",
            "flag": "🇷🇪",
            "code": "RE"
        },
        {
            "name": "Saint Barthelemy",
            "value": "+590",
            "flag": "🇧🇱",
            "code": "BL"
        },
        {
            "name": "Saint Helena, Ascension and Tristan Da Cunha",
            "value": "+290",
            "flag": "🇸🇭",
            "code": "SH"
        },
        {
            "name": "Saint Kitts and Nevis",
            "value": "+1869",
            "flag": "🇰🇳",
            "code": "KN"
        },
        {
            "name": "Saint Lucia",
            "value": "+1758",
            "flag": "🇱🇨",
            "code": "LC"
        },
        {
            "name": "Saint Martin",
            "value": "+590",
            "flag": "🇲🇫",
            "code": "MF"
        },
        {
            "name": "Saint Pierre and Miquelon",
            "value": "+508",
            "flag": "🇵🇲",
            "code": "PM"
        },
        {
            "name": "Saint Vincent and the Grenadines",
            "value": "+1784",
            "flag": "🇻🇨",
            "code": "VC"
        },
        {
            "name": "Samoa",
            "value": "+685",
            "flag": "🇼🇸",
            "code": "WS"
        },
        {
            "name": "San Marino",
            "value": "+378",
            "flag": "🇸🇲",
            "code": "SM"
        },
        {
            "name": "Sao Tome and Principe",
            "value": "+239",
            "flag": "🇸🇹",
            "code": "ST"
        },
        {
            "name": "Saudi Arabia",
            "value": "+966",
            "flag": "🇸🇦",
            "code": "SA"
        },
        {
            "name": "Senegal",
            "value": "+221",
            "flag": "🇸🇳",
            "code": "SN"
        },
        {
            "name": "Serbia",
            "value": "+381",
            "flag": "🇷🇸",
            "code": "RS"
        },
        {
            "name": "Seychelles",
            "value": "+248",
            "flag": "🇸🇨",
            "code": "SC"
        },
        {
            "name": "Sierra Leone",
            "value": "+232",
            "flag": "🇸🇱",
            "code": "SL"
        },
        {
            "name": "Singapore",
            "value": "+65",
            "flag": "🇸🇬",
            "code": "SG"
        },
        {
            "name": "Slovakia",
            "value": "+421",
            "flag": "🇸🇰",
            "code": "SK"
        },
        {
            "name": "Slovenia",
            "value": "+386",
            "flag": "🇸🇮",
            "code": "SI"
        },
        {
            "name": "Solomon Islands",
            "value": "+677",
            "flag": "🇸🇧",
            "code": "SB"
        },
        {
            "name": "Somalia",
            "value": "+252",
            "flag": "🇸🇴",
            "code": "SO"
        },
        {
            "name": "South Africa",
            "value": "+27",
            "flag": "🇿🇦",
            "code": "ZA"
        },
        {
            "name": "South Sudan",
            "value": "+211",
            "flag": "🇸🇸",
            "code": "SS"
        },
        {
            "name": "South Georgia and the South Sandwich Islands",
            "value": "+500",
            "flag": "🇬🇸",
            "code": "GS"
        },
        {
            "name": "Spain",
            "value": "+34",
            "flag": "🇪🇸",
            "code": "ES"
        },
        {
            "name": "Sri Lanka",
            "value": "+94",
            "flag": "🇱🇰",
            "code": "LK"
        },
        {
            "name": "Sudan",
            "value": "+249",
            "flag": "🇸🇩",
            "code": "SD"
        },
        {
            "name": "Suriname",
            "value": "+597",
            "flag": "🇸🇷",
            "code": "SR"
        },
        {
            "name": "Svalbard and Jan Mayen",
            "value": "+47",
            "flag": "🇸🇯",
            "code": "SJ"
        },
        {
            "name": "Swaziland",
            "value": "+268",
            "flag": "🇸🇿",
            "code": "SZ"
        },
        {
            "name": "Sweden",
            "value": "+46",
            "flag": "🇸🇪",
            "code": "SE"
        },
        {
            "name": "Switzerland",
            "value": "+41",
            "flag": "🇨🇭",
            "code": "CH"
        },
        {
            "name": "Syrian Arab Republic",
            "value": "+963",
            "flag": "🇸🇾",
            "code": "SY"
        },
        {
            "name": "Taiwan",
            "value": "+886",
            "flag": "🇹🇼",
            "code": "TW"
        },
        {
            "name": "Tajikistan",
            "value": "+992",
            "flag": "🇹🇯",
            "code": "TJ"
        },
        {
            "name": "Tanzania, United Republic of Tanzania",
            "value": "+255",
            "flag": "🇹🇿",
            "code": "TZ"
        },
        {
            "name": "Thailand",
            "value": "+66",
            "flag": "🇹🇭",
            "code": "TH"
        },
        {
            "name": "Timor-Leste",
            "value": "+670",
            "flag": "🇹🇱",
            "code": "TL"
        },
        {
            "name": "Togo",
            "value": "+228",
            "flag": "🇹🇬",
            "code": "TG"
        },
        {
            "name": "Tokelau",
            "value": "+690",
            "flag": "🇹🇰",
            "code": "TK"
        },
        {
            "name": "Tonga",
            "value": "+676",
            "flag": "🇹🇴",
            "code": "TO"
        },
        {
            "name": "Trinidad and Tobago",
            "value": "+1868",
            "flag": "🇹🇹",
            "code": "TT"
        },
        {
            "name": "Tunisia",
            "value": "+216",
            "flag": "🇹🇳",
            "code": "TN"
        },
        {
            "name": "Turkey",
            "value": "+90",
            "flag": "🇹🇷",
            "code": "TR"
        },
        {
            "name": "Turkmenistan",
            "value": "+993",
            "flag": "🇹🇲",
            "code": "TM"
        },
        {
            "name": "Turks and Caicos Islands",
            "value": "+1649",
            "flag": "🇹🇨",
            "code": "TC"
        },
        {
            "name": "Tuvalu",
            "value": "+688",
            "flag": "🇹🇻",
            "code": "TV"
        },
        {
            "name": "Uganda",
            "value": "+256",
            "flag": "🇺🇬",
            "code": "UG"
        },
        {
            "name": "Ukraine",
            "value": "+380",
            "flag": "🇺🇦",
            "code": "UA"
        },
        {
            "name": "United Arab Emirates",
            "value": "+971",
            "flag": "🇦🇪",
            "code": "AE"
        },
        {
            "name": "United Kingdom",
            "value": "+44",
            "flag": "🇬🇧",
            "code": "GB"
        },
        {
            "name": "United States",
            "value": "+1",
            "flag": "🇺🇸",
            "code": "US"
        },
        {
            "name": "Uruguay",
            "value": "+598",
            "flag": "🇺🇾",
            "code": "UY"
        },
        {
            "name": "Uzbekistan",
            "value": "+998",
            "flag": "🇺🇿",
            "code": "UZ"
        },
        {
            "name": "Vanuatu",
            "value": "+678",
            "flag": "🇻🇺",
            "code": "VU"
        },
        {
            "name": "Venezuela, Bolivarian Republic of Venezuela",
            "value": "+58",
            "flag": "🇻🇪",
            "code": "VE"
        },
        {
            "name": "Vietnam",
            "value": "+84",
            "flag": "🇻🇳",
            "code": "VN"
        },
        {
            "name": "Virgin Islands, British",
            "value": "+1284",
            "flag": "🇻🇬",
            "code": "VG"
        },
        {
            "name": "Virgin Islands, U.S.",
            "value": "+1340",
            "flag": "🇻🇮",
            "code": "VI"
        },
        {
            "name": "Wallis and Futuna",
            "value": "+681",
            "flag": "🇼🇫",
            "code": "WF"
        },
        {
            "name": "Yemen",
            "value": "+967",
            "flag": "🇾🇪",
            "code": "YE"
        },
        {
            "name": "Zambia",
            "value": "+260",
            "flag": "🇿🇲",
            "code": "ZM"
        },
        {
            "name": "Zimbabwe",
            "value": "+263",
            "flag": "🇿🇼",
            "code": "ZW"
        }
    ]
}

export const RateDropDownOption = () => {
    return [
        // {
        //     label: "☆☆☆☆☆",
        //     value: '0',
        // },
        {
            label: "★☆☆☆☆",
            value: '1',
        },
        {
            label: "★★☆☆☆",
            value: '2',
        },
        {
            label: "★★★☆☆",
            value: '3',
        },
        {
            label: "★★★★☆",
            value: '4',
        },
        {
            label: "★★★★★",
            value: '5',
        },
    ]
}