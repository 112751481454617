import React, { useEffect, useState } from 'react'
import './addRedemptionVoucher.scss'
import { ReactComponent as TickIcon } from '../../../assets/ic_tick.svg';
import { ReactComponent as PlaceHolderIcon } from '../../../assets/img_company_placeholder.svg';
import { ReactComponent as RadioCheckedIcon } from '../../../assets/ic_radio_checked.svg'
import { ReactComponent as RadioUncheckedIcon } from '../../../assets/ic_radio_unchecked.svg'
import { useNavigate } from 'react-router-dom';
import checkRole from '../../../checkRole';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { mainAxios } from '../../../axiosConfig';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { blockInvalidChar, dateToString, getAvatar, getName, isValidValue, replaceEmptyPTagWithBrTa } from '../../../Utils';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';

const AddRedemptionVoucher = (params) => {
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        showDialog: false,
        message: ""
    });
    const [responseData, setResponseData] = useState({
        loading: false
    });
    const [category, setCategory] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState(EditorState.createEmpty());
    const [limitedQuatitiy, setLimitedQuantity] = useState(true);
    const [quantity, setQuantity] = useState('');
    const [redemptionPoints, setRedemptionPoints] = useState('');
    const [startAt, setStartAt] = useState('');
    const [endAt, setEndAt] = useState('');
    const [expireAt, setExpireAt] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [imageData, setImageData] = useState('');

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSave = (event) => {
        event.preventDefault();

        const contentHTML = convertToHTML({
            styleToHTML: (style) => {
                if (style === "BOLD") {
                    return <b />;
                } else if (style === "ITALIC") {
                    return <i />;
                }
            },
            entityToHTML: (entity, originalText) => {
                if (entity.type === 'LINK') {
                    const { url } = entity.data;
                    return <a href={url}>{originalText}</a>
                }
                return originalText;
            }
        })(content.getCurrentContent());

        if (category.length > 0 && title.length > 0 && (contentHTML && contentHTML.trim() !== '<p></p>') &&
            quantity.length !== "" && redemptionPoints.length !== "" && endAt.length > 0 && imageData !== "") {
            setResponseData({
                ...responseData,
                loading: true,
            });
            submitNewRedemptionVoucher();
        } else {
            setDialog({ showDialog: true, message: "Please complete all required fields." });
        }
    }

    // 2063
    const submitNewRedemptionVoucher = async () => {
        try {
            const contentHTML = convertToHTML({
                styleToHTML: (style) => {
                    if (style === "BOLD") {
                        return <b />;
                    } else if (style === "ITALIC") {
                        return <i />;
                    }
                },
                entityToHTML: (entity, originalText) => {
                    if (entity.type === 'LINK') {
                        const { url } = entity.data;
                        return <a href={url}>{originalText}</a>
                    }
                    return originalText;
                }
            })(content.getCurrentContent());

            const formData = new FormData();
            formData.append("file", imageData);
            formData.append("category", category);
            formData.append("title", title);
            formData.append("content_html", replaceEmptyPTagWithBrTa(contentHTML));
            formData.append("is_limited_quantity", limitedQuatitiy);
            formData.append("quantity", quantity);
            formData.append("redemption_points", redemptionPoints);
            if (isValidValue(startAt)) {
                formData.append("start_at", dateToString(startAt, "YYYY-MM-DD HH:mm:ss"));
            }
            formData.append("end_at", dateToString(endAt, "YYYY-MM-DD HH:mm:ss"));
            if (isValidValue(expireAt)) {
                formData.append("expire_at", dateToString(expireAt, "YYYY-MM-DD HH:mm:ss"));
            }

            const res = await mainAxios.post('redemption_voucher', formData, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
                navigate("/redemption/voucher");
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    function createDialog() {
        return dialog.showDialog ?
            (
                <ConfirmDialog
                    iconTitle={''}
                    title={''}
                    subtitle={dialog.message}
                    subTitleStyle={{ fontSize: 16 }}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Style={{ visibility: "hidden" }}
                    button2Icon={''}
                    button2Title={'OK'}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => setDialog({ showDialog: false })}
                />
            )
            : null
    }

    function onSidebarClick() { }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="redemption_vouchers" onSidebarClick={onSidebarClick} navChild={{ redemption: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="add-edit-redemption-voucher">
                        <form onSubmit={handleSave}>
                            <div className="header">
                                <div>
                                    <p>Add new redemption voucher</p>
                                    <p>Redemption Voucher &gt; Add new redemption voucher </p>
                                </div>
                                <button type='submit'>
                                    <span><TickIcon /></span>
                                    <span>Save</span>
                                </button>
                            </div>
                            <div className='content'>
                                <div className='details'>
                                    <div className='details-form'>
                                        <div className='details-div'>
                                            <label className='required'>Category:</label>
                                            <div className='details-div-layout'>
                                                <input className='details-div-layout-input' type='text' onChange={(e) => setCategory(e.target.value)} value={category} required />
                                            </div>
                                        </div>
                                        <div className='details-div'>
                                            <label className='required'>Title:</label>
                                            <div className='details-div-layout'>
                                                <input className='details-div-layout-input' type='text' onChange={(e) => setTitle(e.target.value)} value={title} required />
                                            </div>
                                        </div>
                                        <div className='details-div form-textarea details-div-html'>
                                            <label className='required'>Content:</label>
                                            <div className='details-div-layout'>
                                                {/* dropdown option not working in strict mode */}
                                                <Editor
                                                    toolbar={{
                                                        options: ['inline', 'list', 'link', 'emoji', 'history'],
                                                        inline: {
                                                            options: ['bold', 'italic', 'underline'],
                                                        }
                                                    }}
                                                    editorState={content}
                                                    toolbarClassName='notification-toolbar'
                                                    wrapperClassName="notification-wrapper"
                                                    editorClassName="notification-editor"
                                                    onEditorStateChange={(e) => setContent(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='details-div'>
                                            <label className='required'>Limited Quantity:</label>
                                            <div className='details-div-layout'>
                                                <div className='details-div-icon'>
                                                    <div className='details-div-radio' onClick={() => setLimitedQuantity(true)}>
                                                        {limitedQuatitiy ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                                                        <label className='details-div-radio-label'>Yes</label>
                                                    </div>
                                                    <div className='details-div-radio2' onClick={() => setLimitedQuantity(false)}>
                                                        {limitedQuatitiy ? <RadioUncheckedIcon /> : <RadioCheckedIcon />}
                                                        <label className='details-div-radio-label'>No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='details-div'>
                                            <label className='required'>Quantity:</label>
                                            <div className='details-div-layout'>
                                                <input className='details-div-layout-input' type='number' onKeyDown={blockInvalidChar} onChange={(e) => setQuantity(e.target.value)} value={quantity} min={0} required />
                                            </div>
                                        </div>
                                        <div className='details-div'>
                                            <label className='required'>Redemption Points:</label>
                                            <div className='details-div-layout'>
                                                <input className='details-div-layout-input' type='number' onKeyDown={blockInvalidChar} onChange={(e) => setRedemptionPoints(e.target.value)} value={redemptionPoints} min={0} required />
                                            </div>
                                        </div>
                                        <div className='details-div'>
                                            <label>Start At:</label>
                                            <div className='details-div-layout'>
                                                <input className='details-div-layout-input' type='datetime-local' onChange={(e) => setStartAt(e.target.value)} value={startAt ?? ""} />
                                            </div>
                                        </div>
                                        <div className='details-div'>
                                            <label className='required'>End At:</label>
                                            <div className='details-div-layout'>
                                                <input className='details-div-layout-input' type='datetime-local' onChange={(e) => setEndAt(e.target.value)} value={endAt ?? ""} />
                                            </div>
                                        </div>
                                        <div className='details-div'>
                                            <label>Expire At:</label>
                                            <div className='details-div-layout'>
                                                <input className='details-div-layout-input' type='datetime-local' onChange={(e) => setExpireAt(e.target.value)} value={expireAt ?? ""} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='details-image'>
                                    <div className='logo'>
                                        <label className='required'>Image:</label>
                                        <div className='upload'>
                                            {isValidValue(imageSrc) ? <img className='upload-logo' src={imageSrc} alt="" /> : <PlaceHolderIcon />}
                                            <div className='upload-image' onClick={() => document.querySelector(".input-logo").click()}>
                                                <label>Upload Image</label>
                                                <input className='input-logo' type='file' accept='image/png, image/jpeg, image/jpg' hidden onChange={({ target: { files } }) => {
                                                    if (files && files[0]) {
                                                        setImageData(files[0]);
                                                        const reader = new FileReader();
                                                        reader.onloadend = () => {
                                                            setImageSrc(reader.result);
                                                        }
                                                        reader.readAsDataURL(files[0]);
                                                    }
                                                }} />
                                            </div>
                                            {isValidValue(imageSrc) ? <button className='remove-image' onClick={() => {
                                                document.querySelector(".input-logo").value = "";
                                                setImageSrc('');
                                                setImageData('');
                                            }}>Remove</button> : ""}
                                            <p>File size: maximum 5 MB, size: (375 x 375 px)</p>
                                            <p>File extension: .JPG, .JPEG, .PNG</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {createDialog()}
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(AddRedemptionVoucher);