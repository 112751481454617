import React, { useEffect, useState } from 'react'
import './transactionDetails.scss'
import { Link, useLocation } from 'react-router-dom';
import checkRole from '../../checkRole';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { dateToString, getAvatar, getName, isValidValue } from '../../Utils';

const TransactionDetails = (params) => {
    const location = useLocation();
    const [responseData, setResponseData] = useState({
        loading: false,
        data: location.state.data
    });
    let isTransactionsIn = location.state.type === "in";

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const DetailsLayout = (props) => {
        return (
            <div className='details-layout'>
                <p>{props.title}</p>
                {!isValidValue(props.screen) ? <p>{props.content}</p> : <p>
                    <Link to={getLinkTo(props.screen)} rel="noreferrer" target='_blank'>{props.content}</Link>
                </p>}
            </div>
        )
    }

    function getLinkTo(id) {
        var bookingId = "";
        if (isTransactionsIn) {
            let booking = responseData.data.booking ?? {};
            bookingId = booking.booking_id;
        } else {
            let session = responseData.data.session ?? {};
            let booking = session.booking ?? {};
            bookingId = booking.booking_id;
        }
        switch (id) {
            case 0: return { pathname: "/booking/details", search: "?id=" + bookingId };
        }
    }

    function onSidebarClick() { }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page={isTransactionsIn ? "transactions_in" : "transactions_out"} onSidebarClick={onSidebarClick} navChild={{ transactions: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="transaction-details">
                        <div className="header">
                            <div>
                                <p>Transaction details</p>
                                <p>{isTransactionsIn ? "Transactions In" : "Transactions Out"} &gt; Transaction details </p>
                            </div>
                        </div>
                        <div className='content'>
                            {isTransactionsIn ?
                                <div className='details'>
                                    <DetailsLayout title="Type:" content={!isValidValue(responseData.data.transaction_type) ? "-" : responseData.data.transaction_type} />
                                    {responseData.data.type === "booking" ? <DetailsLayout title="Booking Ref:" screen={!isValidValue(responseData.data.code) ? undefined : 0} content={!isValidValue(responseData.data.code) ? "-" : responseData.data.code} /> : undefined}
                                    {responseData.data.type === "booking" ? <DetailsLayout title="Pay Amount:" content={!responseData.data.booking ? "-" : !isValidValue(responseData.data.booking.pay_amount) ? "-" : "$ " + responseData.data.booking.pay_amount.toFixed(2)} /> : undefined}
                                    {responseData.data.type === "booking" ? <DetailsLayout title="Pay Credits:" content={!responseData.data.booking ? "-" : !isValidValue(responseData.data.booking.pay_credits) ? "-" : responseData.data.booking.pay_credits + "c"} /> : undefined}
                                    {responseData.data.type === "topup" ? <DetailsLayout title="Amount:" content={!responseData.data.topup ? "-" : !isValidValue(responseData.data.topup.amount) ? "-" : "$ " + responseData.data.topup.amount.toFixed(2)} /> : undefined}
                                    {responseData.data.type === "topup" ? <DetailsLayout title="Receive Credits:" content={!responseData.data.topup ? "-" : !isValidValue(responseData.data.topup.receive_credits) ? "-" : responseData.data.topup.receive_credits + "c"} /> : undefined}
                                    {responseData.data.type === "booking" ? <DetailsLayout title="Pay at:" content={!responseData.data.booking ? "-" : !isValidValue(responseData.data.booking.pay_at) ? "-" : dateToString(responseData.data.booking.pay_at, "D MMM YY, h:mma")} /> :
                                        <DetailsLayout title="Pay at:" content={!responseData.data.topup ? "-" : !isValidValue(responseData.data.topup.pay_at) ? "-" : dateToString(responseData.data.topup.pay_at, "D MMM YY, h:mma")} />}
                                </div> :
                                <div className='details'>
                                    <DetailsLayout title="Type:" content={!isValidValue(responseData.data.transaction_type) ? "-" : responseData.data.transaction_type} />
                                    <DetailsLayout title="Booking Ref:" screen={!isValidValue(responseData.data.booking_ref) ? undefined : 0} content={!isValidValue(responseData.data.booking_ref) ? "-" : responseData.data.booking_ref} />
                                    <DetailsLayout title="Session Ref:" content={!responseData.data.session ? "-" : !isValidValue(responseData.data.session.session_code) ? "-" : responseData.data.session.session_code} />
                                    <DetailsLayout title="Amount:" content={!responseData.data.session ? "-" : !isValidValue(responseData.data.session.amount) ? "-" : "$ " + responseData.data.session.amount.toFixed(2)} />
                                    <DetailsLayout title="Pay at:" content={!responseData.data.session ? "-" : !isValidValue(responseData.data.session.pay_at) ? "-" : dateToString(responseData.data.session.pay_at, "D MMM YY, h:mma")} />
                                </div>}
                        </div>
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(TransactionDetails);