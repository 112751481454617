import { React, useEffect, useState } from 'react';
import './accountDeletion.scss';
import CustomTable from '../../components/CustomTable/CustomTable';
import checkRole from '../../checkRole';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { getAvatar, getName, sortTable, filterTable, isValidValue, setupName } from '../../Utils';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import { mainAxios } from '../../axiosConfig';
import SearchBar from '../../components/SearchBar/SearchBar';

const UsAccountDeletion = (params) => {
    const [dialog, setDialog] = useState({
        showDialog: false,
        isDelete: false,
        selectedRow: {},
        message: ""
    });
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });
    const [sorting, setSorting] = useState({
        column: "",
        assending: true
    });
    const [filterValue, setFilterValue] = useState({
        searchValue: "",
        filterValue: []
    });
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        getUserListAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        { label: "Role", accessor: "user_role", isSort: true, width: '15%', type: "string", isFilter: true },
        { label: "Account", accessor: "account", isSort: true, width: '20%', type: "string", isFilter: true },
        { label: "Phone", accessor: "phone", isSort: true, width: '20%', type: "string", isFilter: true },
        { label: "Message", accessor: "message", isSort: true, width: '20%', type: "string", isFilter: true },
        { label: "Days Left", accessor: "days_left", isSort: true, width: '10%', type: "string", isFilter: true },
        { label: "Action", accessor: "action", isSort: false }
    ]

    // 1013
    const getUserListAPI = async () => {
        setResponseData({
            loading: true,
            data: responseData.data
        });
        try {
            const res = await mainAxios.get('deactivations', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var deactivationList = res.response.data.deactivations;
                deactivationList.forEach(element => {
                    if (element.role === "c") {
                        let customer = element.customer
                        element.id = customer.customer_id
                        element.name = setupName(customer.first_name, customer.last_name)
                        element.user_role = "Customer"
                        var phoneNumber = "";
                        if (isValidValue(customer.country_code)) {
                            phoneNumber += customer.country_code + " ";
                        }
                        if (isValidValue(customer.phone_number)) {
                            phoneNumber += customer.phone_number;
                        }
                        element.account = (customer.login_id ?? "-") + "\n" +
                            (element.name ?? "-") + "\n" + (phoneNumber === "" ? "-" : phoneNumber);
                    } else if (element.role === "a") {
                        let agent = element.agent
                        element.id = agent.agent_id
                        element.name = setupName(agent.first_name, agent.last_name)
                        element.user_role = "Agent"
                        var agentPhoneNumber = "";
                        if (isValidValue(agent.country_code)) {
                            agentPhoneNumber += agent.country_code + " ";
                        }
                        if (isValidValue(agent.phone_number)) {
                            agentPhoneNumber += agent.phone_number;
                        }
                        element.account = (agent.login_id ?? "-") + "\n" +
                            (element.name ?? "-") + "\n" + (agentPhoneNumber === "" ? "-" : agentPhoneNumber);
                    } else if (element.role === "adm") {
                        let admin = element.admin
                        element.id = admin.admin_id
                        element.name = setupName(admin.first_name, admin.last_name)
                        element.user_role = "Admin"
                        var adminPhoneNumber = "";
                        if (isValidValue(admin.country_code)) {
                            adminPhoneNumber += admin.country_code + " ";
                        }
                        if (isValidValue(admin.phone_number)) {
                            adminPhoneNumber += admin.phone_number;
                        }
                        element.account = (admin.login_id ?? "-") + "\n" +
                            (element.name ?? "-") + "\n" + (adminPhoneNumber === "" ? "-" : adminPhoneNumber);
                    }
                });

                setResponseData({
                    loading: false,
                    data: deactivationList
                });
                filter(filterValue, deactivationList);
            } else {
                setResponseData({
                    loading: false,
                    data: []
                });
                filter(filterValue, []);
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: []
            });
            filter(filterValue, []);
        }
    }

    function onClickRow(id) {
    }

    function onClickDelete(id) {
        responseData.data.forEach(element => {
            if (element.id === id) {
                setDialog({
                    showDialog: true,
                    isDelete: true,
                    selectedRow: element,
                    message: ""
                })
            }
        })
    }

    async function confirmDelete(id) {
        responseData.data.forEach(element => {
            if (element.id === id) {
                rejectDeactivation(element.deactivation_id)
            }
        })
    }

    // 4013
    async function rejectDeactivation(code) {
        setDialog({ showDialog: false, isDelete: false, isSent: false })
        try {
            const res = await mainAxios.delete('deactivation/' + code, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: false,
                    data: [],
                });
                getUserListAPI();
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: responseData.data,
            });
        }
    }

    function onSidebarClick() {
        setResponseData({
            loading: true,
            data: {}
        });
        getUserListAPI();
    }

    function onClickColumn(column, arr) {
        var data = sortTable(column, columns, arr ?? filterList);
        column.data = data;
        setSorting(column);
    }

    function filter(filter, arr) {
        var list = filterTable(filter, arr ?? responseData.data);
        setFilterList(list);
        onClickColumn(sorting, list)
    }

    function handleSearchChange(value) {
        setFilterValue(value)
        filter(value);
    }

    function createDialog() {
        return dialog.showDialog ? dialog.isDelete ?
            (
                <ConfirmDialog
                    id={dialog.selectedRow.id}
                    title={'Delete Account Deletion Request'}
                    subtitle={`Are you sure you want to activate “${dialog.selectedRow.name}” account?`}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Callback={(id) => setDialog({ showDialog: false })}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => confirmDelete(id)}
                />
            ) :
            (
                <ConfirmDialog
                    iconTitle={''}
                    title={''}
                    subtitle={dialog.message}
                    subTitleStyle={{ fontSize: 16 }}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Style={{ visibility: "hidden" }}
                    button2Icon={''}
                    button2Title={'OK'}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => setDialog({ showDialog: false })}
                />
            )
            : null
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="deletion_request" onSidebarClick={onSidebarClick} navChild={{ accounts: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="users">
                        <div className="header">
                            <p>DELETION REQUEST</p>
                            <SearchBar
                                handleSearchChange={(value) => handleSearchChange(value)}
                                value={filterValue}
                                filterList={columns}
                            />
                        </div>
                        <div className="table-container">
                            <CustomTable
                                columns={columns}
                                tableData={sorting.data ?? filterList ?? []}
                                numPerPage={10}
                                isEdit={false}
                                isMore={false}
                                isDisable={false}
                                onClickRow={(id) => onClickRow(id)}
                                onClickDelete={(id) => onClickDelete(id)}
                                sorting={sorting}
                                onClickColumn={(column) => onClickColumn(column)}
                            />
                        </div>
                        {createDialog()}
                    </div>
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(UsAccountDeletion);