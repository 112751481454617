import React from "react";
import "./loadingSpinner.scss";

export default function LoadingSpinner() {
    return (
        <div className='loading-dialog'>
            <div className="spinner-container" >
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}