import React, { useEffect, useState } from 'react'
import './agentBanners.scss'
import { useNavigate } from 'react-router-dom';
import checkRole from '../../../checkRole';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { mainAxios } from '../../../axiosConfig';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { getAvatar, getName, isValidValue } from '../../../Utils';
import { ReactComponent as PlaceHolderIcon } from '../../../assets/img_company_placeholder.svg';

const AgentBanners = (params) => {
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });

    useEffect(() => {
        getSetup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 1002
    const getSetup = async () => {
        try {
            const res = await mainAxios.get('setup', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var array = [];
                if (res.response.data.agent_banners) {
                    array = JSON.parse(res.response.data.agent_banners);
                }
                setResponseData({
                    loading: false,
                    data: array
                });
            } else {
                setResponseData({
                    loading: false,
                    data: {}
                });
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: {}
            });
        }
    }

    function onSidebarClick() {
        setResponseData({
            loading: true,
            data: []
        });
        getSetup();
    }

    const DetailsLayout = (props) => {
        return (
            <div className='details-layout'>
                <div className='details-layout-item'>
                    <p>{props.title}</p>
                </div>
                <div className='details-layout-item'>
                    {props.isImage ? <div className='upload'>
                        {!isValidValue(props.content) ? <PlaceHolderIcon /> : <img className='upload-logo' src={props.content} alt="avatar" />}
                    </div> : <p>{!isValidValue(props.content) ? "-" : props.content}</p>
                    }
                </div>
            </div>
        )
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="agent_banners" onSidebarClick={onSidebarClick} navChild={{ setup: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="agent_banners">
                        <div className="header">
                            <div>
                                <p>Agent Banners</p>
                            </div>
                            <button onClick={() => navigate("/setup/agent_banners/agent-banners-edit")}>
                                Edit
                            </button>
                        </div>
                        <div className='content'>
                            <div className='details'>
                                {responseData.data.map((data, key) => {
                                    return (
                                        <div key={key} className='details-layout-container'>
                                            <div className='details-layout-content'>
                                                <p>•</p>
                                                <DetailsLayout title="Title:" content={data.title} />
                                            </div>
                                            <div className='details-layout-content'>
                                                <p>•</p>
                                                <DetailsLayout title="Content:" content={data.content} />
                                            </div>
                                            <div className='details-layout-content'>
                                                <p>•</p>
                                                <DetailsLayout title="Type:" content={data.type} />
                                            </div>
                                            <div className='details-layout-content'>
                                                <p>•</p>
                                                <DetailsLayout title="Image:" isImage={true} content={data.image_url} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(AgentBanners);