import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.REACT_APP_ENV !== "uat") {
  console.log = () => { }; //disable log in production mode
}
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <App />
);