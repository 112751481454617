import React, { useEffect, useState } from 'react'
import './editFAQs.scss'
import { ReactComponent as TickIcon } from '../../../assets/ic_tick.svg';
import { useNavigate } from 'react-router-dom';
import checkRole from '../../../checkRole';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { mainAxios } from '../../../axiosConfig';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { getAvatar, getName } from '../../../Utils';
import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FaqsItem } from './FAQsItem';

const EditFAQs = (params) => {
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        showDialog: false,
        message: ""
    });
    const [responseData, setResponseData] = useState({
        loading: false
    });

    const [faqs, setFaqs] = useState([{ id: 1, title: "", content: "" }]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const getFaqsPos = id => faqs.findIndex(faq => faq.id === id);

    const handleDragEnd = event => {
        const { active, over } = event

        if (active.id === over.id) return;

        setFaqs(faqs => {
            const originalPos = getFaqsPos(active.id);
            const newPos = getFaqsPos(over.id);

            return arrayMove(faqs, originalPos, newPos);
        })
    }

    useEffect(() => {
        if (params.action === "edit") {
            setResponseData({
                loading: true
            });
            getSetup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSave = (event) => {
        event.preventDefault();

        var newList = faqs
            .filter(item => item.title !== "" && item.content !== "")
            .map(({ id, ...rest }) => rest);

        updateSetup(JSON.stringify(newList));
    }

    // 1002
    const getSetup = async () => {
        try {
            const res = await mainAxios.get('setup', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: false
                });
                if (res.response.data.faqs) {
                    var faqsList = JSON.parse(res.response.data.faqs);
                    for (let i = 0; i < faqsList.length; i++) {
                        faqsList[i].id = i + 1;
                    }
                    if (faqsList.length !== 0) {
                        setFaqs(faqsList);
                    }
                }
            } else {
                setResponseData({
                    ...responseData,
                    loading: false
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false
            });
        }
    }

    // 3002
    const updateSetup = async (newFaqs) => {
        try {
            const res = await mainAxios.put('setup', {
                faqs: newFaqs,
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
                navigate("/setup/faqs");
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    function createDialog() {
        return dialog.showDialog ?
            (
                <ConfirmDialog
                    iconTitle={''}
                    title={''}
                    subtitle={dialog.message}
                    subTitleStyle={{ fontSize: 16 }}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Style={{ visibility: "hidden" }}
                    button2Icon={''}
                    button2Title={'OK'}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => setDialog({ showDialog: false })}
                />
            )
            : null
    }

    function setTitle(id, title) {
        var newList = faqs;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].title = title;
            }
        }
        setFaqs([...newList]);
    }

    function setContent(id, content) {
        var newList = faqs;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].content = content;
            }
        }
        setFaqs([...newList]);
    }

    function addFaqs() {
        var currentIndex = 0;
        for (let i = 0; i < faqs.length; i++) {
            if (faqs[i].id > currentIndex) {
                currentIndex = faqs[i].id;
            }
        }
        var newList = faqs.concat({ id: currentIndex + 1, title: "", content: "" });
        setFaqs([...newList]);
    }

    function removeFaqs(id) {
        if (faqs.length === 1) {
            faqs[0].title = "";
            faqs[0].content = "";
            setFaqs([...faqs]);
        } else {
            setFaqs((current) => current.filter((element) => {
                return element.id !== id;
            }));
        }
    }

    function onSidebarClick() { }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="faqs" onSidebarClick={onSidebarClick} navChild={{ setup: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="edit-faqs">
                        <form onSubmit={handleSave}>
                            <div className="header">
                                <div>
                                    <p>Edit faqs</p>
                                    <p>FAQs &gt; Edit faqs</p>
                                </div>
                                <button type='submit'>
                                    <span><TickIcon /></span>
                                    <span>Save</span>
                                </button>
                            </div>
                            <div className='content'>
                                <div className='details'>
                                    <div className='details-form'>
                                        <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                                            <SortableContext items={faqs} strategy={verticalListSortingStrategy}>
                                                {faqs.map((faq, index) => (
                                                    <FaqsItem key={index} index={index} id={faq.id} title={faq.title} content={faq.content} setTitle={setTitle} setContent={setContent} addFaqs={addFaqs} removeFaqs={removeFaqs} />
                                                ))}
                                            </SortableContext>
                                        </DndContext>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {createDialog()}
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(EditFAQs);