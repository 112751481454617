import { React, useEffect } from 'react';
import "./notFound.scss";

const NotFound = () => {
  useEffect(() => {
  }, []);

  return (
    <div>
      Opps, the page is not found!!! <br />
    </div>
  )
}

export default NotFound