import React, { useEffect, useState } from 'react'
import './logs.scss'
import { Link, useNavigate } from 'react-router-dom';
import checkRole from '../../checkRole';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { mainAxios } from '../../axiosConfig';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { getAvatar, getName } from '../../Utils';

const Logs = (params) => {
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });

    useEffect(() => {
        getSetup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 1002
    const getSetup = async () => {
        try {
            const res = await mainAxios.get('setup', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var array = [];
                if (res.response.data.faqs) {
                    array = JSON.parse(res.response.data.faqs);
                }
                setResponseData({
                    loading: false,
                    data: array
                });
            } else {
                setResponseData({
                    loading: false,
                    data: {}
                });
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: {}
            });
        }
    }

    function onSidebarClick() {
        setResponseData({
            loading: true,
            data: []
        });
        getSetup();
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="logs" onSidebarClick={onSidebarClick} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="logs">
                        <div className="header">
                            <div>
                                <p>LOGS</p>
                            </div>
                        </div>
                        <div className='content'>
                            <div className='details'>
                                <div className='hints'>
                                    <label className='required'><b>cronjob(H)</b> should run every hour in Common</label>
                                    <label className='required'>contact us function should log <b>sendMail()</b> in Common</label>
                                    <label className='required'>take care when <b>sendSms()</b> comes with error code</label>
                                </div>
                                <div className='logs-link-container'>
                                    <Link className='logs-link' to={`${process.env.REACT_APP_BASE_LOGS_URL}/common/v2/logs.txt`} rel="noreferrer" target='_blank'>Common</Link>
                                    <Link className='logs-link' to={`${process.env.REACT_APP_BASE_LOGS_URL}/mobile-agent/v2/logs.txt`} rel="noreferrer" target='_blank'>Mobile - Agent</Link>
                                    <Link className='logs-link' to={`${process.env.REACT_APP_BASE_LOGS_URL}/mobile-customer/v2/logs.txt`} rel="noreferrer" target='_blank'>Mobile - Customer</Link>
                                    <Link className='logs-link' to={`${process.env.REACT_APP_BASE_LOGS_URL}/web/v2/logs.txt`} rel="noreferrer" target='_blank'>Web</Link>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(Logs);