import React, { useEffect, useState } from 'react'
import './promotions.scss'
import { useNavigate } from 'react-router-dom';
import checkRole from '../../../checkRole';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { mainAxios } from '../../../axiosConfig';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { dateToString, getAvatar, getGMTString, getName, isValidValue } from '../../../Utils';

const Promotions = (params) => {
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState({
    loading: true,
    data: []
  });

  useEffect(() => {
    getSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 1002
  const getSetup = async () => {
    try {
      const res = await mainAxios.get('setup', {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        var array = [];
        if (res.response.data.booking_promotions) {
          array = JSON.parse(res.response.data.booking_promotions);
        }
        getServicesListAPI(array);
      } else {
        setResponseData({
          loading: false,
          data: []
        });
      }
    } catch (error) {
      setResponseData({
        loading: false,
        data: []
      });
    }
  }

  // 1020
  const getServicesListAPI = async (array) => {
    try {
      const res = await mainAxios.get('services', {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        var servicesList = res.response.data.services;
        if (servicesList) {
          for (let i = 0; i < array.length; i++) {
            if (array[i].service_id !== undefined) {
              var serviceObject = servicesList.find(item => item.service_id === array[i].service_id);
              if (serviceObject !== undefined) {
                array[i].service_title = serviceObject.title;
              }
            }
          }
          setResponseData({
            loading: false,
            data: array
          });
        } else {
          setResponseData({
            loading: false,
            data: []
          });
        }
      } else {
        setResponseData({
          loading: false,
          data: []
        });
      }
    } catch (error) {
      setResponseData({
        loading: false,
        data: []
      });
    }
  }

  function onSidebarClick() {
    setResponseData({
      loading: true,
      data: []
    });
    getSetup();
  }

  return (
    <div className='app-main'>
      <div className={`${params.navClose ? 'navClose' : ""}`}>
        <Sidebar page="booking_promotions" onSidebarClick={onSidebarClick} navChild={{ setup: true }} />
      </div>
      <div className='app-main-content'>
        <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
        <div className='app-content'>
          <div className="promotions">
            <div className="header">
              <div>
                <p>BOOKING PROMOTIONS</p>
              </div>
              <button onClick={() => navigate("/setup/booking-promotions/promotions-edit")}>
                Edit
              </button>
            </div>
            <div className='content'>
              <div className='details'>
                <label className='required'>A booking can only match one promotion, and conditions will be checked in the following sequence.</label>
                {responseData.data.map((data, key) => {
                  return (
                    <div key={key} className='details-layout'>
                      {isValidValue(data.min_sessions) && isValidValue(data.discount_percentage) ?
                        <div className='details-layout-content'>
                          <p>&#x2713;</p>
                          <p>Book {data.min_sessions}+ sessions for a {data.discount_percentage}% discount</p>
                        </div>
                        : isValidValue(data.min_sessions) && isValidValue(data.discount_amount) ?
                          <div className='details-layout-content'>
                            <p>&#x2713;</p>
                            <p>Book {data.min_sessions}+ sessions for a ${data.discount_amount} discount</p>
                          </div>
                          : isValidValue(data.service_title) && isValidValue(data.discount_percentage) ?
                            <div className='details-layout-content'>
                              <p>&#x2713;</p>
                              <p>Book {data.service_title} for a {data.discount_percentage}% discount</p>
                            </div>
                            : isValidValue(data.service_title) && isValidValue(data.discount_amount) ?
                              <div className='details-layout-content'>
                                <p>&#x2713;</p>
                                <p>Book {data.service_title} for a ${data.discount_amount} discount</p>
                              </div>
                              : undefined}
                      {isValidValue(data.start_at) && isValidValue(data.end_at) ?
                        <div className='details-layout-content'>
                          <p>&#x2713;</p>
                          <p>Promotion period: from {dateToString(data.start_at, "D MMM YY, h:mma")} until {dateToString(data.end_at, "D MMM YY, h:mma")} ({getGMTString()})</p>
                        </div>
                        :
                        isValidValue(data.start_at) ?
                          <div className='details-layout-content'>
                            <p>&#x2713;</p>
                            <p>Promotion period: from {dateToString(data.start_at, "D MMM YY, h:mma")} ({getGMTString()})</p>
                          </div>
                          :
                          isValidValue(data.end_at) ?
                            <div className='details-layout-content'>
                              <p>&#x2713;</p>
                              <p>Promotion period: until {dateToString(data.end_at, "D MMM YY, h:mma")} ({getGMTString()})</p>
                            </div>
                            : undefined}
                    </div>
                  )
                })}
              </div>
            </div>
          </div >
        </div>
        {responseData.loading ? <LoadingSpinner /> : undefined}
      </div>
    </div>
  )
}

export default checkRole(Promotions);