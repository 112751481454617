import React, { useState } from 'react'
import './customTable.scss'
import CustomTableBody from './CustomTableBody'
import CustomTableHead from './CustomTableHead'

var currentPageSection = 1;

const CustomTable = ({ columns, tableData, numPerPage, path, isEdit = true, isDelete = true, isMore = false, isDisable = false, isSent = false, isDeleteDisabled = false, isDisableOnClickRow = false, onClickRow, onClickEdit, onClickDelete, onClickViewMore, onClickStatus, onClickSent, onCustomButton, sorting = null, onClickColumn, onChangeDrowDownValue }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const length = tableData.length;
    const totalPage = Math.ceil(length / numPerPage) === 0 ? 1 : Math.ceil(length / numPerPage);
    const maxPerPageOption = 5;
    const lastPage = Math.ceil(totalPage / maxPerPageOption);
    const pageData = tableData.slice(((currentPage * numPerPage) + 1 - numPerPage) - 1, length >= numPerPage ? currentPage * numPerPage > length ? length : currentPage * numPerPage : length);

    const handlePageClick = (page) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const toFirstPageSection = () => {
        if (currentPageSection !== 1) {
            currentPageSection = 1;
            handlePageClick(1);
        }
    }

    const toPreviousPageSection = () => {
        currentPageSection = currentPageSection - 1;
        if (currentPageSection === 1) {
            handlePageClick(1);
        } else {
            handlePageClick(((currentPageSection - 1) * 5) + 1);
        }
    }

    const toLastPageSection = () => {
        if (currentPageSection !== lastPage) {
            currentPageSection = lastPage;
            handlePageClick(((currentPageSection - 1) * 5) + 1);
        }
    }

    const toNextPageSection = () => {
        currentPageSection = currentPageSection + 1;
        handlePageClick(((currentPageSection - 1) * 5) + 1);
    }

    return (
        <div>
            <table className="custom-table">
                <CustomTableHead
                    columns={columns}
                    sorting={sorting}
                    onClickColumn={(id) => onClickColumn(id)}
                />
                <CustomTableBody
                    columns={columns}
                    tableData={pageData}
                    path={path}
                    isEdit={isEdit}
                    isDelete={isDelete}
                    isMore={isMore}
                    isDisable={isDisable}
                    isSent={isSent}
                    isDisableOnClickRow={isDisableOnClickRow}
                    isDeleteDisabled={isDeleteDisabled}
                    onClickRow={(id) => onClickRow(id)}
                    onClickEdit={(id) => onClickEdit(id)}
                    onClickDelete={(id) => onClickDelete(id)}
                    onClickViewMore={(id) => onClickViewMore(id)}
                    onClickStatus={(id) => onClickStatus(id)}
                    onClickSent={(id) => onClickSent(id)}
                    onCustomButton={(id, isRedButton) => onCustomButton(id, isRedButton)}
                    onChangeDrowDownValue={(opt, id, accessor) => onChangeDrowDownValue(opt, id, accessor)}
                />
            </table>
            <div className='custom-table-footer'>
                <p>Showing {length > 0 ? (currentPage * numPerPage) + 1 - numPerPage : 0} to {length >= numPerPage ? currentPage * numPerPage > length ? length : currentPage * numPerPage : length} of {length} entries</p>
                <div className='custom-table-page'>
                    {(currentPageSection !== 1) ? <button onClick={() => toFirstPageSection()}>&lt;&lt;</button> : ""}
                    {(currentPageSection !== 1) ? <button onClick={() => toPreviousPageSection()}>&lt;</button> : ""}
                    {Array.from(Array(maxPerPageOption), (e, i) => {
                        let pageIndex = ((currentPageSection - 1) * maxPerPageOption) + i + 1;
                        if (pageIndex <= totalPage) {
                            return <button key={pageIndex} className={`${pageIndex === currentPage ? 'selected' : ''}`} onClick={() => handlePageClick(pageIndex)}>{pageIndex}</button>
                        } else {
                            return ""
                        }
                    })}
                    {(currentPageSection !== lastPage) ? <button onClick={() => toNextPageSection()}>&gt;</button> : ""}
                    {(currentPageSection !== lastPage) ? <button onClick={() => toLastPageSection()}>&gt;&gt;</button> : ""}
                </div>
            </div>
        </div>
    )
}

export default CustomTable