import { React, useEffect, useState } from 'react';
import './notificationHistory.scss';
import CustomTable from '../../../components/CustomTable/CustomTable';
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import checkRole from '../../../checkRole';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { getAvatar, getName, sortTable, filterTable, getRoleTypeName, getFullNotificationTypeName, removeHTMLTags, dateToString, isValidValue } from '../../../Utils';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import { ReactComponent as SendIcon } from '../../../assets/ic_sent.svg';
import { mainAxios } from '../../../axiosConfig';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../../../components/SearchBar/SearchBar';

const NotificationHistory = (params) => {
    const navigate = useNavigate();

    useEffect(() => {
        getNotificationsListAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [dialog, setDialog] = useState({
        showDialog: false,
        isDelete: false,
        isSent: false,
        selectedRow: {},
        message: ""
    });
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });
    const [sorting, setSorting] = useState({
        column: "",
        assending: true
    });
    const [filterValue, setFilterValue] = useState({
        searchValue: "",
        filterValue: []
    });
    const [filterList, setFilterList] = useState([]);

    const columns = [
        { label: "Type", accessor: "type_name", isSort: true, width: '17%', type: "string", isFilter: true },
        { label: "Role", accessor: "role_name", isSort: true, width: '17%', type: "string", isFilter: true },
        { label: "Title", accessor: "title", isSort: true, width: '20%', type: "string", isFilter: true },
        { label: "Send Time", accessor: "sent_date", isSort: true, width: '16%', type: "date", isFilter: true },
        { label: "Push Notifications", accessor: "push_notification", isSort: true, width: '10%', type: "string", styleColumn: { textAlign: 'center' }, isFilter: true },
        { label: "Action", accessor: "action", isSort: false, width: '10%' }
    ]

    // 1050
    const getNotificationsListAPI = async () => {
        try {
            const res = await mainAxios.get('notifications', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var arr = []
                var notifications = res.response.data.notifications;
                notifications.forEach(obj => {
                    let item = JSON.parse(obj.data);
                    obj.type_name = getFullNotificationTypeName(obj.type);
                    obj.role_name = getRoleTypeName(obj.role);
                    if (obj.type === "e") {
                        obj.title = item.title;
                        obj.title_string = item.title;
                        obj.content_title = item.content_title;
                        obj.content_description_html = item.content_description_html;
                    } else {
                        obj.title = removeHTMLTags(item.title_html);
                        obj.title_html = item.title_html;
                        obj.message = removeHTMLTags(item.message_html);
                        obj.message_html = item.message_html;
                        obj.image_url = item.image_url;
                        obj.icon_name = item.icon_name;
                    }
                    obj.push_notification = obj.to_push ? "Yes" : "No";
                    obj.sent_date = obj.batches[0].send_at;
                    obj.id = obj.notification_id;
                    obj.isDisable = obj.remove_at;
                    if (isValidValue(obj.sent_date)) {
                        obj.sent_date = dateToString(obj.sent_date, "D MMM YY, h:mma");
                    }
                    arr.push(obj);
                })
                setResponseData({
                    loading: false,
                    data: arr
                });

                filter(filterValue, arr);
            } else {
                setResponseData({
                    loading: false,
                    data: []
                });

                filter(filterValue, []);
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: []
            });

            filter(filterValue, []);
        }
    }

    function onClickRow(id) {
        responseData.data.forEach(obj => {
            if (obj.id === id) {
                navigate("/notifications/history/" + id, { state: { id: id, data: obj } });
            }
        })
    }

    function onClickDelete(id) {
        responseData.data.forEach(obj => {
            if (obj.id === id) {
                if (obj.isDisable) {
                    navigate("/notifications/history/" + id, { state: { id: id, data: obj } });
                } else {
                    setDialog({
                        showDialog: true,
                        isDelete: true,
                        isSent: false,
                        selectedRow: obj
                    })
                }
            }
        })
    }

    // 4051
    async function confirmDelete(id) {
        setDialog({ showDialog: false, isDelete: false, isSent: false })
        setResponseData({
            loading: true,
            data: responseData.data,
        });

        try {
            const res = await mainAxios.delete('notification/' + id, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                getNotificationsListAPI();
            }
        } catch (error) {
            getNotificationsListAPI();
        }
    }

    // 2054
    async function confirmSent(id) {
        setDialog({ showDialog: false, isDelete: false, isSent: false })
        setResponseData({
            loading: true,
            data: responseData.data,
        });

        try {
            await mainAxios.delete('send_notification/' + id, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            setResponseData({
                loading: false,
                data: responseData.data,
            });
        } catch (error) {
            setResponseData({
                loading: false,
                data: responseData.data,
            });
        }
    }

    function createDialog() {
        return dialog.showDialog ? (
            dialog.isDelete ?
                (
                    <ConfirmDialog
                        id={(dialog.selectedRow ?? {}).id ?? 0}
                        title={'Delete Notification'}
                        subtitle={'Are you sure you want to delete this notification?'}
                        backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                        button1Callback={(id) => setDialog({ showDialog: false })}
                        button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                        button2Callback={(id) => confirmDelete(id)}
                    />
                )
                :
                dialog.isSent ?
                    (
                        <ConfirmDialog
                            id={(dialog.selectedRow ?? {}).id ?? 0}
                            title={'Sent Notification'}
                            iconTitle={<SendIcon />}
                            subtitle={'Are you sure you want to sent this notification?'}
                            backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                            button1Callback={(id) => setDialog({ showDialog: false })}
                            button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                            button2Callback={(id) => confirmSent(id)}
                            button2Icon={<SendIcon />}
                            button2Title={"Send"}
                        />
                    )
                    :
                    (
                        <ConfirmDialog
                            iconTitle={''}
                            title={''}
                            subtitle={dialog.message}
                            subTitleStyle={{ fontSize: 16 }}
                            backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                            button1Style={{ visibility: "hidden" }}
                            button2Icon={''}
                            button2Title={'OK'}
                            button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                            button2Callback={(id) => setDialog({ showDialog: false })}
                        />
                    )
        )
            : null
    }

    function onSidebarClick() {
        setResponseData({
            loading: true,
            data: []
        });
        getNotificationsListAPI();
    }

    function onClickColumn(column, arr) {
        var data = sortTable(column, columns, arr ?? filterList);
        column.data = data;
        setSorting(column);
    }

    function filter(filter, arr) {
        var list = filterTable(filter, arr ?? responseData.data);
        setFilterList(list);
        onClickColumn(sorting, list)
    }

    function handleSearchChange(value) {
        setFilterValue(value)
        filter(value);
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="notification_history" onSidebarClick={onSidebarClick} navChild={{ manage_notifications: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className="notification_history">
                    <div className="header">
                        <p>NOTIFICATION HISTORY</p>
                        <SearchBar
                            handleSearchChange={(value) => handleSearchChange(value)}
                            value={filterValue}
                            filterList={columns}
                        />
                    </div>
                    <div className="table-container">
                        <CustomTable
                            columns={columns}
                            tableData={sorting.data ?? filterList}
                            numPerPage={10}
                            isEdit={false}
                            isDelete={false}
                            isMore={false}
                            isDisable={false}
                            isDeleteDisabled={true}
                            onClickRow={(id) => onClickRow(id)}
                            onClickDelete={(id) => onClickDelete(id)}
                            onClickStatus={(id) => onClickRow(id)}
                            sorting={sorting}
                            onClickColumn={(column) => onClickColumn(column)}
                        />
                    </div>
                    {createDialog()}
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(NotificationHistory);