import axios from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import ConfirmDialog from './components/ConfirmDialog/ConfirmDialog';
import { errorsMapping, getTimeZoneOffset } from './Utils';

const mainAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});
const googleMapAxios = axios.create({
    baseURL: "https://maps.googleapis.com/maps/api/geocode/json?language=en&region=sg&key=" + process.env.REACT_APP_GOOGLE_API_KEY
});
const setAuthToken = () => {
    let object = JSON.parse(window.localStorage.getItem("auth"));
    const token = object?.accessToken;
    if (token) {
        mainAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        delete mainAxios.defaults.headers.common.Authorization;
    }
    mainAxios.defaults.headers.common['Timezone-Offset'] = getTimeZoneOffset();
};

export function onLogout() {
    const authObject = {
        accessToken: "",
        isLogin: "0"
    }
    window.localStorage.setItem("auth", JSON.stringify(authObject));
    window.localStorage.removeItem("admin");
    setAuthToken();
    window.location.href = ''

    return;
}

setAuthToken();

mainAxios.interceptors.request.use(config => {
    config.timeout = 30000;
    const requestData = {
        baseURL: config.baseURL,
        data: config.data,
        headers: config.headers,
        method: config.method
    }
    console.log("Request:", requestData);
    return config;
});
googleMapAxios.interceptors.request.use(config => {
    config.timeout = 10000;
    const requestData = {
        baseURL: config.baseURL,
        data: config.data,
        headers: config.headers,
        method: config.method
    }
    console.log("Request:", requestData);
    return config;
});

mainAxios.interceptors.response.use(
    response => {
        const responseData = {
            baseURL: response.request.responseURL,
            data: response.data,
            headers: response.headers,
            status: response.status,
            statusText: response.statusText
        }
        console.log("Response:", responseData);

        if (response.data.result) {
            return { response: response, isSuccess: true };
        } else {
            if (response.data.error === "InvalidToken") {
                onLogout()
            } else {
                if (!response.request.responseURL.includes("/booking_invoice_url/")) {
                    errorAlertPopup(response.data.error);
                }
            }
            // return null
            return { response: response, isSuccess: false };
        }
    },
    error => {
        if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            console.log(error.request);
            // errorAlertPopup(error._response)
        } else {
            console.log('Error:', error.message);
            // errorAlertPopup(error.message)
        }
        return Promise.reject(error);
    }
)
googleMapAxios.interceptors.response.use(
    response => {
        const responseData = {
            baseURL: response.request.responseURL,
            data: response.data,
            headers: response.headers,
            status: response.status,
            statusText: response.statusText
        }
        console.log("Response:", responseData);

        if (response.data.results) {
            return { response: response, isSuccess: true };
        } else {
            return { response: response, isSuccess: false };
        }
    },
    error => {
        if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error:', error.message);
        }
        return Promise.reject(error);
    }
)

export default setAuthToken;
export { mainAxios, googleMapAxios };

export function errorAlertPopup(error) {
    let root = createRoot(document.getElementById('errorMessageAlert'));
    root.render(<ErrorMessageAlertView root={root} message={errorsMapping(error)} />)
}
const ErrorMessageAlertView = ({ message, root }) => {
    function remove() {
        root.render(null);
    }
    return (
        <ConfirmDialog
            iconTitle={''}
            title={'Error'}
            subtitle={message}
            subTitleStyle={{ fontSize: 16 }}
            backgroundButtonCallback={(id) => remove()}
            button1Style={{ visibility: "hidden" }}
            button2Icon={''}
            button2Title={'OK'}
            button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
            button2Callback={(id) => remove()}
        />
    )
}