import React from "react";
import "./searchBar.scss";
import { ReactComponent as SearchIcon } from '../../assets/ic_search.svg';
import { CustomSelect } from '../../components/SelectOption/CustomSelect';

const SearchBar = ({ handleSearchChange, filterList, value }) => {
    var filterValue = value ?? {};
    var options = [];
    filterList.forEach(obj => {
        if (obj.isFilter) {
            var item = JSON.parse(JSON.stringify(obj));
            item.value = item.accessor;
            options.push(item);
        }
    })

    function search(event) {
        searchChange(event.target.value, filterValue.filterValue);
    }
    function filter(val) {
        searchChange(filterValue.searchValue, val);
    }

    function searchChange(searchValue, filterValue) {
        handleSearchChange({
            searchValue: searchValue,
            filterValue: filterValue
        })
    }

    return (
        <div className="search-bar">
            <div className="search-bar-container">
                <SearchIcon />
                <input className="search-bar-input" value={filterValue.searchValue} type="search" placeholder="Search..." onChange={(event) => search(event)} />
                <div className="search-bar-filter">
                    <div className='details-div dropdown-div'>
                        <div className='details-div-layout details-div-multi-layout'>
                            <CustomSelect list={options} placeholder='Filter' required={false} isMulti={false} value={filterValue.filterValue} onChangeValue={(e) => filter(e)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchBar;