import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import "./login.scss";
import logo from '../../assets/img_logo.png';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import setAuthToken, { errorAlertPopup, mainAxios } from '../../axiosConfig';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { validateEmail } from '../../Utils';

const Login = () => {

  const navigate = useNavigate();
  const [dialog, setDialog] = useState({
    showDialog: false,
    message: "Invalid username or password."
  });
  const [responseData, setResponseData] = useState({
    loading: false,
    data: {}
  });
  const redirectLink = '/sessions';

  useEffect(() => {
    let object = JSON.parse(window.localStorage.getItem("auth"));
    if (object?.isLogin === "1") {
      getAdminAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    var { uname } = document.forms[0];

    if (!validateEmail(uname.value)) {
      setDialog({ showDialog: true, message: "Invalid username format." });
    } else {
      setResponseData({
        loading: true,
        data: {}
      });
      postLogin();
    }
  }

  // 2001
  const postLogin = async () => {
    var { uname, pass } = document.forms[0];
    var device_token = "";
    // let object = JSON.parse(window.localStorage.getItem("device_token"));
    // if (object?.token) {
    //   device_token = object?.token;
    // }
    try {
      const res = await mainAxios.post('login', {
        // platform: 'w',
        login_id: uname.value,
        login_password: pass.value,
        // device_token: device_token
      }, {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      setResponseData({
        loading: false,
        data: {}
      });
      if (res.isSuccess) {
        const authObject = {
          accessToken: res.response.data.access_token,
          isLogin: "1"
        }
        window.localStorage.setItem("auth", JSON.stringify(authObject));
        setAuthToken();
        getAdminAPI();
      } else {
        if (res.response.data.error !== null) {
        } else {
          errorAlertPopup("Invalid username or password.")
        }
      }
    } catch (error) {
      setResponseData({
        loading: false,
        data: {}
      });
    }
  }

  // 1011
  const getAdminAPI = async () => {
    try {
      const res = await mainAxios.get('admin', {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        const adminObject = {
          name: res.response.data.admin.username,
          avatar: res.response.data.admin.avatar_url,
          admin_id: res.response.data.admin.admin_id
        }
        window.localStorage.setItem("admin", JSON.stringify(adminObject));
        navigate(redirectLink, { replace: true });
      }
    } catch (error) {
    }
  }

  function createDialog() {
    return dialog.showDialog ?
      (
        <ConfirmDialog
          iconTitle={''}
          title={''}
          subtitle={dialog.message}
          subTitleStyle={{ fontSize: 16 }}
          backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
          button1Style={{ visibility: "hidden" }}
          button2Icon={''}
          button2Title={'OK'}
          button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
          button2Callback={(id) => setDialog({ showDialog: false })}
        />
      )
      : null
  }

  return (
    <div className='login-container'>
      <img className='login-logo' src={logo} alt="Logo" />
      <div className="form">
        <form id='login-form' onSubmit={handleSubmit} autoComplete='off'>
          <div className="input-container">
            <label>Username </label>
            <input className='details-div-layout-input' type="text" name="uname" required />
          </div>
          <div className="input-container">
            <label>Password </label>
            <input className='details-div-layout-input' type="password" name="pass" required />
          </div>
          <div className="button-container">
            <button>Log In</button>
          </div>
        </form>
      </div>
      {createDialog()}
      {responseData.loading ? <LoadingSpinner /> : undefined}
    </div>
  )
}

export default Login