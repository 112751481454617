import "./services.scss";
import { React, useEffect, useState } from 'react';
import { ReactComponent as AddIcon } from '../../assets/ic_add.svg';
import CustomTable from "../../components/CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { getAvatar, getName, renameKey, sortTable, filterTable } from '../../Utils.jsx'
import checkRole from '../../checkRole';
import { mainAxios } from "../../axiosConfig";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import SearchBar from '../../components/SearchBar/SearchBar';

const Services = (params) => {
  const navigate = useNavigate();
  const [dialog, setDialog] = useState({
    showDialog: false,
    isDelete: false,
    selectedRow: {},
    message: ""
  });
  const [responseData, setResponseData] = useState({
    loading: true,
    data: []
  });

  const [sorting, setSorting] = useState({
    column: "",
    assending: true
  });
  const [filterValue, setFilterValue] = useState({
    searchValue: "",
    filterValue: []
  });
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    getServicesListAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    { label: "Title", accessor: "title", isSort: true, width: '80%', type: "string", isFilter: true },
    { label: "Action", accessor: "action", isSort: false }
  ]

  // 1020
  const getServicesListAPI = async () => {
    try {
      const res = await mainAxios.get('services', {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        var servicesList = res.response.data.services;
        servicesList.forEach(obj => renameKey(obj, "service_id", "id"))
        setResponseData({
          loading: false,
          data: servicesList
        });

        filter(filterValue, servicesList);
      } else {
        setResponseData({
          loading: false,
          data: {}
        });
        filter(filterValue, []);
      }
    } catch (error) {
      setResponseData({
        loading: false,
        data: {}
      });
      filter(filterValue, []);
    }
  }

  function onClickRow(id) {
    services(id, 'view');
  }

  function onClickEdit(id) {
    services(id, 'edit');
  }

  function services(id, action) {
    for (var data of responseData.data) {
      if (data.id === id) {
        if (action === "edit") {
          navigate("/service/" + id + "/service-edit", { state: { id: data.id, from: "List" } });
        } else {
          navigate("/service/details?id=" + id);
        }
        break;
      }
    }
  }

  function onClickDelete(id) {
    for (var data of responseData.data) {
      if (data.id === id) {
        setDialog({
          showDialog: true,
          isDelete: true,
          selectedRow: data
        })
      }
    }
  }

  // 4021
  const deleteService = async (serviceID) => {
    try {
      const res = await mainAxios.delete('service/' + serviceID, {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        getServicesListAPI()
      } else {
        setResponseData({
          ...responseData,
          loading: false,
        });
      }
    } catch (error) {
      setResponseData({
        ...responseData,
        loading: false,
      });
    }
  }

  function confirmDelete(id) {
    setDialog({ showDialog: false });
    deleteService(id);
  }

  function createDialog() {
    return dialog.showDialog ? dialog.isDelete ?
      (
        <ConfirmDialog
          id={dialog.selectedRow.id}
          title={'Delete Service'}
          subtitle={'Are you sure you want to delete ‘' + dialog.selectedRow.title + '‘ ?'}
          backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
          button1Callback={(id) => setDialog({ showDialog: false })}
          button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
          button2Callback={(id) => confirmDelete(id)}
        />
      ) :
      (
        <ConfirmDialog
          iconTitle={''}
          title={''}
          subtitle={dialog.message}
          subTitleStyle={{ fontSize: 16 }}
          backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
          button1Style={{ visibility: "hidden" }}
          button2Icon={''}
          button2Title={'OK'}
          button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
          button2Callback={(id) => setDialog({ showDialog: false })}
        />
      )
      : null
  }

  function onSidebarClick() {
    setResponseData({
      loading: true,
      data: {}
    });
    getServicesListAPI();
  }

  function onClickColumn(column, arr) {
    var data = sortTable(column, columns, arr ?? filterList);
    column.data = data;
    setSorting(column);
  }

  function filter(filter, arr) {
    var list = filterTable(filter, arr ?? responseData.data);
    setFilterList(list);
    onClickColumn(sorting, list)
  }

  function handleSearchChange(value) {
    setFilterValue(value)
    filter(value);
  }

  return (
    <div className='app-main'>
      <div className={`${params.navClose ? 'navClose' : ""}`}>
        <Sidebar page="services" onSidebarClick={onSidebarClick} />
      </div>
      <div className='app-main-content'>
        <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
        <div className='app-content'>
          <div className="services">
            <div className="header">
              <p>SERVICES</p>
              <SearchBar
                handleSearchChange={(value) => handleSearchChange(value)}
                value={filterValue}
                filterList={columns}
              />
              <button onClick={() => navigate("/service/service-add")}>
                <span><AddIcon /></span>
                <span>Add New</span>
              </button>
            </div>
            <div className="table-container">
              <CustomTable
                columns={columns}
                tableData={sorting.data ?? filterList}
                numPerPage={10}
                onClickRow={(id) => onClickRow(id)}
                onClickEdit={(id) => onClickEdit(id)}
                onClickDelete={(id) => onClickDelete(id)}
                onClickViewMore={(id) => onClickRow(id)}
                sorting={sorting}
                onClickColumn={(column) => onClickColumn(column)}
              />
            </div>
            {createDialog()}
          </div>
        </div>
        {responseData.loading ? <LoadingSpinner /> : undefined}
      </div>
    </div>
  )
}

export default checkRole(Services);