import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import './faqsItem.scss'
import { ReactComponent as MenuIcon } from '../../../assets/ic_menu2.svg'
import { ReactComponent as AddIcon } from '../../../assets/ic_add.svg';
import { ReactComponent as CloseIcon } from '../../../assets/ic_close_outline.svg'

export const FaqsItem = ({ index, id, title, content, setTitle, setContent, addFaqs, removeFaqs }) => {
    const { attributes, listeners, setNodeRef, transform } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform)
    };

    return (
        <div className="faqs-item" style={style}>
            <div className="faqs-input-container">
                <MenuIcon className="faqs-menu" ref={setNodeRef}{...attributes}{...listeners} />
                <input className='faqs-input' type='text' onChange={(e) => setTitle(id, e.target.value)} value={title} placeholder="Question" />
                <CloseIcon className='faqs-remove-icon' onClick={() => removeFaqs(id)} />
                <AddIcon className={`faqs-add-icon ${index === 0 ? '' : 'icon-hidden'}`} onClick={() => addFaqs()} />
            </div>
            <div className="faqs-input-container">
                <MenuIcon className="faqs-menu icon-hidden" />
                <textarea className='faqs-textarea' type='text' rows={4} onChange={(e) => setContent(id, e.target.value)} value={content} placeholder="Answer" />
                <CloseIcon className='faqs-remove-icon icon-hidden' />
                <AddIcon className='faqs-add-icon icon-hidden' />
            </div>
        </div>
    )
}