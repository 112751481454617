import React, { act, useState } from 'react';
import './customInputDialog.scss';
import { ReactComponent as CloseIcon } from '../../assets/ic_close_outline.svg'
import { blockInvalidChar } from '../../Utils';

const CustomInputDialog = ({ type, title, action, button1Callback, button2Style, button2Callback, backgroundButtonCallback }) => {

    const [points, setPoints] = useState('');
    const [inputText, setInputText] = useState('');

    function handlePoints() {
        if (action === "reduce") {
            button2Callback("-" + points);
        } else {
            button2Callback(points);
        }
    }

    return (
        <div className='custom-input-dialog'>
            <button className='backgroundButton' onClick={() => backgroundButtonCallback === undefined ? console.log('backgroundButtonCallback') : backgroundButtonCallback()} />
            <div className='container'>
                <div className='container2'>
                    <div>
                        <div className='details-div'>
                            <label className='required'>{title}:</label>
                            <div className='details-div-layout'>
                                {type === "points" ?
                                    <input className='details-div-layout-input' type='number' onKeyDown={blockInvalidChar} onChange={(e) => setPoints(e.target.value)} value={points} min={0} /> :
                                    <input className='details-div-layout-input' type='text' onChange={(e) => setInputText(e.target.value)} value={inputText} />}
                            </div>
                        </div>
                        <div className='buttonContainer'>
                            <button onClick={() => button1Callback === undefined ? console.log('button1Callback') : button1Callback()}>
                                <CloseIcon />
                                Cancel
                            </button>
                            <button style={button2Style} onClick={() => button2Callback === undefined ? console.log('button2Callback') : type === "points" ? handlePoints() : button2Callback(inputText)}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export function ShowCustomInputDialog() {
    return (
        <CustomInputDialog />
    )
}

export default CustomInputDialog;