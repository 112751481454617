import React, { useEffect, useState } from 'react'
import './serviceDetails.scss'
import { useNavigate, useSearchParams } from 'react-router-dom';
import checkRole from '../../checkRole';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { ReactComponent as PlaceHolderIcon } from '../../assets/img_company_placeholder.svg';
import { mainAxios } from '../../axiosConfig';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { getAvatar, getName, isValidValue } from '../../Utils';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const ServiceDetails = (params) => {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const queryID = queryParameters.get("id");
  const [responseData, setResponseData] = useState({
    loading: true,
    data: {}
  });

  useEffect(() => {
    getServiceDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const links = document.querySelectorAll('#html-link a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });

  }, [responseData]);


  // 1021
  const getServiceDetails = async () => {
    try {
      const res = await mainAxios.get('service/' + queryID, {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        setResponseData({
          loading: false,
          data: res.response.data.service
        });
      } else {
        setResponseData({
          loading: false,
          data: {}
        });
      }
    } catch (error) {
      setResponseData({
        loading: false,
        data: {}
      });
    }
  }

  const DetailsLayout = (props) => {
    return (
      <div id={props.isHTML ? 'html-link' : ''} className='details-layout'>
        <p>{props.title}</p>
        {!props.isHTML ? <p>{props.content}</p> : <div className='details-html'>{props.content}</div>}
      </div>
    )
  }

  function onSidebarClick() { }

  return (
    <div className='app-main'>
      <div className={`${params.navClose ? 'navClose' : ""}`}>
        <Sidebar page="services" onSidebarClick={onSidebarClick} />
      </div>
      <div className='app-main-content'>
        <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
        <div className='app-content'>
          <div className="services-details">
            <div className="header">
              <div>
                <p>Service details</p>
                <p>Services &gt; Service details </p>
              </div>
              <button onClick={() => navigate("/service/" + queryID + "/service-edit", { state: { id: queryID } })}>
                Edit
              </button>
            </div>
            <div className='content'>
              <div className='details'>
                <DetailsLayout title="Title:" content={!isValidValue(responseData.data.title) ? "-" : responseData.data.title} />
                <DetailsLayout title="Subtitle:" content={!isValidValue(responseData.data.subtitle) ? "-" : responseData.data.subtitle} />
                <DetailsLayout title="Rate Per Hour:" content={!isValidValue(responseData.data.rate_per_hour) ? "-" : "$ " + responseData.data.rate_per_hour.toFixed(2)} />
                <DetailsLayout title="Designations:" content={!isValidValue(responseData.data.designations) ? "-" : responseData.data.designations.split(',').join(", ")} />
                <DetailsLayout title="Info:" isHTML={isValidValue(responseData.data.info_html)} content={isValidValue(responseData.data.info_html) ? parse(DOMPurify.sanitize(responseData.data.info_html)) : "-"} />
                <DetailsLayout title="Upcoming:" content={!isValidValue(responseData.data.is_upcoming) ? "-" : responseData.data.is_upcoming ? "Yes" : "No"} />
              </div>
              <div className='details-image'>
                <div className='logo'>
                  <p>Image:</p>
                  <div className='upload'>
                    {!isValidValue(responseData.data.image_url) ? <PlaceHolderIcon /> : <img className='upload-logo' src={responseData.data.image_url} alt="" />}
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div>
        {responseData.loading ? <LoadingSpinner /> : undefined}
      </div>
    </div>
  )
}

export default checkRole(ServiceDetails);