import React, { useEffect, useState } from 'react';
import './customTableDialog.scss';
import { ReactComponent as CloseIcon } from '../../assets/ic_close_outline.svg'
import CustomTable from '../CustomTable/CustomTable';
import { filterTable, isValidValue, renameKey, setupName, sortTable } from '../../Utils';
import { mainAxios } from '../../axiosConfig';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import SearchBar from '../SearchBar/SearchBar';

const CustomTableDialog = ({ id, columns, numPerPage, onClickTableRow, button1Title, button1Icon, button1Callback, button1Style, button2Title, button2Callback, button2Style, isDisableButton2, backgroundButtonCallback }) => {

    const [tableSorting, setTableSorting] = useState({
        column: "",
        assending: true
    });
    const [tableFilterValue, setTableFilterValue] = useState({
        searchValue: "",
        filterValue: []
    });
    const [tableFilterList, setTableFilterList] = useState([]);

    const [tableResponseData, setTableResponseData] = useState({
        loading: true,
        data: []
    });

    useEffect(() => {
        getAgentListAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 101A
    const getAgentListAPI = async () => {
        try {
            const res = await mainAxios.get('agents', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var userList = res.response.data.agents;
                userList.forEach(obj => renameKey(obj, "agent_id", "id"))
                const currentDate = new Date();
                for (let i = 0; i < userList.length; i++) {
                    let lastLoginAt = userList[i].last_login_at;
                    if (isValidValue(lastLoginAt)) {
                        const lastLoginAtDate = new Date(lastLoginAt);
                        const timeDifference = Math.abs(lastLoginAtDate - currentDate);
                        const seconds = Math.floor(timeDifference / 1000);
                        const minutes = Math.floor(seconds / 60);
                        const hours = Math.floor(minutes / 60);
                        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                        const years = Math.round((timeDifference / 1000 / (60 * 60 * 24)) / 365.25);

                        const lastLogin = seconds > 59 ? minutes > 59 ? hours > 23 ? days > 364 ? years + (years > 1 ? " year ago" : " years ago") : days + (days > 1 ? " days ago" : " days ago") : hours + (hours > 1 ? " hours ago" : " hour ago") : minutes + (minutes > 1 ? " minutes ago" : " minute ago") : seconds + (seconds > 1 ? " seconds ago" : " second ago")
                        userList[i].last_login = lastLogin;
                    }

                    let deactivateAt = userList[i].deactivate_at;
                    // if (deactivateAt != null) {
                    //   const deactivateAtDate = new Date(deactivateAt);
                    //   userList[i].isDisable = deactivateAtDate <= currentDate;
                    // } else {
                    //   userList[i].isDisable = false
                    // }
                    userList[i].isDisable = deactivateAt;
                    userList[i].isSelected = false;
                    var mobileNo = "";
                    if (isValidValue(userList[i].country_code)) {
                        mobileNo = userList[i].country_code + " ";
                    }

                    if (isValidValue(userList[i].phone_number)) {
                        mobileNo = mobileNo + userList[i].phone_number;
                    }

                    userList[i].mobile_no = mobileNo;
                    userList[i].name = setupName(userList[i].first_name, userList[i].last_name);
                }
                let newList = userList.filter(item => !item.isDisable);
                setTableResponseData({
                    loading: false,
                    data: newList
                });

                tableFilter(tableFilterValue, newList);
            } else {
                setTableResponseData({
                    loading: false,
                    data: {}
                });

                tableFilter(tableFilterValue, []);
            }
        } catch (error) {
            setTableResponseData({
                loading: false,
                data: {}
            });

            tableFilter(tableFilterValue, []);
        }
    }

    function onClickTableColumn(column, arr) {
        var data = sortTable(column, columns, arr ?? tableFilterList);
        column.data = data;
        setTableSorting(column);
    }

    function tableFilter(filter, arr) {
        var list = filterTable(filter, arr ?? tableResponseData.data);
        setTableFilterList(list);
        onClickTableColumn(tableSorting, list)
    }

    function handleTableSearchChange(value) {
        setTableFilterValue(value)
        tableFilter(value);
    }

    function handleRowClick(id) {
        var newList = tableResponseData.data;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].isSelected = true;
            } else {
                newList[i].isSelected = false;
            }
        }
        setTableResponseData({
            loading: false,
            data: newList
        });
        tableFilter(tableFilterValue, newList);
        onClickTableRow(id);
    }

    return (
        <div className='custom-table-dialog'>
            <button className='backgroundButton' onClick={() => backgroundButtonCallback === undefined ? console.log('backgroundButtonCallback') : backgroundButtonCallback(id)} />
            <div className='container'>
                <div className='container2'>
                    <div>
                        <div className="custom-table-header">
                            <p>{`LIST OF AGENTS`}</p>
                            <SearchBar
                                handleSearchChange={(value) => handleTableSearchChange(value)}
                                value={tableFilterValue}
                                filterList={columns}
                            />
                        </div>
                        <div className='table-container'>
                            <CustomTable
                                columns={columns}
                                tableData={tableSorting.data ?? tableFilterList}
                                isDelete={false}
                                isEdit={false}
                                numPerPage={numPerPage}
                                onClickRow={(id) => handleRowClick(id)}
                                sorting={tableSorting}
                                onClickColumn={(column) => onClickTableColumn(column)}
                            />
                        </div>
                        <div className='buttonContainer'>
                            <button style={button1Style} onClick={() => button1Callback === undefined ? console.log('button1Callback') : button1Callback(id)}>
                                {button1Icon ?? <CloseIcon />}
                                {button1Title ?? 'Cancel'}
                            </button>
                            <button style={button2Style} disabled={isDisableButton2} onClick={() => button2Callback === undefined ? console.log('button2Callback') : button2Callback(id)}>
                                {button2Title ?? 'OK'}
                            </button>
                        </div>
                    </div>
                    {tableResponseData.loading ? <LoadingSpinner /> : undefined}
                </div>
            </div >
        </div >
    )
}

export function ShowCustomTableDialog() {
    return (
        <CustomTableDialog />
    )
}

export default CustomTableDialog;