import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import './agentBannerItem.scss'
import { ReactComponent as MenuIcon } from '../../../assets/ic_menu2.svg'
import { ReactComponent as AddIcon } from '../../../assets/ic_add.svg';
import { ReactComponent as CloseIcon } from '../../../assets/ic_close_outline.svg'
import { CustomSelect } from "../../../components/SelectOption/CustomSelect";
import { AgentBannerTypeOption, isValidValue } from "../../../Utils";
import { mainAxios } from "../../../axiosConfig";
import { ReactComponent as PlaceHolderIcon } from '../../../assets/img_company_placeholder.svg';

export const AgentBannerItem = ({ index, id, title, content, type, image, setTitle, setContent, setType, setImage, addAgentBanners, removeAgentBanners }) => {
    const { attributes, listeners, setNodeRef, transform } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform)
    };
    const selectedType = AgentBannerTypeOption().find(agentBannerType => agentBannerType.value === type);

    // 2007
    const uploadBanner = async (files) => {
        try {
            const formData = new FormData();
            formData.append("file", files);
            const res = await mainAxios.post('banner_image', formData, {
                headers: { "Content-Type": 'multipart/form-data' }
            });
            if (res.isSuccess) {
                setImage(id, res.response.data.image_url);
            } else {
                setImage(id, "");
            }
        } catch (error) {
            setImage(id, "");
        }
    }

    return (
        <div className="agent-banners-item" style={style}>
            <div className="agent-banners-input-container">
                <MenuIcon className="agent-banners-menu" ref={setNodeRef}{...attributes}{...listeners} />
                <div className='details-div-layout-1'>
                    <input className='agent-banners-input' type='text' onChange={(e) => setTitle(id, e.target.value)} value={title} placeholder="Title" />
                </div>
                <CloseIcon className='agent-banners-remove-icon' onClick={() => removeAgentBanners(id)} />
                <AddIcon className={`agent-banners-add-icon ${index === 0 ? '' : 'icon-hidden'}`} onClick={() => addAgentBanners()} />
            </div>
            <div className="agent-banners-input-container">
                <MenuIcon className="agent-banners-menu icon-hidden" />
                <div className='details-div-layout'>
                    <textarea className='agent-banners-textarea' type='text' rows={4} onChange={(e) => setContent(id, e.target.value)} value={content} placeholder="Content" />
                </div>
                <CloseIcon className='agent-banners-remove-icon icon-hidden' />
                <AddIcon className='agent-banners-add-icon icon-hidden' />
            </div>
            <div className="agent-banners-input-container">
                <MenuIcon className="agent-banners-menu icon-hidden" />
                <div className='details-div-layout details-div-multi-layout'>
                    <CustomSelect list={AgentBannerTypeOption()} placeholder='Select Type' isMulti={false} value={selectedType} onChangeValue={(e) => setType(id, e.value)} />
                </div>
                <CloseIcon className='agent-banners-remove-icon icon-hidden' />
                <AddIcon className='agent-banners-add-icon icon-hidden' />
            </div>
            <div className="agent-banners-input-container">
                <MenuIcon className="agent-banners-menu icon-hidden" />
                <div className='details-image'>
                    <div className='logo'>
                        <div className='upload'>
                            {isValidValue(image) ? <img className='upload-logo' src={image} alt="Image" /> : <PlaceHolderIcon />}
                            <div className='upload-image' onClick={() => document.querySelector(`.agent-banner-upload-${id}`).click()}>
                                <label>Upload Image</label>
                                <input className={`input-logo agent-banner-upload-${id}`} type='file' accept='image/png, image/jpeg' hidden onChange={({ target: { files } }) => {
                                    if (files && files[0]) {
                                        uploadBanner(files[0]);
                                    }
                                }} />
                            </div>
                            <p>File size: maximum 5 MB, size: (100 x 100 px)</p>
                            <p>File extension: .JPEG, .PNG</p>
                        </div>
                    </div>
                </div>
                <CloseIcon className='agent-banners-remove-icon icon-hidden' />
                <AddIcon className='agent-banners-add-icon icon-hidden' />
            </div>
        </div>
    )
}