import "./transactions.scss";
import { React, useEffect, useState } from 'react';
import CustomTable from "../../components/CustomTable/CustomTable.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner.jsx';
import { getAvatar, getName, sortTable, filterTable, dateToString, isValidValue } from '../../Utils.jsx'
import checkRole from '../../checkRole.js';
import { mainAxios } from "../../axiosConfig.js";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";
import Navbar from "../../components/Navbar/Navbar.jsx";
import SearchBar from '../../components/SearchBar/SearchBar.jsx';

const Transactions = (params) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });

    const [sorting, setSorting] = useState({
        column: "",
        assending: true
    });
    const [filterValue, setFilterValue] = useState({
        searchValue: "",
        filterValue: []
    });
    const [filterList, setFilterList] = useState([]);
    let isTransactionsIn = params.type === "in";

    useEffect(() => {
        if (!responseData.loading) {
            setResponseData({
                loading: true,
                data: []
            });
        }
        getTransactionListAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const columns = isTransactionsIn ?
        [
            { label: "Type", accessor: "transaction_type", isSort: true, width: '25%', type: "string", isFilter: true },
            { label: "#", accessor: "code", isSort: true, width: '25%', type: "string", isFilter: true },
            { label: "$ / c", accessor: "amount", isSort: true, width: '25%', type: "string", isFilter: true },
            { label: "Status", accessor: "status", isSort: true, width: '25%', type: "string", isFilter: true },
        ] :
        [
            { label: "Type", accessor: "transaction_type", isSort: true, width: '25%', type: "string", isFilter: true },
            { label: "#", accessor: "code", isSort: true, width: '25%', type: "string", isFilter: true, isAbleClick: true },
            { label: "$", accessor: "amount", isSort: true, width: '25%', type: "string", isFilter: true },
            { label: "Status", accessor: "status", isSort: true, width: '25%', type: "string", isFilter: true },
        ]

    function capitalizeFirstLetter(string) {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // 1005
    const getTransactionListAPI = async () => {
        try {
            const res = await mainAxios.get('transactions', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' },
                params: { "keys": isTransactionsIn ? "transactions_in" : "transactions_out" }
            });
            if (res.isSuccess) {
                var transactionList = isTransactionsIn ? res.response.data.transactions_in : res.response.data.transactions_out;
                if (isTransactionsIn) {
                    for (let i = 0; i < transactionList.length; i++) {
                        if (transactionList[i].type === "booking") {
                            if (transactionList[i].booking) {
                                transactionList[i].id = transactionList[i].booking.booking_id;
                                transactionList[i].code = transactionList[i].booking.booking_code;
                                transactionList[i].amount = isValidValue(transactionList[i].booking.pay_amount) ? "$ " + transactionList[i].booking.pay_amount.toFixed(2) : isValidValue(transactionList[i].booking.pay_credits) ? transactionList[i].booking.pay_credits + "c" : "-";
                                transactionList[i].status = "Pay: " + (!isValidValue(transactionList[i].booking.pay_at) ? "-" : dateToString(transactionList[i].booking.pay_at, "D MMM YY, h:mma"));
                            }
                        } else {
                            if (transactionList[i].topup) {
                                transactionList[i].id = transactionList[i].topup.topup_id;
                                var amount = "";
                                if (isValidValue(transactionList[i].topup.amount)) {
                                    amount = "$ " + transactionList[i].topup.amount.toFixed(2);
                                }
                                if (isValidValue(transactionList[i].topup.receive_credits)) {
                                    if (amount === "") {
                                        amount = "+ " + transactionList[i].topup.receive_credits + "c";
                                    } else {
                                        amount += "\n+ " + transactionList[i].topup.receive_credits + "c";
                                    }
                                }
                                transactionList[i].amount = amount !== "" ? amount : "-";
                                transactionList[i].status = "Pay: " + (!isValidValue(transactionList[i].topup.pay_at) ? "-" : dateToString(transactionList[i].topup.pay_at, "D MMM YY, h:mma"));
                            }
                        }
                        transactionList[i].transaction_type = capitalizeFirstLetter(transactionList[i].type);
                    }
                } else {
                    for (let i = 0; i < transactionList.length; i++) {
                        if (transactionList[i].session) {
                            transactionList[i].id = transactionList[i].session.session_id;
                            if (transactionList[i].session.booking) {
                                transactionList[i].booking_ref = transactionList[i].session.booking.booking_code;
                                transactionList[i].code = "Booking: " + transactionList[i].session.booking.booking_code + "\n" + "Session: " + transactionList[i].session.session_code;
                            }
                            transactionList[i].amount = isValidValue(transactionList[i].session.amount) ? "$ " + transactionList[i].session.amount.toFixed(2) : "-";
                            transactionList[i].status = "Pay: " + (!isValidValue(transactionList[i].session.pay_at) ? "-" : dateToString(transactionList[i].session.pay_at, "D MMM YY, h:mma"));
                        }
                        transactionList[i].transaction_type = capitalizeFirstLetter(transactionList[i].type);
                    }
                }
                setResponseData({
                    loading: false,
                    data: transactionList
                });

                filter(filterValue, transactionList);
            } else {
                setResponseData({
                    loading: false,
                    data: {}
                });
                filter(filterValue, []);
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: {}
            });
            filter(filterValue, []);
        }
    }

    function onClickRow(id) {
        transactions(id);
    }

    function transactions(id) {
        for (var data of responseData.data) {
            if (data.id === id) {
                navigate("/transaction/details?id=" + id, { state: { data: data, type: isTransactionsIn ? "in" : "out" } });
                break;
            }
        }
    }

    function onSidebarClick() {
        setResponseData({
            loading: true,
            data: {}
        });
        getTransactionListAPI();
    }

    function onClickColumn(column, arr) {
        var data = sortTable(column, columns, arr ?? filterList);
        column.data = data;
        setSorting(column);
    }

    function filter(filter, arr) {
        var list = filterTable(filter, arr ?? responseData.data);
        setFilterList(list);
        onClickColumn(sorting, list)
    }

    function handleSearchChange(value) {
        setFilterValue(value)
        filter(value);
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page={isTransactionsIn ? "transactions_in" : "transactions_out"} onSidebarClick={onSidebarClick} navChild={{ transactions: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="transactions">
                        <div className="header">
                            <p>{isTransactionsIn ? "TRANSACTIONS IN" : "TRANSACTIONS OUT"}</p>
                            <SearchBar
                                handleSearchChange={(value) => handleSearchChange(value)}
                                value={filterValue}
                                filterList={columns}
                            />
                        </div>
                        <div className="table-container">
                            <CustomTable
                                columns={columns}
                                tableData={sorting.data ?? filterList}
                                numPerPage={10}
                                isDisableOnClickRow={!isTransactionsIn}
                                onClickRow={(id) => onClickRow(id)}
                                onClickViewMore={(id) => onClickRow(id)}
                                sorting={sorting}
                                onClickColumn={(column) => onClickColumn(column)}
                            />
                        </div>
                    </div>
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(Transactions);