import { React, useEffect, useState } from 'react';
import './sessionDetails.scss';
import checkRole from '../../checkRole';
import { Link, useSearchParams } from 'react-router-dom';
import { mainAxios } from '../../axiosConfig';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { getAvatar, getDateTimeWithGMT, getName, isValidValue, setupName } from '../../Utils';
import CustomTableDialog from '../../components/CustomTableDialog/CustomTableDialog';
import { ReactComponent as PlaceHolderIcon } from '../../assets/img_company_placeholder.svg';
import CustomInputDialog from '../../components/CustomInputDialog/CustomInputDialog';

const SessionDetails = (params) => {
    const [queryParameters] = useSearchParams();
    const queryID = queryParameters.get("id");
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });
    const [tableDialog, setTableDialog] = useState({
        showDialog: false
    });
    const [inputDialog, setInputDialog] = useState({
        showDialog: false,
        title: ""
    });
    const [selectedId, setSelectedId] = useState("");

    const agentColumns = [
        { label: "Name", accessor: "name", isSort: true, width: '35%', type: "string", isFilter: true },
        { label: "Email", accessor: "login_id", isSort: true, width: '35%', type: "string", isFilter: true },
        { label: "Mobile No.", accessor: "mobile_no", isSort: true, width: '30%', type: "string", isFilter: true }
    ]

    useEffect(() => {
        getSessionDetailsAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const DetailsLayout = (props) => {
        return (
            <div className='details-layout'>
                <div className='details-layout-item'>
                    <p>{props.title}</p>
                </div>
                <div className='details-layout-item'>
                    {props.isImage ? <div className='upload'>
                        {!isValidValue(props.content) ? <PlaceHolderIcon /> :
                            <div className='image-grid-container'>
                                {props.content.map((item, index) => (
                                    <div key={index} className='image-grid-item'>
                                        <a href={item} target='_blank' rel='noopener noreferrer'>
                                            <img className='upload-logo' src={item} alt="Photo" />
                                        </a>
                                    </div>
                                ))}
                            </div>}
                    </div> : (props.screen === undefined) ?
                        (<p>{props.content}</p>) :
                        <p>
                            <Link to={getLinkTo(props.screen)} rel="noreferrer" target='_blank'>{props.content}</Link>
                        </p>
                    }
                </div>
            </div>
        )
    }

    function getLinkTo(id) {
        let data = responseData.data ?? {};
        let booking = data.booking ?? {};
        let customer = booking.customer ?? {};
        let agent = data.agent ?? {};

        switch (id) {
            case 0: return { pathname: "/customers/details", search: "?id=" + customer.customer_id };
            case 1: return { pathname: "/agents/details", search: "?id=" + agent.agent_id };
            case 2: return { pathname: "/booking/details", search: "?id=" + booking.booking_id };
            default: break;
        }
    }

    // 1041
    const getSessionDetailsAPI = async () => {
        try {
            const res = await mainAxios.get('session/' + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var data = res.response.data.session;
                var isPay = false;
                var isCancel = false;
                if (data.booking) {
                    if (data.booking.pay_at != null) {
                        isPay = true;
                    }
                    if (data.booking.cancel_at != null) {
                        isCancel = true;
                    }
                }
                data.isDisableCustom = !isPay || isCancel || data.complete_at != null;
                setResponseData({
                    loading: false,
                    data: data
                });
            } else {
                setResponseData({
                    loading: false,
                    data: {}
                });
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: {}
            });
        }
    }

    // 3042
    const assignAgentAPI = async () => {
        try {
            const res = await mainAxios.put('session_assign/' + queryID, {
                agent_id: selectedId
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: true,
                    data: {}
                });
                getSessionDetailsAPI();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
        setSelectedId("");
    }

    function createTableDialog() {
        return tableDialog.showDialog ?
            (
                <CustomTableDialog
                    columns={agentColumns}
                    numPerPage={3}
                    onClickTableRow={(id) => onClickTableRow(id)}
                    backgroundButtonCallback={(id) => handleCancelCallback()}
                    button1Callback={(id) => handleCancelCallback()}
                    button2Title={isValidValue(responseData.data.decline_at) ? 'Re-assign' : 'Assign'}
                    button2Style={selectedId === "" ? { backgroundColor: '#dbdbdb', color: '#656565' } : isValidValue(responseData.data.decline_at) ? { backgroundColor: '#FF0000', color: 'white' } : { backgroundColor: '#FC7921', color: 'white' }}
                    isDisableButton2={selectedId === ""}
                    button2Callback={(id) => handleButton2Callback()}
                />
            ) : null
    }

    function onClickTableRow(id) {
        setSelectedId(id);
    }

    function handleCancelCallback() {
        setSelectedId("");
        setTableDialog({ showDialog: false })
    }

    function handleButton2Callback() {
        setTableDialog({ showDialog: false })
        assignAgentAPI();
    }

    function onSidebarClick() { }

    function assignAgent() {
        setTableDialog({ showDialog: true });
    }

    // 3043
    const sessionPaidAPI = async (pay_reference) => {
        setInputDialog({ showDialog: false });
        setResponseData({
            ...responseData,
            loading: true,
        });
        try {
            const res = await mainAxios.put('session_paid/' + queryID, {
                pay_reference: pay_reference
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: true,
                    data: {}
                });
                getSessionDetailsAPI();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 4043
    const sessionUnpaidAPI = async () => {
        setResponseData({
            ...responseData,
            loading: true,
        });
        try {
            const res = await mainAxios.delete('session_unpaid/' + queryID, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: true,
                    data: {}
                });
                getSessionDetailsAPI();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    function createInputDialog() {
        return inputDialog.showDialog ?
            (
                <CustomInputDialog
                    type={"text"}
                    title={inputDialog.title}
                    action={inputDialog.action}
                    backgroundButtonCallback={() => setInputDialog({ showDialog: false })}
                    button1Callback={() => setInputDialog({ showDialog: false })}
                    button2Style={{ backgroundColor: 'rgba(249, 165, 40, 1)', color: 'white' }}
                    button2Callback={(pay_reference) => sessionPaidAPI(pay_reference)}
                />
            ) : null
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="sessions" onSidebarClick={onSidebarClick} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="session-details">
                        <div className="header">
                            <div>
                                <p>Session details</p>
                                <p>Booked Sessions &gt; Session details </p>
                            </div>
                            {
                                responseData.data.isDisableCustom ? undefined : <button className={`${isValidValue(responseData.data.complete_at) ? 'second-button' : 'first-button'} ${isValidValue(responseData.data.decline_at) ? 'red-button' : ''}`} onClick={() => assignAgent()}>
                                    {isValidValue(responseData.data.decline_at) ? 'Re-assign' : 'Assign'}
                                </button>
                            }
                            {
                                !isValidValue(responseData.data.complete_at) || !isValidValue(responseData.data.accept_at) ? undefined :
                                    isValidValue(responseData.data.pay_at) ?
                                        <button className={`${responseData.data.isDisableCustom ? 'first-button' : ''}`} onClick={() => sessionUnpaidAPI()}>
                                            Unpaid
                                        </button> : <button className={`${responseData.data.isDisableCustom ? 'first-button' : ''}`} onClick={() => setInputDialog({ showDialog: true, title: 'Pay Reference' })}>
                                            Paid
                                        </button>

                            }
                        </div>
                        <div className='content'>
                            <div className='details'>
                                <DetailsLayout title="Booking Ref:" screen={responseData.data.booking ? !isValidValue(responseData.data.booking.booking_code) ? undefined : 2 : undefined} content={responseData.data.booking ? !isValidValue(responseData.data.booking.booking_code) ? "-" : responseData.data.booking.booking_code : "-"} />
                                <DetailsLayout title="Session Ref:" content={!isValidValue(responseData.data.session_code) ? "-" : responseData.data.session_code} />
                                <DetailsLayout title="Service:" content={responseData.data.booking ? !isValidValue(responseData.data.booking.title) ? "-" : responseData.data.booking.title : "-"} />
                                <DetailsLayout title="Customer:" screen={responseData.data.booking ? responseData.data.booking.customer ? !isValidValue(setupName(responseData.data.booking.customer.first_name, responseData.data.booking.customer.last_name)) ? undefined : 0 : undefined : undefined} content={responseData.data.booking ? responseData.data.booking.customer ? !isValidValue(setupName(responseData.data.booking.customer.first_name, responseData.data.booking.customer.last_name)) ? "-" : setupName(responseData.data.booking.customer.first_name, responseData.data.booking.customer.last_name) : "-" : "-"} />
                                <DetailsLayout title="Agent:" screen={responseData.data.agent ? !isValidValue(setupName(responseData.data.agent.first_name, responseData.data.agent.last_name)) ? undefined : 1 : undefined} content={responseData.data.agent ? !isValidValue(setupName(responseData.data.agent.first_name, responseData.data.agent.last_name)) ? "-" : setupName(responseData.data.agent.first_name, responseData.data.agent.last_name) : "-"} />
                                <DetailsLayout title="Pay Reference:" content={!isValidValue(responseData.data.pay_reference) ? "-" : responseData.data.pay_reference} />
                                <DetailsLayout title="Start at:" content={!isValidValue(responseData.data.start_at) ? "-" : getDateTimeWithGMT(responseData.data.start_at, responseData.data.booking.address_timezone_offset, "D MMM YY, h:mma")} />
                                <DetailsLayout title="End at:" content={!isValidValue(responseData.data.end_at) ? "-" : getDateTimeWithGMT(responseData.data.end_at, responseData.data.booking.address_timezone_offset, "D MMM YY, h:mma")} />
                                <DetailsLayout title="Assign at:" content={!isValidValue(responseData.data.assign_at) ? "-" : getDateTimeWithGMT(responseData.data.assign_at, responseData.data.booking.address_timezone_offset, "D MMM YY, h:mma")} />
                                <DetailsLayout title="Accept at:" content={!isValidValue(responseData.data.accept_at) ? "-" : getDateTimeWithGMT(responseData.data.accept_at, responseData.data.booking.address_timezone_offset, "D MMM YY, h:mma")} />
                                <DetailsLayout title="Decline at:" content={!isValidValue(responseData.data.decline_at) ? "-" : getDateTimeWithGMT(responseData.data.decline_at, responseData.data.booking.address_timezone_offset, "D MMM YY, h:mma")} />
                                <DetailsLayout title="Complete at:" content={!isValidValue(responseData.data.complete_at) ? "-" : getDateTimeWithGMT(responseData.data.complete_at, responseData.data.booking.address_timezone_offset, "D MMM YY, h:mma")} />
                                <DetailsLayout title="Pay at:" content={!isValidValue(responseData.data.pay_at) ? "-" : getDateTimeWithGMT(responseData.data.pay_at, responseData.data.booking.address_timezone_offset, "D MMM YY, h:mma")} />
                                {isValidValue(responseData.data.breakdown) && responseData.data.breakdown.length > 0 ?
                                    <div className='details-breakdown'>
                                        <p className='details-title'>Breakdown</p>
                                        {responseData.data.breakdown.map((item) => {
                                            return (
                                                <DetailsLayout title={item.key + ":"} content={item.value} />
                                            )
                                        })}
                                    </div> : undefined
                                }
                            </div>
                            <div className='details'>
                                <p className='details-title'>Review</p>
                                <DetailsLayout title="Score:" content={!isValidValue(responseData.data.review_score) ? "-" : responseData.data.review_score} />
                                <DetailsLayout title="Comment:" content={!isValidValue(responseData.data.review_comment) ? "-" : responseData.data.review_comment} />
                                <DetailsLayout title="Images:" isImage={responseData.data.review_images && responseData.data.review_images.length !== 0} content={responseData.data.review_images && responseData.data.review_images.length !== 0 ? responseData.data.review_images : "-"} />
                                <DetailsLayout title="At:" content={!isValidValue(responseData.data.review_at) ? "-" : getDateTimeWithGMT(responseData.data.review_at, responseData.data.booking.address_timezone_offset, "D MMM YY, h:mma")} />
                            </div>
                        </div>
                        {createTableDialog()}
                        {createInputDialog()}
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(SessionDetails);