import { React, useEffect, useState } from 'react';
import './bookings.scss';
import checkRole from '../../checkRole';
import { useNavigate } from 'react-router-dom';
import { mainAxios } from '../../axiosConfig';
import { getAvatar, getName, renameKey, sortTable, filterTable, timeToString, isValidValue, setupName, getDateTimeWithGMT } from '../../Utils';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import CustomTable from '../../components/CustomTable/CustomTable';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import SearchBar from '../../components/SearchBar/SearchBar';

const Bookings = (params) => {

  const navigate = useNavigate();
  const [responseData, setResponseData] = useState({
    loading: true,
    data: []
  });
  const [sorting, setSorting] = useState({
    column: "",
    assending: true
  });
  const [filterValue, setFilterValue] = useState({
    searchValue: "",
    filterValue: []
  });
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    getBookingListAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    { label: "#", accessor: "booking_code", isSort: true, width: '13%', type: "string", isFilter: true, isAbleClick: true },
    { label: "Customer", accessor: "customer_details", isSort: true, width: '16%', type: "string", isFilter: true },
    { label: "Service", accessor: "service_details", isSort: true, width: '15%', type: "string", isFilter: true },
    { label: "Schedule Dates", accessor: "date", isSort: true, width: '17%', type: "string", isFilter: true },
    { label: "$ / c", accessor: "amount", isSort: true, width: '10%', type: "string", isFilter: true },
    { label: "Status", accessor: "status", isSort: true, width: '29%', type: "string", isFilter: true }
  ]

  // 1030
  const getBookingListAPI = async () => {
    try {
      const res = await mainAxios.get('bookings', {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        var bookingList = res.response.data.bookings;
        bookingList.forEach(obj => renameKey(obj, "booking_id", "id"))

        for (let i = 0; i < bookingList.length; i++) {
          var date = "";
          if (bookingList[i].sessions) {
            for (let a = 0; a < bookingList[i].sessions.length; a++) {
              if (isValidValue(bookingList[i].sessions[a].start_at)) {
                if (date === "") {
                  date = getDateTimeWithGMT(bookingList[i].sessions[a].start_at, bookingList[i].address_timezone_offset, "D MMM YY");
                } else {
                  date += "\n" + getDateTimeWithGMT(bookingList[i].sessions[a].start_at, bookingList[i].address_timezone_offset, "D MMM YY");
                }
              }
            }
          }
          bookingList[i].date = date;

          bookingList[i].service_details = (bookingList[i].title ?? "-") + "\n" +
            (!isValidValue(bookingList[i].schedule_time) ? "-" : timeToString(bookingList[i].schedule_time, "h:mma")) + "\n" +
            (!isValidValue(bookingList[i].schedule_duration) ? "-" : bookingList[i].schedule_duration + (bookingList[i].schedule_duration > 1 ? " hours" : " hour"));

          var customer_details = "";
          if (bookingList[i].customer) {
            var phoneNumber = "";
            if (isValidValue(bookingList[i].customer.country_code)) {
              phoneNumber += bookingList[i].customer.country_code + " ";
            }
            if (isValidValue(bookingList[i].customer.phone_number)) {
              phoneNumber += bookingList[i].customer.phone_number;
            }
            customer_details = (bookingList[i].customer.login_id ?? "-") + "\n" +
              (setupName(bookingList[i].customer.first_name, bookingList[i].customer.last_name) ?? "-") + "\n" + (phoneNumber === "" ? "-" : phoneNumber);
          }
          bookingList[i].customer_details = customer_details;

          bookingList[i].amount = isValidValue(bookingList[i].pay_amount) ? "$ " + bookingList[i].pay_amount.toFixed(2) : bookingList[i].pay_credits ? bookingList[i].pay_credits + "c" : "-";

          bookingList[i].status = "Book: " +
            (!isValidValue(bookingList[i].book_at) ? "-" : getDateTimeWithGMT(bookingList[i].book_at, bookingList[i].address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
            "Pay: " + (!isValidValue(bookingList[i].pay_at) ? "-" : getDateTimeWithGMT(bookingList[i].pay_at, bookingList[i].address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
            "Cancel: " + (!isValidValue(bookingList[i].cancel_at) ? "-" : getDateTimeWithGMT(bookingList[i].cancel_at, bookingList[i].address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
            "Refund: " + (!isValidValue(bookingList[i].refund_at) ? "-" : getDateTimeWithGMT(bookingList[i].refund_at, bookingList[i].address_timezone_offset, "D MMM YY, h:mma"));
        }

        setResponseData({
          loading: false,
          data: bookingList
        });
        filter(filterValue, bookingList);
      } else {
        setResponseData({
          loading: false,
          data: {}
        });
        filter(filterValue, []);
      }
    } catch (error) {
      setResponseData({
        loading: false,
        data: {}
      });
      filter(filterValue, []);
    }
  }

  function onClickRow(id) {
    bookings(id, 'view');
  }

  function onClickEdit(id) {
    bookings(id, 'edit');
  }

  function bookings(id, action) {
    for (var data of responseData.data) {
      if (data.id === id) {
        navigate("/booking/details?id=" + id);
        break;
      }
    }
  }

  function onSidebarClick() {
    setResponseData({
      loading: true,
      data: {}
    });
    getBookingListAPI();
  }

  function onClickColumn(column, arr) {
    var data = sortTable(column, columns, arr ?? filterList);
    column.data = data;
    setSorting(column);
  }

  function filter(filter, arr) {
    var list = filterTable(filter, arr ?? responseData.data);
    setFilterList(list);
    onClickColumn(sorting, list)
  }

  function handleSearchChange(value) {
    setFilterValue(value)
    filter(value);
  }

  return (
    <div className='app-main'>
      <div className={`${params.navClose ? 'navClose' : ""}`}>
        <Sidebar page="bookings" onSidebarClick={onSidebarClick} />
      </div>
      <div className='app-main-content'>
        <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
        <div className='app-content'>
          <div className="bookings">
            <div className="header">
              <p>BOOKINGS</p>
              <SearchBar
                handleSearchChange={(value) => handleSearchChange(value)}
                value={filterValue}
                filterList={columns}
              />
            </div>
            <div className="table-container">
              <CustomTable
                columns={columns}
                tableData={sorting.data ?? filterList}
                isDelete={false}
                isDisableOnClickRow={true}
                numPerPage={10}
                onClickRow={(id) => onClickRow(id)}
                onClickEdit={(id) => onClickEdit(id)}
                onClickViewMore={(id) => onClickRow(id)}
                sorting={sorting}
                onClickColumn={(column) => onClickColumn(column)}
              />
            </div>
          </div>
        </div>
        {responseData.loading ? <LoadingSpinner /> : undefined}
      </div>
    </div>
  )
}

export default checkRole(Bookings);