import React, { useEffect, useState } from 'react'
import './editPromotions.scss'
import { ReactComponent as TickIcon } from '../../../assets/ic_tick.svg';
import { useNavigate } from 'react-router-dom';
import checkRole from '../../../checkRole';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { mainAxios } from '../../../axiosConfig';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { dateToString, getAvatar, getName, isValidValue } from '../../../Utils';
import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { PromotionItem } from './PromotionItem';

const EditPromotions = (params) => {
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        showDialog: false,
        message: ""
    });
    const [responseData, setResponseData] = useState({
        loading: false
    });

    const [serviceDropdownList, setServiceDropDownList] = useState([{ value: "-", label: "-" }]);
    const [promotions, setPromotions] = useState([{ id: 1, min_sessions: "", discount_percentage: "" }]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const getPromotionPos = id => promotions.findIndex(promotion => promotion.id === id);

    const handleDragEnd = event => {
        const { active, over } = event

        if (active.id === over.id) return;

        setPromotions(promotions => {
            const originalPos = getPromotionPos(active.id);
            const newPos = getPromotionPos(over.id);

            return arrayMove(promotions, originalPos, newPos);
        })
    }

    useEffect(() => {
        if (params.action === "edit") {
            setResponseData({
                loading: true
            });
            getSetup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSave = (event) => {
        event.preventDefault();

        for (let i = 0; i < promotions.length; i++) {
            if ((promotions[i].service_id && promotions[i].min_sessions) || (promotions[i].discount_percentage && promotions[i].discount_amount)) {
                setDialog({ showDialog: true, message: "Please input only one option for OR selections." });
                return;
            }
        }

        const filterList = promotions
            .filter(item => (item.min_sessions || item.service_id) && (item.discount_percentage || item.discount_amount))
            .map(({ id, start_at_date, end_at_date, ...rest }) => rest);

        const newList = filterList.map(item => {
            const filteredItem = Object.fromEntries(
                Object.entries(item).filter(([key, value]) => value)
            );
            return filteredItem;
        });

        updateSetup(JSON.stringify(newList));
    }

    // 1002
    const getSetup = async () => {
        try {
            const res = await mainAxios.get('setup', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var promotionList = [];
                if (res.response.data.booking_promotions) {
                    promotionList = JSON.parse(res.response.data.booking_promotions);
                    for (let i = 0; i < promotionList.length; i++) {
                        if (promotionList[i].start_at !== undefined && promotionList[i].start_at) {
                            promotionList[i].start_at_date = dateToString(promotionList[i].start_at, "YYYY-MM-DDTHH:mm");
                        }
                        if (promotionList[i].end_at !== undefined && promotionList[i].end_at) {
                            promotionList[i].end_at_date = dateToString(promotionList[i].end_at, "YYYY-MM-DDTHH:mm");
                        }
                    }
                }
                getServicesListAPI(promotionList);
            } else {
                setResponseData({
                    ...responseData,
                    loading: false
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false
            });
        }
    }

    // 1020
    const getServicesListAPI = async (promotionList) => {
        try {
            const res = await mainAxios.get('services', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var servicesList = res.response.data.services;
                if (servicesList) {
                    var dropdownList = [{ value: "-", label: "-" }];
                    for (let i = 0; i < servicesList.length; i++) {
                        dropdownList.push({ value: servicesList[i].service_id, label: servicesList[i].title });
                    }

                    setServiceDropDownList(dropdownList);
                }

                for (let i = 0; i < promotionList.length; i++) {
                    promotionList[i].id = i + 1;
                }
                if (promotionList.length !== 0) {
                    setPromotions(promotionList);
                }
                setResponseData({
                    loading: false
                });
            } else {
                setResponseData({
                    ...responseData,
                    loading: false
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false
            });
        }
    }

    // 3002
    const updateSetup = async (newPromotions) => {
        try {
            const res = await mainAxios.put('setup', {
                booking_promotions: newPromotions,
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
                navigate("/setup/booking-promotions");
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    function createDialog() {
        return dialog.showDialog ?
            (
                <ConfirmDialog
                    iconTitle={''}
                    title={''}
                    subtitle={dialog.message}
                    subTitleStyle={{ fontSize: 16 }}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Style={{ visibility: "hidden" }}
                    button2Icon={''}
                    button2Title={'OK'}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => setDialog({ showDialog: false })}
                />
            )
            : null
    }

    function setMinSessions(id, min_sessions) {
        var newList = promotions;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].min_sessions = min_sessions;
            }
        }
        setPromotions([...newList]);
    }

    function setService(id, service) {
        var newList = promotions;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                if (service.value === "-") {
                    newList[i].service_id = null;
                } else {
                    newList[i].service_id = service.value;
                }
            }
        }
        setPromotions([...newList]);
    }

    function setDiscount(id, discount) {
        var newList = promotions;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].discount_percentage = discount;
            }
        }
        setPromotions([...newList]);
    }

    function setDiscountAmount(id, discount) {
        var newList = promotions;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].discount_amount = discount;
            }
        }
        setPromotions([...newList]);
    }

    function setStartAt(id, start_at_date) {
        var newList = promotions;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                if (isValidValue(start_at_date)) {
                    newList[i].start_at = dateToString(start_at_date, "YYYY-MM-DD HH:mm:ss");
                    newList[i].start_at_date = start_at_date;
                } else {
                    newList[i].start_at = "";
                    newList[i].start_at_date = "";
                }
            }
        }
        setPromotions([...newList]);
    }

    function setEndAt(id, end_at_date) {
        var newList = promotions;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                if (isValidValue(end_at_date)) {
                    newList[i].end_at = dateToString(end_at_date, "YYYY-MM-DD HH:mm:ss");
                    newList[i].end_at_date = end_at_date;
                } else {
                    newList[i].end_at = "";
                    newList[i].end_at_date = "";
                }
            }
        }
        setPromotions([...newList]);
    }

    function addPromotion() {
        var currentIndex = 0;
        for (let i = 0; i < promotions.length; i++) {
            if (promotions[i].id > currentIndex) {
                currentIndex = promotions[i].id;
            }
        }
        var newList = promotions.concat({ id: currentIndex + 1, min_sessions: "", discount_percentage: "" });
        setPromotions([...newList]);
    }

    function removePromotion(id) {
        if (promotions.length === 1) {
            promotions[0].min_sessions = "";
            promotions[0].discount_percentage = "";
            setPromotions([...promotions]);
        } else {
            setPromotions((current) => current.filter((element) => {
                return element.id !== id;
            }));
        }
    }

    function onSidebarClick() { }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="booking_promotions" onSidebarClick={onSidebarClick} navChild={{ setup: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="edit-promotions">
                        <form onSubmit={handleSave}>
                            <div className="header">
                                <div>
                                    <p>Edit booking promotions</p>
                                    <p>Booking Promotions &gt; Edit booking promotions </p>
                                </div>
                                <button type='submit'>
                                    <span><TickIcon /></span>
                                    <span>Save</span>
                                </button>
                            </div>
                            <div className='content'>
                                <div className='details'>
                                    <div className='details-form'>
                                        <label className='required'>A booking can only match one promotion, and conditions will be checked in the following sequence.</label>
                                        <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                                            <SortableContext items={promotions} strategy={verticalListSortingStrategy}>
                                                {promotions.map((promotion, index) => (
                                                    <PromotionItem key={index} index={index} id={promotion.id} serviceList={serviceDropdownList} minSessions={promotion.min_sessions} serviceId={promotion.service_id} discount={promotion.discount_percentage} discountAmount={promotion.discount_amount} startAt={promotion.start_at_date} endAt={promotion.end_at_date} setMinSessions={setMinSessions} setService={setService} setDiscount={setDiscount} setDiscountAmount={setDiscountAmount} setStartAt={setStartAt} setEndAt={setEndAt} addPromotion={addPromotion} removePromotion={removePromotion} />
                                                ))}
                                            </SortableContext>
                                        </DndContext>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {createDialog()}
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(EditPromotions);